import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { Auftrag } from 'src/app/store/models/auftrag.model'
import * as moment from 'moment'
import 'moment/locale/de'
import { AuftragDatenbestand } from '../../../enums/auftrag.enums'
import { SyncStatus } from '../../../store/models/vertrag.model'

// set moment locale
moment.locale('de')

@Component({
  selector: 'app-auftrag',
  templateUrl: './auftrag.component.html',
  styleUrls: ['./auftrag.component.scss']
})
export class AuftragComponent implements OnChanges {

  auftragDatenbestandEnum = AuftragDatenbestand
  isLeistungsradarFaehig = false
  isInteractable = false
  imageUrl = ''
  anzeigeEingangsDatum = ''

  @Input()
  auftrag: Auftrag

  @Output()
  deleteAuftrag: EventEmitter<Auftrag> = new EventEmitter()

  @Output()
  handleDownloadError: EventEmitter<any> = new EventEmitter()

  public quelle: string
  public syncError = false

  protected readonly AuftragDatenbestand = AuftragDatenbestand

  ngOnChanges(changes: SimpleChanges): void {
    this.isLeistungsradarFaehig = this.auftrag?.vertraege.some(vertrag => vertrag.leistungsradar)
    this.isInteractable = this.auftrag?.datenbestand === AuftragDatenbestand.BEREITGESTELLT || this.auftrag?.datenbestand === AuftragDatenbestand.NACHLADBAR
    if (changes.auftrag.previousValue !== changes.auftrag.currentValue) {
      this.imageUrl = this.generateImageUrl()
      this.quelle = this.generateQuelle()
      this.syncError = this.checkForSyncError()
      this.anzeigeEingangsDatum = this.formatEingangsdatumToAnzeigeText(this.auftrag.eingangsDatum)
    }
  }

  onDeleteAuftrag(event: any): void {
    event.stopPropagation()
    this.deleteAuftrag.emit(this.auftrag)
  }

  onDownloadError(): void {
    this.handleDownloadError.emit()
  }

  private generateQuelle(): string {
    return this.auftrag?.ursprung ? this.auftrag.ursprung + '-Upload' : ''
  }

  private generateImageUrl(): string {
    if (this.auftrag?.thumbnail) {
      return 'data:image/png;base64,' + this.auftrag.thumbnail
    }
    return 'data:image/png;base64,'
  }

  private checkForSyncError(): boolean {
     return this.auftrag.vertraege.some(vertrag => vertrag.syncStatus === SyncStatus.SYNCFEHLER)
  }

  private formatEingangsdatumToAnzeigeText(datum: string): string {
    if(!datum) {
      return ''
    }

    const eingangsDatum = moment(datum)
    if(!eingangsDatum.isValid() ) {
      return ''
    }

    if ( moment().isSame(eingangsDatum, 'day')) {
      return `Heute um ${eingangsDatum.format('HH:mm')}`
    } else {
      return `${eingangsDatum.format('dd DD.MM.YYYY')} um ${eingangsDatum.format('HH:mm')}`
    }
  }
}
