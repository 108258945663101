// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const env = {
  avatarUrls: {
    entwicklung: 'https://vbinfo.entwicklung.dvag/v1/vermoegensberater/{replaceWithVbnummer}/bilder/vbportraithomepage ',
    integration: 'https://vbinfo.integration.dvag/v1/vermoegensberater/{replaceWithVbnummer}/bilder/vbportraithomepage ',
    abnahme: 'https://vbinfo.abnahme.dvag/v1/vermoegensberater/{replaceWithVbnummer}/bilder/vbportraithomepage ',
    produktion: 'https://vbinfo.dvag/v1/vermoegensberater/{replaceWithVbnummer}/bilder/vbportraithomepage '
  },
  production: false,
  debuggingLogs: false,
  keycloak: {
    realm: 'zob',
    clientId: 'app-web-datenturbo',
    baseUrl: '',
    urls: {
      entwicklung: 'https://entwicklung.auth.dvag/auth',
      integration: 'https://integration.auth.dvag/auth',
      abnahme: 'https://abnahme.auth.dvag/auth',
      produktion: 'https://auth.dvag/auth'
    }
  },
  zob: {
    // set in app.module.ts
    baseUrl: '',
    entwicklung: 'https://entwicklung.api.dvag/zob',
    integration: 'https://integration.api.dvag/zob',
    abnahme: 'https://abnahme.api.dvag/zob',
    produktion: 'https://api.dvag/zob'
  },
  be: {
    // set in app.module.ts
    baseUrl: '',
    urls: {
      entwicklung: 'https://datenturbo.entwicklung.dvag/rest/v1',
      integration: 'https://datenturbo.integration.dvag/rest/v1',
      abnahme: 'https://datenturbo.abnahme.dvag/rest/v1',
      produktion: 'https://datenturbo.dvag/rest/v1'
    }
  },
  qrfotoupload: {
    baseUrl: null,
    urls: {
      localhost: 'http://localhost:4200/qrfotoupload',
      digilocal: 'https://digi.local/qrfotoupload',
      entwicklung: 'https://datenturbo.entwicklung.dvag/qrfotoupload',
      integration: 'https://datenturbo.integration.dvag/qrfotoupload',
      abnahme: 'https://datenturbo.abnahme.dvag/qrfotoupload',
      produktion: 'https://datenturbo.dvag/qrfotoupload'
    }
  }
}

switch (window.location.hostname) {
  case 'digi.local':
    env.qrfotoupload.baseUrl = env.qrfotoupload.urls.digilocal
    // FallThrough beabsichtigt: Damit wir lokal testen können beim Serven der Anwendung gegen Integration
  case 'datenturbo.integration.dvag':
  case 'digi.integration.dvag':
  case 'datacollect.integration.k8s.dvag.net':
    env.be.baseUrl = env.be.urls.integration
    env.keycloak.baseUrl = env.keycloak.urls.integration
    env.zob.baseUrl = env.zob.integration
    env.zob.baseUrl = env.zob.integration
    env.qrfotoupload.baseUrl = env.qrfotoupload.baseUrl ?? env.qrfotoupload.urls.integration
    break

  case 'datenturbo.abnahme.dvag':
  case 'digi.abnahme.dvag':
  case 'datacollect.abnahme.k8s.dvag.net':
    env.be.baseUrl = env.be.urls.abnahme
    env.keycloak.baseUrl = env.keycloak.urls.abnahme
    env.zob.baseUrl = env.zob.abnahme
    env.qrfotoupload.baseUrl = env.qrfotoupload.urls.abnahme
    break

  case 'datenturbo.dvag':
  case 'digi.dvag':
  case 'datacollect.k8s.dvag.net':
    env.be.baseUrl = env.be.urls.produktion
    env.keycloak.baseUrl = env.keycloak.urls.produktion
    env.zob.baseUrl = env.zob.produktion
    env.qrfotoupload.baseUrl = env.qrfotoupload.urls.produktion
    break

  case 'localhost':
    env.qrfotoupload.baseUrl = env.qrfotoupload.urls.localhost
    // FallThrough beabsichtigt: Damit wir lokal testen können beim Serven der Anwendung
  default:
    env.be.baseUrl = env.be.urls.entwicklung
    env.keycloak.baseUrl = env.keycloak.urls.entwicklung
    env.zob.baseUrl = env.zob.entwicklung
    env.qrfotoupload.baseUrl = env.qrfotoupload.baseUrl ?? env.qrfotoupload.urls.entwicklung
}

export const environment = env

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error' // Included with Angular CLI.
