<div class="header" [ngClass]="{'advocard': vergleich.bereich.name === 'ADVOCARD'}">
  <div class="inner-cell merkmal">
    <p class="fliesstext-fett-dark">Leistungsmerkmale</p>
  </div>
  <div class="outer-cell">
    <div class="inner-cell">
      <p class="fliesstext-fett-dark">{{vergleich.bereich.name}}</p>
    </div>
  </div>
  <div class="outer-cell">
    <div class="cell">
      <p class="fliesstext-fett-dark">{{vergleich.gesellschaft.name}}</p>
    </div>
  </div>
</div>

<div *ngFor="let data of vergleich.merkmale" class="row">
  <div class="merkmal">
    <div class="inner-cell">
      <p class="fliesstext-standard-dark" [ngClass]="{'bold': data.title}">{{data.merkmal}}</p>
    </div>
  </div>

  <div [ngClass]="'color-' + data.baseColor" class="outer-cell">
    <div class="inner-cell werte">
      <p class="fliesstext-standard-dark" [innerHTML]="data.base"></p>
    </div>
  </div>

  <div [ngClass]="'color-' + data.compareColor" class="outer-cell">
    <div class="inner-cell werte">
      <p class="fliesstext-standard-dark" [ngClass]="{'white': data.compareColor === 1}" [innerHTML]="data.compare"></p>
    </div>
  </div>
</div>
