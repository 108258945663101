import { Component, Input } from '@angular/core'
import { FormularFeldConfig } from '../../../store/models/formular-feld-config.model'

@Component({
  selector: 'app-readonly-template',
  templateUrl: './readonly-template.component.html'
})
export class ReadonlyTemplateComponent {

  @Input()
  field: FormularFeldConfig

  @Input()
  displayValue: string
}
