import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Stage } from '../stage/stage'
import { Kunde } from '../model/kunde.model'
import { Observable, of } from 'rxjs'
import { Haushalt } from '../model/haushalt.model'
import { testObject } from '../../../../../test/testobjects/test-object'

@Injectable({
  providedIn: 'root'
})
export class KundenHttpService {


  constructor(private httpClient: HttpClient, private stage: Stage) {
  }

  sucheKunde(searchQuery: string): Observable<Kunde[]> {
    // MOCK: Kundensuche
    // return of(testObject.kundensuche.getKunden())
    return this.httpClient.get<Kunde[]>(`${this.stage.getBackendUrl()}/personen?name=${searchQuery}`)
  }

  ladeHaushalt(haushaltsId: string): Observable<Haushalt> {
    return this.httpClient.get<Haushalt>(`${this.stage.getBackendUrl()}/haushalte/${haushaltsId}`)
  }

  ladeKunde(kundenNr: string): Observable<Kunde> {
    // MOCK: Kunde laden mocken
    // return of(testObject.kundensuche.getKunden()[0])
    return this.httpClient.get<Kunde>(`${this.stage.getBackendUrl()}/personen/${kundenNr}`)
  }
}
