import { Injectable } from '@angular/core'
import { Auftrag} from '../../store/models/auftrag.model'
import { forkJoin, Observable, throwError } from 'rxjs'
import { AuftragHttpService } from '../../store/services/auftrag.http.service'
import { take } from 'rxjs/operators'
import { OcrPage } from '../../store/models/ocr.model'
import { AuftragKategorie } from '../../enums/auftrag.enums'

@Injectable({
  providedIn: 'root'
})
export class AuftragService {

  constructor(private auftragHttpService: AuftragHttpService) { }

  fetchAuftrag(auftragId: string): Observable<Auftrag> {
    if ( !auftragId ) {
      return throwError(() => new Error(`Die übergebene AuftragId darf nicht leer oder NULL sein`))
    }

    return this.auftragHttpService.fetchAuftrag(auftragId).pipe(take(1))
  }

  deleteAuftrag(auftrag: Auftrag): Observable<any> {
    if ( !auftrag?.auftragId ) {
      return throwError(() => new Error(`Der übergebene Auftrag oder die AuftragsId darf nicht leer/NULL sein`))
    }

    return this.auftragHttpService.deleteAuftrag(auftrag.auftragId)
  }

  updateFormularId(auftrag: Auftrag, formularId: string): Observable<undefined> {
    if(!auftrag) {
      return throwError(() => new Error(`Der Auftrag darf nicht NULL sein`))
    }
    if(!formularId) {
      return throwError(() => new Error(`Die FormularId muss gesetzt sein.`))
    }
    if ( auftrag.kategorie !== AuftragKategorie.OFFEN) {
      return throwError(() => new Error(`Der Dokumenttyp eines Auftrags darf nur im Status 'OFFEN' geändert werden.`))
    }
    return this.auftragHttpService.updateFormularId(auftrag.auftragId, {formularId})
  }

  updateKiFremdvertragId(auftrag: Auftrag, kiFremdvertragId: string ): Observable<undefined> {
    if(!auftrag) {
      return throwError(() => new Error(`Der Auftrag darf nicht NULL sein`))
    }
    if ( auftrag.kategorie !== AuftragKategorie.OFFEN) {
      return throwError(() => new Error(`Die FremdvertragsId eines Auftrags darf nur im Status 'OFFEN' geändert werden.`))
    }

    return this.auftragHttpService.updateKiFremdvertragId(auftrag.auftragId, {kiFremdvertragId})
  }

  fetchDateiWithOCR(auftragId: string, datei: string): Observable<[{blob: Blob, width: number, height: number}, OcrPage]> {
    if (!auftragId || !datei) {
      return throwError(() => new Error('Fuer das Laden eines Bildes und dem zugehoerigen OCR-Ergebnis werden die AuftragId und die Datei benoetigt'))
    }

    const bild$ = this.auftragHttpService.fetchDatei(auftragId, datei)
      .pipe(take(1))

    const ocr$ = this.auftragHttpService.fetchDateiOcr(auftragId, datei)
      .pipe(take(1))

    return forkJoin([bild$, ocr$])
  }

  ladeDatenFuerAuftragErneut(auftragId: string ): Observable<any> {
    if (!auftragId) {
      return throwError(() => new Error('Fuer das Nachladen der Daten eines Auftrgas wird dessen AuftragsId benoetigt'))
    }

    return this.auftragHttpService.datenNachladenAuftrag(auftragId).pipe(
      take(1)
    )
  }
}
