<ng-container *ngIf="!isReadonly">

  <div [formGroup]="mainFormularGroup">
    <dx-checkbox #focusElement
                 [id]="field.name + '_wert'"
                 [formControlName]="field.name"
                 (focus)="storeNameOfFocusedElement(field.name)"
                 [required]="field.required"
                 [label]="field.label"
                 [kisynced]="field.kiSync">
    </dx-checkbox>
  </div>

</ng-container>
<app-readonly-template *ngIf="isReadonly" [field]="field"
                       [displayValue]="getReadonlyDisplayValue()"></app-readonly-template>
