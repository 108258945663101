import {Component, Input} from '@angular/core';
import {LeistungsradarResult} from '../../model/leistungsradarresult.model'


@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'dt-leistungsradar-vergleich',
  templateUrl: './leistungsradar-vergleich.component.html',
  styleUrls: ['./leistungsradar-vergleich.component.scss']
})
export class LeistungsradarVergleichComponent {
  @Input()
  vergleich: LeistungsradarResult;

}
