<dx-text type='ps'>{{ field.label }}</dx-text>

<div *ngIf='!isReadonly' [formGroup]='mainFormularGroup'>
  <div formGroupName="{{field.name}}">
    <dx-grid mq1="6-6/6-(6)">
      <dx-number-input #sonderzahlung_netto_El
                       id="sonderzahlung_netto"
                       label="Jährlich netto"
                       (focus)="saveNameForOCR('netto', sonderzahlung_netto_El, OCRType.BETRAG)"
                       (valueChange)="enableFormControls($event.detail)"
                       formControlName='netto'
                       profile="currency-euro"
                       kisynced="true"
                       [errormessage]='formControl.get("netto").invalid ? getErrorMessage("netto") : null'
      ></dx-number-input>
      <dx-number-input #sonderzahlung_brutto_El
                       id="sonderzahlung_brutto"
                       (focus)="saveNameForOCR('brutto', sonderzahlung_brutto_El, OCRType.BETRAG)"
                       label="Jährlich brutto"
                       formControlName='brutto'
                       profile="currency-euro"
                       kisynced="true"
                       (valueChange)="resetValueComplete()"
                       (valueComplete)="onValueComplete()"
                       [errormessage]='(formControl.invalid || formControl.get("brutto").invalid) ? getErrorMessage("brutto") : null'
      ></dx-number-input>
    </dx-grid>
    <dx-radio-button-group id="sonderzahlung-radio-button-group" label="Was möchten Sie tun?" formControlName="bestandsId"
                           kisynced="true" (valueChange)="updateVersion($event.detail)">
      <dx-radio-button label="Als neue Sonderzahlung hinzufügen" value="" id="sonderzahlung-radio-button-0"></dx-radio-button>
      <dx-radio-button *ngFor="let sonderzahlung of sonderzahlungen; let i = index"
                       label="Bestehende Sonderzahlung von {{sonderzahlung.displayText}} ersetzen"
                       [value]="sonderzahlung.bestandsId"
                       id="sonderzahlung-radio-button-{{i+1}}">
      </dx-radio-button>
    </dx-radio-button-group>
  </div>
</div>

<div *ngIf='isReadonly' class="formcontrol-grid-readonly-item">
  <dx-text type="it" class="readonly-item">Jährlich netto</dx-text>
  <dx-text type="ps">
    {{ initialValue?.netto ? (initialValue.netto | number:'1.2-2':'de') + ' €' : '--' }}
  </dx-text>
  <dx-text type="it" class="readonly-item">Jährlich brutto</dx-text>
  <dx-text type="ps">
    {{ initialValue?.brutto ? (initialValue.brutto | number:'1.2-2':'de') + ' €' : '--' }}
  </dx-text>
</div>
