<dx-container color='background' type='page' style='margin-top: 24px;'>
  <section>

    <dx-text type='Headline-3'>Es gibt eine neue Version von Datenturbo.</dx-text>

    <dx-text type='ps'>
      Bitte haben Sie ein wenig Geduld, Sie werden automatisch weitergeleitet.
    </dx-text>
    <dx-loading-indicator size='large' label=''></dx-loading-indicator>
  </section>
</dx-container>
