import { Injectable } from '@angular/core'
import { CookiesStorageService } from '../cookies-storage.service'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class CookieZustimmungService {

  private showCookieDialogSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  public showCookieDialog$ = this.showCookieDialogSub.asObservable()
  protected dcCookieName = 'dvag_dc_cookieconsent'

  constructor(private cookieStorageService: CookiesStorageService) {
  }

  checkForCookiesAreAllowed() {
    setTimeout(() => {
      const consent = this.cookieStorageService.getCookie(this.dcCookieName)
      if (consent !== 'allow') {
        this.showCookieDialogSub.next(true)
      }
    }, 0)
  }

  setCookie() {
    this.cookieStorageService.setCookie(this.dcCookieName, 'allow', 365)
    this.showCookieDialogSub.next(false)
  }
}
