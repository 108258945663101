import { FremdvertragsAuswahl } from '../../components/formular/services/fremdvertraege.service'

export interface VertragDto {
  vertragId: string;
  formularId?: string;
  formularName?: string;
  syncStatus?: SyncStatus;
  erstellungsDatum?: string; // eigentlich muss dieses existieren
  bearbeitungsDatum?: string;
  kiFremdvertragId?: string;
  leistungsradar?: boolean;
}

export interface Vertrag extends VertragDto {
  kiFremdvertragOptionen?: FremdvertragsAuswahl[];
  isValid?: boolean;
  isNachbearbeitbar?: boolean;
}

export enum SyncStatus {
  EINGESTELLT = 'EINGESTELLT', // Ein neuer Vertrag der noch nicht in die KI geschrieben wurden
  SYNCHRONISIERT = 'SYNCHRONISIERT',  // Die Angaben aus dem Splitscreen wurden in die KI geschrieben.
  SYNCFEHLER = 'SYNCFEHLER' // Beim Schreiben in die KI gab es einen Fehler.
}
