import { Inject, Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { BeforeInstallPromptEvent } from '../store/models/before-install-prompt-event.model'
import { distinctUntilChanged } from 'rxjs/operators'
import { Platform } from '@angular/cdk/platform'
import { EnvironmentInfoService } from '../../../projects/environment-info/src/lib/environment-info.service'

@Injectable({
  providedIn: 'root'
})
export class InstallPwaToHomeScreenService {

  public isSafari = false
  public isChromBased = false
  private isNotInstalled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  private installPromptFn?: () => Promise<boolean>

  constructor(private readonly platform: Platform, private environmentInfoService: EnvironmentInfoService, @Inject('window') private window: Window) {
  }

  public get canInstall$(): Observable<boolean> {
    return this.isNotInstalled.asObservable().pipe(distinctUntilChanged())
  }

  public init() {
    this.checkPlatformAndEventsForPWAInstalled()
  }

  checkPlatformAndEventsForPWAInstalled(): void {
    if (
      this.environmentInfoService.checkiOSDevice() &&
      this.platform.SAFARI &&
      !this.window.navigator.userAgent.match('CriOS') &&
      !this.isAlreadyInstalledOniOS()
    ) {
      // Safari on iOS, all Browsers on iOS use the Safari rendering engine - only Chrome is filtered out
      this.isNotInstalled.next(true)
    } else if (this.platform.BLINK) {
      // Chromium-based browsers
      this.isChromBased = true
      this.addEventListenerForChromiumBasedBrowsers()
    }
  }

  /**
   * Opens a prompt for the user to add the PWA to the Homescreen. Before calling this method, check if
   * {@link canInstall$} contains `true`.
   *
   * @returns A promise that will resolve when prompt is available and contain whether the user accepted or declined
   *          if given the choice. If no choice was given, the promise will always contain true. The promise will
   *          reject if a prompt is not available.
   */
  public async promptInstall(): Promise<boolean> {
    if (this.installPromptFn) {
      return await this.installPromptFn()
    } else {
      throw new Error('Install prompt is not available')
    }
  }

  private addEventListenerForChromiumBasedBrowsers(): void {
    this.window.addEventListener('beforeinstallprompt', e => { // NOSONAR
      const event: BeforeInstallPromptEvent = e as BeforeInstallPromptEvent
      event.preventDefault()

      this.installPromptFn = async () => {
        event.prompt()
        const { outcome } = await event.userChoice
        this.isNotInstalled.next(false)
        this.installPromptFn = undefined
        return outcome === 'accepted'
      }

      this.isNotInstalled.next(true)
    })

    this.window.addEventListener('appinstalled', e => { // NOSONAR
      this.isNotInstalled.next(false)
    })
  }

  private isAlreadyInstalledOniOS(): boolean {
    // if are standalone android OR safari
    const iOSCanInstall = 'standalone' in this.window.navigator
    const iOSIsInstalled = (this.window.navigator as any).standalone === true
    return iOSCanInstall && iOSIsInstalled
  }
}
