import { Injectable } from '@angular/core'
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { from, Observable, of } from 'rxjs'
import { Authenticator } from '../model/authenticator'
import { Stage } from '../stage/stage'
import { mergeMap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class AuthenticatorInterceptor implements HttpInterceptor {

  authenticator: Authenticator

  constructor(private stage: Stage) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.startsWith(this.stage.getBackendUrl())) {
      return next.handle(request)
    }

    return this.getAuthenticationHeaders()
      .pipe(
        mergeMap(header => {
          return next.handle(request.clone({ setHeaders: header }))
        })
      )
  }

  private getAuthenticationHeaders(): Observable<{}> {
    if (!this.authenticator) {
      return of({})
    }

    return from(this.authenticator.authenticationHeaders())
  }

}
