import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core'
import { Formular } from '../../../../store/models/formular.model'
import { AuftragStatus } from '../../../../enums/auftrag.enums'
import { FormularFeldConfig } from '../../../../store/models/formular-feld-config.model'
import { FormBuilder, FormGroup } from '@angular/forms'
import { FormularFeldDictionaryService } from './services/formular-feld-dictionary.service'
import { Subscription } from 'rxjs'

@Component({
  selector: 'app-vertragsformular',
  templateUrl: './vertrags-formular.component.html',
  styleUrls: ['./vertrags-formular.component.scss'],
  providers: [FormularFeldDictionaryService]
})
export class VertragsFormularComponent implements OnDestroy {
  fields: FormularFeldConfig[] = []
  form: FormGroup
  formularInput: Formular = null
  initialAngaben: any = null
  private isValid = true
  private isValidSubscribtion: Subscription

  @Output() isFormularValid: EventEmitter<boolean> = new EventEmitter<boolean>()

  @Input() set formular(value: Formular) {
    if (value) {
      this.formularFeldDictionaryService.resetBasisInputFormularFeld()
      this.formularInput = value
      this.fields = value?.felder ?? []
      this.form = this.fb.group({})
      this.isFormularValid.emit(this.isValid)
      this.handleFormularValidityChanges()
    }
  }

  @Input() set angaben(value: any) {
    if (value) {
      this.form.reset()
      this.initialAngaben = value
      this.formularFeldDictionaryService.getListOfBasisInputFormularFeldNames()
        .forEach(fieldName => {
          this.formularFeldDictionaryService.getBasisInputFormularFeld(fieldName)?.setInitialValue(
            value[fieldName]
          )
        })
    }
  }

  @Input() showLoadingIndicator: boolean

  auftragBearbeitungEnum = AuftragStatus
  @Input() auftragBearbeitungsStatus: AuftragStatus

  @Input() auftragId: string

  constructor(private fb: FormBuilder,
              public formularFeldDictionaryService: FormularFeldDictionaryService) {
  }

  ngOnDestroy(): void {
    this.formularFeldDictionaryService = null
    this.isValidSubscribtion?.unsubscribe()
  }

  /**
   * check for unsaved data in the formular
   * @returns true if contains unsaved data and false if no data have to be saved
   */
  containsUnsavedData(): boolean {
    const changedFields = this.formularFeldDictionaryService.getListOfBasisInputFormularFeldNames()
      .filter(fieldname => this.formularFeldDictionaryService.getBasisInputFormularFeld(fieldname).hasValueChanged())

    console.log('Folgende Felder wurden geändert: ', changedFields.join(', '))

    return changedFields.length > 0
  }

  getAngaben(): any {
    return this.formularFeldDictionaryService.getListOfBasisInputFormularFeldNames()
      .reduce(((previousValue, currentValue) => {
          previousValue[currentValue] = this.formularFeldDictionaryService.getBasisInputFormularFeld(currentValue).readValue()
          return previousValue
        }
      ), {})
  }

  private handleFormularValidityChanges() {
    if (this.isValidSubscribtion) {
      this.isValidSubscribtion.unsubscribe()
    }
    this.isValidSubscribtion = this.form.valueChanges.subscribe(_ => {
      if (this.isValid !== this.form.valid) {
        this.isValid = this.form.valid
        this.isFormularValid.emit(this.isValid)
      }
    })
  }
}
