import { Injectable } from '@angular/core'
import { KeycloakLoginCheckResponse } from '../models/keycloak-login-check-response.model'
import { defer, from, Observable, of, retry, throwError } from 'rxjs'
import { environment } from '../../../environments/environment'
import Keycloak from 'keycloak-js'
import { catchError, delay, map } from 'rxjs/operators'

export interface ContextUser {
  name: string,
  vbNummer: string
}

@Injectable({
  providedIn: 'root',
})
export class KeycloakApiService {

  public keycloakInstance: Keycloak
  private readonly MIN_TOKEN_VALIDITY = 60 // We use 60 seconds because an upload could take longer than 30 seconds on 3G networks


  constructor() {
    this.keycloakInstance = new Keycloak(
      { ...environment.keycloak, url: environment.keycloak.baseUrl })
  }

  initKeycloak(): Observable<KeycloakLoginCheckResponse> {
    const initOptions: Keycloak.KeycloakInitOptions = {
      onLoad: 'check-sso',
      checkLoginIframe: false,
      scope: 'context_profile', // Wird benötigt für Vertreter und Assistenten Handling in Keycloak
      // checkLoginIframe: true,
      // checkLoginIframeInterval: 20
    }

    return from(this.keycloakInstance.init(initOptions))
      .pipe(
        catchError(() => of(false)),
        map(loggedIn => ({
          loggedIn: loggedIn,
          idmId: this.keycloakInstance.subject,
        } as KeycloakLoginCheckResponse)),
      )
  }

  login(redirektURL?: string): Observable<boolean> {
    if (redirektURL) {
      this.keycloakInstance.redirectUri = redirektURL
    }
    return from(this.keycloakInstance.login()).pipe(
      map(() => true)
    )
  }

  /**
   * Führt in Keycloak einen Login aus, aber mit der Aktion 'CONTEXT_CHANGE', um einen Benutzerwechsel zu triggern.
   */
  triggerKundenwechsel() {
    this.keycloakInstance.login({ action: 'CONTEXT_CHANGE' })
  }

  public isLoggedIn(): boolean {
    return this.keycloakInstance.authenticated && !!this.keycloakInstance.token
  }

  public isTokenExpired() {
    return this.keycloakInstance.isTokenExpired(this.MIN_TOKEN_VALIDITY)
  }


  /**
   * Führt ein updateToken durch, wenn der User eingeloggt ist und die Gültigkeit des
   * Tokens nicht mehr ausreicht. Ist der User nicht eingeloggt, so wird er zum Login geroutet.
   * Reicht die Gültigkeit des Tokens nicht aus, dann wird ein updateToken durchgeführt und entweder
   * das erneuerte oder das noch gültige Token returned. Sollte ein Fehler auftreten, so werden weitere
   * Versuche unternommen das Token zu updaten. Die Anzahl der Versuche insgesamt wird mit dem Parameter
   * retryCount angegeben
   * @param retryCount Die Anzahl der Versuche, die unternommen werden sollen, um das Token zu aktualisieren, Default: 3
   */
  updateTokenIfNeeded(retryCount: number = 3): Observable<string> {
    if (!this.isLoggedIn()) {
      this.login()
      return throwError(() => new Error('Benutzer ist nicht eingeloggt'))
    }

    if (!this.isTokenExpired()) {
      return of(this.keycloakInstance.token)
    }

    // Hier kann nicht der from-Operator verwendet werden, da dann die Promise nur einmal ausgeführt wird und das Result an alle Subscriber gegeben wird.
    // Da sich replay nur an dem Stream neu subscribed, bekommt es immer den gleichen value.
    // Erzeugt man das Observable mittels detach und packt die Promise in eine Factory-Methode, so wird diese jedes Mal ausgeführt, wenn sich jemand daran subscribed
    return defer(() => this.keycloakInstance.updateToken(this.MIN_TOKEN_VALIDITY)).pipe(
      map((refreshed) => {
        // Gibt true nach Aktualisierung des Tokens zurück und false,
        // wenn das Token noch valide ist.
        if (!refreshed) {
          console.log('Token is still valid')
        }
        return this.keycloakInstance.token
      }),
      retry({
        count: retryCount,
        delay: (error, retryAttempt) => {
          if (retryAttempt >= retryCount) {
            return throwError(() => new Error(`Maximale Wiederholungsversuche von '${retryCount}' erreicht`))
          }
          return of(error).pipe(delay(1000))
        },
      }),
      catchError((error) => {
        console.error('Fehler bei der Token-Aktualisierung: ', error)
        this.login()
        return throwError(() => new Error(`Token-Aktualisierung nach '${retryCount}' Versuchen fehlgeschlagen. Es wird ein Login angestoßen.`))
      }),
    )
  }

  isAuthenticated(): boolean {
    return this.keycloakInstance.authenticated
  }

  getToken(): string {
    return this.keycloakInstance.token
  }

  getTokenParsedItem(tokenParsedKey: string): any {
    return this.keycloakInstance?.tokenParsed?.[tokenParsedKey]
  }

  getVBNummerFromToken(): string {
    return this.getTokenParsedItem('preferred_username')
  }

  /**
   * Liefert den Namen des angemeldeten Benutzers. Im Falle eines Mitbenutzers
   * wird der Name geparst und in der Form "Mitbenutzer <Nummer>" zurückgegeben,
   * wo <Nummer> in diesem Fall für die letzte Ziffer der VBNummer steht (1-9).
   * @return Vollen Namen des angemeldeten Benutzers
   */
  getFullname(): string {
    const name = this.getTokenParsedItem('name')
    if (name.includes('Mitbenutzer')) {
      return 'Mitbenutzer ' + this.getVBNummerFromToken().charAt(this.getVBNummerFromToken().length - 1)
    }
    return name
  }

  /**
   * Liefert Daten zu dem vertretenen VB zurück, falls diese im Token vorhanden
   * sind. Existiert kein Context-User im Token, dann ist das Object 'undefined'
   * @return ContextUser
   */
  getContextUser(): ContextUser {
    const context = this.getTokenParsedItem('context')
    let contextUser: ContextUser


    if (context) {
      contextUser = { name: context['name'], vbNummer: context['preferred_username'] }
    }
    return contextUser
  }

  hasContextUser(): boolean {
    const contextUser = this.getTokenParsedItem('context')
    return !!contextUser
  }

  logout(): Observable<boolean> {
    return from(this.keycloakInstance.logout({ redirectUri: window.location.origin + `/dashboard` }))
      .pipe(map(() => true))
  }
}
