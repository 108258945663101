import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Auftrag } from '../models/auftrag.model'
import { environment } from '../../../environments/environment'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { OcrPage } from '../models/ocr.model'

@Injectable({
  providedIn: 'root'
})
export class AuftragHttpService {

  constructor(private httpClient: HttpClient) {}

  fetchAuftraege() {
    return this.httpClient.get<Auftrag[]>(environment.be.baseUrl + '/auftraege');
  }

  fetchAuftrag(auftragsId: string) {
    return this.httpClient.get<Auftrag>(environment.be.baseUrl + '/auftraege/' + auftragsId);
  }

  datenNachladenAuftrag(auftragsId: string) {
    return this.httpClient.post<any>(environment.be.baseUrl + '/auftraege/' + auftragsId + '/bereitstellung', null);
  }

  fetchDatei(auftragsId: string, dateiId: string): Observable<{ blob: Blob; width: number; height: number }> {
    return this.httpClient.get(environment.be.baseUrl + '/auftraege/' + auftragsId + '/dateien/' + dateiId + '/ocr/image', {
      responseType: 'blob',
      observe: 'response'
    }).pipe(
      map( response => (
        {
          blob: response.body,
          width: parseInt(response.headers.get('x-image-width'), 10),
          height: parseInt(response.headers.get('x-image-height'), 10),
      }))

    );
  }

  fetchDateiOcr(auftragsId: string, dateiId: string): Observable<OcrPage> {
    return this.httpClient.get<OcrPage>(environment.be.baseUrl + '/auftraege/' + auftragsId + '/dateien/' + dateiId + '/ocr');
  }

  addAuftrag(auftragData: Auftrag) {
    return this.httpClient.post<any>(environment.be.baseUrl + '/auftraege', auftragData);
  }

  deleteAuftrag(auftragId: string) {
    return this.httpClient.delete(environment.be.baseUrl + '/auftraege/' + auftragId);
  }

  fetchFormularAngaben(auftragId: string, formularId: string) {
    return this.httpClient.get<any>(environment.be.baseUrl + '/auftraege/' + auftragId + '/formulare/' + formularId + '/angaben');
  }

  updateFormularAngaben(auftragId: string, formularId: string, payload: any) {
    return this.httpClient.put<any>(environment.be.baseUrl + '/auftraege/' + auftragId + '/formulare/' + formularId + '/angaben', payload);
  }

  updateFormularId(auftragId: string, payload: any) {
    return this.httpClient.put<any>(environment.be.baseUrl + '/auftraege/' + auftragId + '/formular', payload);
  }

  updateKiFremdvertragId(auftragId: string, payload: any) {
    return this.httpClient.put<any>(environment.be.baseUrl + '/auftraege/' + auftragId + '/fremdvertrag', payload);
  }
}
