import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import * as moment from 'moment'
import { EnvironmentInfoService } from '../../../../../projects/environment-info/src/lib/environment-info.service'
import { Injector } from '@angular/core'
import { ValidatorService } from 'angular-iban'

// set moment locale
moment.locale('de')

const injector = Injector.create({
  providers: [
    { provide: EnvironmentInfoService }
  ]
})

export class FormularFeldValidatoren {

  static minimum(minWert: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const controlValue = Number(control.value)
      return controlValue < minWert ? { minimum: { valid: false } } : null
    }
  }

  static maximum(maxWert: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const controlValue = Number(control.value)
      return controlValue > maxWert ? { maximum: { valid: false } } : null
    }
  }

  static bisHeute(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value
      const momentValue = moment(value, 'YYYY-MM-DD')
      return momentValue.isAfter(moment.now()) ? { bis_heute: { valid: false } } : null
    }
  }

  static maxZeichen(anzahl: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const stringValue = control.value ? control.value + '' : ''
      // So können Verträge mit Syncherror erzeugt werden:
      const environment = injector.get(EnvironmentInfoService)
      if (control.value === '12345678901234567890123456' && environment.isDevelopmentEnv()) {
        return stringValue.length > anzahl + 2 ? { max_zeichen: { valid: false } } : null
      } else {
        return stringValue.length > anzahl ? { max_zeichen: { valid: false } } : null
      }

    }
  }

  static minZeichen(anzahl: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null
      }
      const stringValue = control.value + ''
      return stringValue.length < anzahl && stringValue.length > 0 ? { min_zeichen: { valid: false } } : null
    }
  }

  public static inZukunft(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null
      }
      const value = control.value
      const momentValue = moment(value, 'YYYY-MM-DD')
      return momentValue.isBefore(moment.now()) ? { inZukunft: { valid: false } } : null
    }
  }

  public static kennzeichen(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value

      const invalidKennzeichen = this.isInValidKennzeichen(value)

      return (invalidKennzeichen) ? { KENNZEICHEN: { valid: false } } : null
    }
  }

  private static isInValidKennzeichen(value): boolean {
    if (!value) {
      return false
    }

    const anzahlZeichenVorTrennzeichen = value.indexOf('-')

    // Ein bis drei Buchstaben bei Bezirk, ohne weitere Zeichen ist erlaubt
    if ((/^[a-zA-ZäöüÄÖÜ]{1,3}$/.test(value))) {
      return false
    }

    // vollständiges Kennzeichen hat immer "-"
    if (!value.includes('-')) {
      return true
    }

    const bezirk = value.substring(0, anzahlZeichenVorTrennzeichen)
    if (!/^[a-zA-ZäöüÄÖÜ]{1,3}$/.test(bezirk)) {
      return true
    }

    for (let i = anzahlZeichenVorTrennzeichen + 1; i < value.length; i++) {
      // i ist eine Ziffer
      if (!isNaN(value[i]) && isNaN(value[i - 1])) {
        const indexErsteZiffer = i

        const buchstaben = value.substring(anzahlZeichenVorTrennzeichen + 1, indexErsteZiffer)
        const ziffern = value.substring(indexErsteZiffer, value.length)

        return !(/^[a-zA-Z]{1,2}$/.test(buchstaben) && /^\s?[0-9]{1,4}[A-Z]?$/.test(ziffern))
      }
    }
    return true
  }

  static entsprichtRegex(regexValue: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null
      }
      const value = control.value + ''
      const regEx = RegExp(regexValue)
      const regexPassed = regEx.test(value)
      return !regexPassed ? { entspricht_Regex: { valid: false } } : null
    }
  }

  static required(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isEmpty = control.value == null || control.value === ''
      return isEmpty ? { required: { valid: false } } : null
    }
  }

  static nettoLessThanOrEqualToBrutto(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      const netto = group.get('netto')?.value
      const brutto = group.get('brutto')?.value

      if (brutto === undefined || brutto === null || brutto === '') {
        return null
      }
      if (netto !== null && brutto !== null && netto > brutto) {
        return { nettoLessThanOrEqualToBrutto: true }
      }
      return null
    }
  }

  static iban(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {

      let value = control.value

      // If the value is not set, do not validate
      if (!value) {
        return null
      }

      // check for allowed characters (DT-1584)
      value = value.replace(/\s+/g, '')
      const containsOnlyValidChars = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{11,30}$/i.test(value)
      if (!containsOnlyValidChars) {
        return { iban: { valid: false, message: 'Ungültige Zeichen in der IBAN' } }
      }

      return ValidatorService.validateIban(control)
    }
  }

}


