@if (!isReadonly) {
  <div [formGroup]='mainFormularGroup' style='width: 100%;'>
    <dx-text id='vers_pers_main_headline' type='h5'>{{ field.label }}</dx-text>
    <div formGroupName='{{field.name}}' style='display: flex; flex-direction: column; gap: 16px;'>
      @if (finishedLoading) {
        @if (nestedFormArray.controls.length > 0) {
          <div formArrayName='nestedFormArray'
               style='display: flex; flex-direction: column; gap: 12px;'>
            @for (nestedForm of nestedFormArray.controls; let formIndex = $index; track nestedForm.get('kundennummer').value) {
              <div [formGroupName]='formIndex'>
                <dx-state-icon state='kisynced'
                               style='display: flex; flex-direction: row-reverse; margin-bottom: 6px;'></dx-state-icon>
                <dx-card>
                  <dx-card-collapsable id='card_vers_pers_{{formIndex}}'
                                       #versichertepersoncard
                                       [chevronvisible]='haushaltsMitglieder.length > 1'
                                       [open]='haushaltsMitglieder.length === 1'>
                    <dx-card-header id='card_vers_pers_{{formIndex}}_header' slot='header'>
                      <div class='action-container'
                           [ngClass]="{'chevron-not-visible': haushaltsMitglieder.length === 1}">
                        <dx-dropdown style='width:100%;'
                                     id='vers_pers_auswahl_{{formIndex}}'
                                     placeholder='Person auswählen'
                                     [formControlName]="'kundennummer'"
                                     [readonly]='haushaltsMitglieder.length === 1'
                                     (valueComplete)='onHaushaltsmitgliedSelected(formIndex)'
                                     clearable='false'
                        >
                          @for (person of haushaltsMitgliederAuswahlOptionen()[formIndex]; let persIndex = $index; track person.kundenNummer) {
                            <dx-list-item
                              id='vers_pers_auswahl_{{formIndex}}_{{persIndex}}'
                              [value]='person.kundenNummer'
                              [label]='person.name'
                              sublabel="Geboren am {{person.geburtsDatum | date: 'dd.MM.yyyy'}}"
                            ></dx-list-item>
                          }
                        </dx-dropdown>
                        @if (haushaltsMitglieder.length > 1 && nestedFormArray.controls.length > 1 && readValue()?.length > 1) {
                          <dx-button id='remove_pers_{{formIndex}}' (click)='removeNestedForm(formIndex)'
                                     type='text'
                                     theme='destructive'
                                     icon='Loeschen' slot='action'></dx-button>
                        }
                      </div>
                    </dx-card-header>
                    <dx-card-content>
                      <dx-grid mq3='12/*' mq4='6-6/*'>
                        @for (versichertePersonConfig of unterformularFeldConfigs; track versichertePersonConfig.name) {

                          <ng-template #errorMessageTemplate let-config="config" let-index="index">
                            <dx-error-message [id]="config.name + '_errormessage_' + index" slot='error-message'>
                              @for (validator of config.validators; track validator.name) {
                                @if (nestedForm.get(config.name).errors[validator.name.toLowerCase()]) {
                                  <ng-container>{{ validator.fehlerText }}</ng-container>
                                }
                              }
                            </dx-error-message>
                          </ng-template>

                          @switch (versichertePersonConfig.type) {
                            @case (OCRType.TEXT) {
                              <dx-text-input #textInputElement
                                             [id]="versichertePersonConfig.name + '_' + formIndex"
                                             [label]='versichertePersonConfig.label'
                                             [kisynced]='versichertePersonConfig.kiSync'
                                             (focus)='saveNameForOCR(versichertePersonConfig.name, textInputElement, OCRType.TEXT, formIndex)'
                                             [formControlName]='versichertePersonConfig.name'
                              >
                                @if (nestedForm.get(versichertePersonConfig.name).invalid) {
                                  <ng-container *ngTemplateOutlet="errorMessageTemplate; context: { config: versichertePersonConfig, index: formIndex }"></ng-container>
                                }
                              </dx-text-input>
                            }
                            @case (OCRType.BETRAG) {
                              <dx-number-input #betragsInputElement
                                               [id]="versichertePersonConfig.name + '_' + formIndex"
                                               [label]='versichertePersonConfig.label'
                                               [kisynced]='versichertePersonConfig.kiSync'
                                               (focus)='saveNameForOCR(versichertePersonConfig.name, betragsInputElement, OCRType.BETRAG, formIndex)'
                                               [formControlName]='versichertePersonConfig.name'
                                               profile='currency-euro'
                              >
                                @if (nestedForm.get(versichertePersonConfig.name).invalid) {
                                  <ng-container *ngTemplateOutlet="errorMessageTemplate; context: { config: versichertePersonConfig, index: formIndex }"></ng-container>
                                }
                              </dx-number-input>
                            }
                            @case (OCRType.PROZENT) {
                              <dx-number-input #prozentInputElement
                                               [id]="versichertePersonConfig.name + '_' + formIndex"
                                               [label]='versichertePersonConfig.label'
                                               [kisynced]='versichertePersonConfig.kiSync'
                                               (focus)='saveNameForOCR(versichertePersonConfig.name, prozentInputElement, OCRType.PROZENT, formIndex)'
                                               [formControlName]='versichertePersonConfig.name'
                                               profile='percent'
                              >
                                @if (nestedForm.get(versichertePersonConfig.name).invalid) {
                                  <ng-container *ngTemplateOutlet="errorMessageTemplate; context: { config: versichertePersonConfig, index: formIndex }"></ng-container>
                                }
                              </dx-number-input>
                            }
                          }
                        }
                      </dx-grid>
                    </dx-card-content>
                  </dx-card-collapsable>
                </dx-card>
              </div>
            }
          </div>
        }
        @if (haushaltsMitglieder.length > 1) {
          <div style='display: flex; flex-direction: row; justify-content: space-between;'>
            <dx-button id='add-versicherte-person' label='Weitere versicherte Person hinzufügen' icon='plus-kreis'
                       type='text'
                       [disabled]='nestedFormArray.controls.length === haushaltsMitglieder.length'
                       (click)='addNestedForm()'></dx-button>
          </div>
        }
      } @else {
        <dx-loading-indicator id='vers_pers_loading_indicator'
                              size='medium'
                              theme='light'
                              label=''
                              style='align-self: center; margin-top: 16px'>
        </dx-loading-indicator>
      }
    </div>
  </div>
} @else {
  <div id='vers_pers_readonly_container'>
    <dx-text id='vers_pers_main_headline_readonly' type='h5' style='margin-bottom: 8px;'>{{ field.label }}</dx-text>
    @if (!readonlyValue && !finishedLoading) {
      <dx-loading-indicator id='vers_pers_loading_indicator_readonly'
                            size='small'
                            theme='light'
                            label=''
                            style='align-self: flex-start;'>
      </dx-loading-indicator>
    } @else if (!readonlyValue && finishedLoading) {
      <dx-text id='vers_pers_readonly_novalue' type='ps'>--</dx-text>
    } @else {
      @for (values of readonlyValue; track values.kundennummer; let index = $index) {
        <div style='display: flex; flex-direction: column;'>
          <div id='vers_pers_action_container_{{index}}' class='action-headline' (click)='toogleReadonly(index)'>
            <dx-icon #readonlychevrons id='vers_pers_action_container_icon_{{index}}' icon='Chevron-unten' size='16'
                     color='default' style='padding-top: 2px;'></dx-icon>
            <dx-text id='vers_pers_action_container_name_{{index}}' type='ps'>{{ values.kundenname }}</dx-text>
          </div>
          <div #readonlyvalues id='vers_pers_value_container_{{index}}' class='toggleable-values-container'
               style='display: none;'>
            <!--"display: none" darf nicht in das scss, da sonst der erste klick auf den Toggle nicht funktioniert -->
            @for (field of unterformularFeldConfigs; track field.name) {
              @if (field.type !== 'HIDDEN') {
                <div id='vers_pers_value_container_{{index}}_{{field.name}}' class='formcontrol-grid-readonly-item'>
                  <dx-text type='it' id='vers_pers_value_container_{{index}}_{{field.name}}_label'>{{ field.label }}
                  </dx-text>
                  <dx-text type='ps'
                           id='vers_pers_value_container_{{index}}_{{field.name}}_value'>{{ values[field.name] ?? '--' }}
                  </dx-text>
                </div>
              }
            }
          </div>
        </div>
      }
    }
  </div>
}

