import { Component, ViewChild } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { BasisInputFormularFeld } from '../basis-input-formular-feld'
import { AktivesEingabefeldHandlerService } from '../services/aktives-eingabefeld-handler.service'
import { DxCheckbox } from '@dvag/design-system-angular'
@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html'
})
export class CheckboxComponent extends BasisInputFormularFeld {

  @ViewChild('focusElement') focusElement: DxCheckbox

  constructor(public override fb: FormBuilder, public override focusedControlHandlerService: AktivesEingabefeldHandlerService) {
    super(fb, focusedControlHandlerService)
  }

  override prepareOcrTextForFormControl(ocrText: string, oldValue: any): any {
    return !oldValue
  }

  override getReadonlyDisplayValue(): string {
    if(this.initialValue == null ) {
      return ''
    }
    return this.initialValue ? 'ja' : 'nein'
  }

  override async focus(): Promise<void> {
    if( this.focusElement?.focusControl ) {
      try {
        await this.focusElement?.focusControl()
      } catch {
        console.log(`Es ist eine Fehler beim Setzen des Fokus beim Feld '${this.field.name}' augetreten`)
      }
    } else {
      console.warn(`An dem FormularFeld '${this.field.name}' vom Typ '${typeof this.focusElement}' existiert keine Methode 'focusControl()'!`)
    }
  }
}
