<div *ngIf="!isReadonly" [formGroup]="mainFormularGroup">
  <dx-text-input #focusElement
                 [formControlName]="field.name"
                 id="{{field.name}}_wert"
                 label="{{field.label}}"
                 (focus)="storeNameOfFocusedElement(field.name)"
                 (valueComplete)="validate($event.detail)"
                 (valueChange)="hasConfigMaskierung? errormessage = '' : validate($event.detail)"
                 [errormessage]="errormessage"
                 [kisynced]="field.kiSync"
                 (keydown)="hasConfigMaskierung? valueAdded($event): null">
  </dx-text-input>
</div>
<app-readonly-template *ngIf="isReadonly" [field]="field" [displayValue]="getReadonlyDisplayValue()"></app-readonly-template>
