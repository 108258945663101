<ng-container *ngIf='!isReadonly'>
    <form (ngSubmit)='onAddItem()' [formGroup]='multitextFormGroup' autocomplete='off'>
        <dx-text-input #additem formControlName='addItem'
                       (valueComplete)='onAddItem()'
                       id='{{field.name}}_wert'
                       label='{{field.label}}'
                       (focus)='storeNameOfFocusedElement(field.name)'
                       maxlength='{{maxZeichen ? maxZeichen : null}}'
                       [kisynced]='field.kiSync'>
        </dx-text-input>
    </form>
    <div class='pills'>
        <div id='{{field.name}}_pill_{{i}}' class='pill' *ngFor='let valueNow of finalValue; let i = index;'>
            <dx-text type='ps' id='{{field.name}}_pill_{{i}}_wert'>{{ valueNow }}</dx-text>
            <dx-icon icon='x-gross' (click)='onDelItem(i)' size='16'></dx-icon>
        </div>
    </div>

    <span [formGroup]='mainFormularGroup'>
    <input id='{{field.name}}_hidden-input' [formControlName]='field.name' hidden>
  </span>
</ng-container>
<div *ngIf='isReadonly' class='multitext-readonly' id='{{field.name}}_readonly_container'>
    <dx-text type="it" id='{{field.name}}_label'>{{field.label}}</dx-text>
    <div *ngIf="initialValue" id='{{field.name}}_wert' style='overflow: hidden;'>
        <ul>
            <li *ngFor="let besonderheit of initialValue; let idx = index" id='{{field.name}}_{{idx}}_wert'><dx-text type='ps'>{{besonderheit}}</dx-text></li>
        </ul>
    </div>
    <dx-text *ngIf="!initialValue" type="ps" id="{{field.name}}_wert" color='gray-16'>--</dx-text>
</div>
