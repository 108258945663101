import { VertragHttpService } from '../../../../../store/services/vertrag-http.service'
import { ReplaySubject } from 'rxjs'
import { Injectable } from '@angular/core'

@Injectable()
export class VertragService {

  private angabenSubject = new ReplaySubject(1)
  public angaben$ = this.angabenSubject.asObservable()

  constructor(private vertragHttpService: VertragHttpService) {}

  loadAngabenForSelection(auftragId: string, vertragId: string): void {
    if(!auftragId || !vertragId ) {
    this.handleErrorOnLoadAngaben({expandMsg: `Die Angaben konnten nicht angefragt werden, da nötige Parameter fehlen! AuftragID: ${auftragId}; VertragId: ${vertragId}`})
      return
    }

    this.vertragHttpService.fetchFormularAngaben(auftragId, vertragId).subscribe({
      next: (angaben) => {
        this.angabenSubject.next(angaben)
      },
      error: err => {
        this.handleErrorOnLoadAngaben({error: err})
      }
    })
  }

  handleErrorOnLoadAngaben(input?: { error?: Error, expandMsg?: string }) {
    //TODO: Handle Error
    console.warn(`Beim Laden der Angaben ist ein Fehler aufgetreten. ${input.expandMsg} ${input.error ? ' Error: ' + input.error: ''}`)
    this.angabenSubject.next(null)
    return
  }
}
