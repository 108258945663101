import {Component, Input} from '@angular/core';
import {LeistungsradarResult} from './model/leistungsradarresult.model'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'dt-leistungsradar',
  templateUrl: './leistungsradar.component.html',
  styleUrls: ['./leistungsradar.component.scss']
})
export class LeistungsradarComponent {
  result: LeistungsradarResult

  @Input()
  set vergleich(value: LeistungsradarResult) {
    if (value) {
      this.result = value;
      this.result.bereich.name = value.bereich.name
    }
  }

  segment = 'vergleich'
}
