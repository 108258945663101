import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import {  BehaviorSubject, Observable, of, Subscription, timer } from 'rxjs'
import { Auftrag } from '../../store/models/auftrag.model'
import { AuftragState } from '../../store/states/auftrag.state'
import { DeleteAuftrag, FetchAuftraege } from '../../store/actions/auftrag.actions'
import { catchError, concatMap, distinctUntilChanged, filter, map } from 'rxjs/operators'
import { FileUploadService } from './services/file-upload.service'
import { FilteredAuftraegeService } from './services/filtered-auftraege.service'
import { CookieZustimmungService } from '../cookies/cookie-zustimmung/cookie-zustimmung.service'
import { UploadToolDialogComponent } from 'upload-components'
import { EnvironmentInfoService } from '../../../../projects/environment-info/src/lib/environment-info.service'
import { Router } from '@angular/router'
import { AuftragsFilterService } from './services/auftrags-filter.service'
import { AuftragDatenbestand, AuftragKategorie } from '../../enums/auftrag.enums'
import { NotificationService, NotificationTyp } from '../services/notification.service'
import { DxAlert } from '@dvag/design-system-angular'
import { FormBuilder, FormGroup } from '@angular/forms'
import { ApplicationInsightsService, DT_FE_CUSTOM_EVENT } from '../../services/application-insights.service'

@Component({
  selector: 'app-auftraglist',
  templateUrl: './auftraglist.component.html',
  styleUrls: ['./auftraglist.component.scss'],
})
export class AuftraglistComponent implements OnInit, OnDestroy {
  form: FormGroup;

  @Select(AuftragState.getAuftragList)
  auftragList$: Observable<Auftrag[]>
  private auftragListSub: Subscription

  // merkt sich die aktuellste Liste von Auftraegen, der auch immer wieder zum Filtern verwendet wird
  private auftraegeSubject= new BehaviorSubject<Auftrag[]>([])

  // enthählt die gefilterten Auftraege
  private filteredAuftraegeSubject = new BehaviorSubject<Auftrag[]>([])
  private filteredAuftraege$: Observable<Auftrag[]> = this.filteredAuftraegeSubject.asObservable()
  offeneAuftraege$: Observable<Auftrag[]> = this.filteredAuftraege$.pipe(
    map(auftraege => auftraege.filter(auftrag => auftrag.kategorie === AuftragKategorie.OFFEN)),
  )
  bearbeiteteAuftraege$: Observable<Auftrag[]> = this.filteredAuftraege$.pipe(
    map(auftraege => auftraege.filter(auftrag => auftrag.kategorie === AuftragKategorie.BEARBEITET)),
  )

  public auftraegeLadenSpinner = true
  public pollingAuftragListSub: Subscription

  public zuLoeschenderAuftrag: Auftrag
  public zuLoeschenderAuftragType: string
  public zuLoeschenderAuftragPerson: string
  public simpleSplitscreenErrorAlertTitle: string

  public selectedTab: AuftragKategorie = AuftragKategorie.OFFEN
  public sorting: 'up' | 'down'
  public searchTerm: string

  @ViewChild('auftraglisteAlert') auftragListeAlert: DxAlert
  @ViewChild('loeschenAlert') loeschenAlert: DxAlert
  @ViewChild('uploadtooldialog') uploadToolDialog: UploadToolDialogComponent

  protected readonly AuftragDatenbestand = AuftragDatenbestand
  protected readonly AuftragKategorie = AuftragKategorie

  constructor(
    public fileUploadServive: FileUploadService,
    public environmentInfoService: EnvironmentInfoService,
    private filteredAuftraegeService: FilteredAuftraegeService,
    private store: Store,
    private cookiesService: CookieZustimmungService,
    private router: Router,
    private auftragsFilterService: AuftragsFilterService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private applicationInsightsService: ApplicationInsightsService,
  ) {
    this.form = this.fb.group({
      testControl: ['']
    });
  }

  @HostListener('window:beforeunload', ['$event']) beforeUnloadHander(event: any) {
    return this.fileUploadServive.activeUploads.size <= 0
  }

  ngOnInit() {
    const { searchterm, sorting, kategorie } = this.auftragsFilterService.getFilter()
    this.sorting = sorting
    this.selectedTab = kategorie
    this.searchTerm= searchterm

    this.cookiesService.checkForCookiesAreAllowed()


    // Wir reagieren nur auf neue Auftraege vom BE, wenn sich was zum letzten Aufruf geändert hat
    this.auftragListSub = this.auftragList$.pipe(
      distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)),
    ).subscribe(auftraege => {
      this.auftraegeSubject.next(auftraege)

      // wenn sich was ändert, dann soll sofort der aktuelle Filter auf die neue Liste angewendet werden
      this.filteredAuftraegeSubject.next(this.filteredAuftraegeService.filterAuftraege(auftraege, sorting, this.searchTerm))
    })

    // trigger initial filling of store
    if (this.auftraegeSubject.value.length === 0) {
      this.auftraegeLadenSpinner = true
    }
    this.store.dispatch(new FetchAuftraege()).pipe(
      catchError(err => {
        console.log('Error on action \'FetchAuftraege\'.', err)
        this.notificationService.showNotification('Achtung', 'Die Aufträge konnten nicht geladen werden, da ein unerwarteter Fehler aufgetreten ist. Bitte versuchen Sie es in ein paar Minuten erneut.', 7, NotificationTyp.achtung)
        return of('')
      }),
    ).subscribe({
      next: _ => {
        this.auftraegeLadenSpinner = false
      }, error: _ => {
        this.auftraegeLadenSpinner = false
      },
    })

    this.pollingAuftragListSub = timer(0, 2000)
      .pipe(
        filter(() => this.auftraegeSubject.value.some(auftrag => auftrag.datenbestand === AuftragDatenbestand.BEREITSTELLUNG)),
        concatMap(_ => this.store.dispatch(new FetchAuftraege())),
      ).subscribe()
  }

  ngOnDestroy() {
    this.auftragListSub.unsubscribe()
    this.pollingAuftragListSub.unsubscribe()
    this.auftragsFilterService.updateFilter(this.searchTerm, this.selectedTab, this.sorting)
  }

  filterAuftragsliste(value?: string) {
    this.searchTerm = value ?? ''
    this.filteredAuftraegeSubject.next(this.filteredAuftraegeService.filterAuftraege(this.auftraegeSubject.value, this.sorting, this.searchTerm))
  }

  toggleSortierung() {
    this.sorting = this.sorting === 'up' ? 'down' : 'up'
    this.filterAuftragsliste(this.searchTerm)
    this.applicationInsightsService.logEvent(DT_FE_CUSTOM_EVENT.DASHBOARD_SORTIERUNGSWECHSEL)
  }

  selectTab(value: AuftragKategorie) {
    this.selectedTab = value
    this.applicationInsightsService.logEvent(DT_FE_CUSTOM_EVENT.DASHBOARD_TABWECHSEL)
  }

  public async onDeleteAuftrag(auftrag: Auftrag) {
    this.zuLoeschenderAuftrag = auftrag

    this.zuLoeschenderAuftragType = auftrag.vertraege.length > 1 ? 'Bündelvertrag' : `"${auftrag.vertraege[0].formularName}"`
    this.zuLoeschenderAuftragPerson = auftrag.kundenName ?? auftrag.kundenNummer

    this.loeschenAlert.visible = true
  }

  declinedDeleteAuftrag() {
    this.loeschenAlert.visible = false
  }

  confirmedDeleteAuftrag() {
    this.store.dispatch(new DeleteAuftrag(this.zuLoeschenderAuftrag.auftragId)).subscribe({
        next: () => {
          this.loeschenAlert.visible = false
        },
        error: err => {
          this.loeschenAlert.visible = false
          console.log('Error on action \'DeleteAuftrag\'. The Auftrag with the id \' %s\' could not be removed. ', this.zuLoeschenderAuftrag.auftragId, err)
          this.showSimpleSplitscreenErrorAlert('Fehler beim Löschen des Auftrags')
        },
      },
    )
  }

  showDownloadError() {
    this.showSimpleSplitscreenErrorAlert('Fehler beim Herunterladen der Datei')
  }

  closeSimpleSplitscreenErrorAlert() {
    this.auftragListeAlert.visible = false
  }

  showSimpleSplitscreenErrorAlert(title: string) {
    this.simpleSplitscreenErrorAlertTitle = title
    this.auftragListeAlert.visible = true
  }
}
