<dx-icon *ngIf="!isDownloading && iconOnly" id="{{auftragsId}}-herunterladen-icon" icon="download" size="24" color='default' (click)="downloadDatei($event)"></dx-icon>
<dx-button *ngIf="!isDownloading && !iconOnly"
           id="{{auftragsId}}-herunterladen"
           type="text"
           icon="download"
           theme='default'
           (click)="downloadDatei($event)">
</dx-button>
<div *ngIf="isDownloading" [ngClass]="{'space-around': !iconOnly}">
  <dx-loading-indicator id='download-spinner' size="small" theme='light' label=''></dx-loading-indicator>
</div>
