<div *ngIf="!isReadonly" [formGroup]="mainFormularGroup">
  <dx-dropdown #focusElement
               [formControlName]="field.name"
               id="{{field.name}}_wert"
               label="{{field.label}}"
               required="{{field.required}}"
               clearable='true'
               (focus)="storeNameOfFocusedElement(field.name)"
               [kisynced]="field.kiSync">
    <dx-list-item *ngFor="let option of field.selectOptions; let idx = index" [value]="option" [label]="option" [id]="'tarifname_wert-list-item-' + idx + '-control'"></dx-list-item>
  </dx-dropdown>
</div>
<app-readonly-template *ngIf="isReadonly" [field]="field" [displayValue]="getReadonlyDisplayValue()"></app-readonly-template>
