/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { KeycloakBearerInterceptor } from './auth-interceptor'
import { ViernulldreiInterceptor } from './viernulldrei-interceptor'
import { RequestIdInterceptor } from './requestid-interceptor'
import { VersionInterceptor } from './version-interceptor'
import { ViernulleinsInterceptor } from './viernulleins.interceptor'

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: KeycloakBearerInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ViernulldreiInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ViernulleinsInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestIdInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: VersionInterceptor,
    multi: true
  }
]
