import { Injectable } from '@angular/core'
import { BehaviorSubject, forkJoin, Observable, skipWhile } from 'rxjs'
import { Vertrag } from '../../../store/models/vertrag.model'
import { FremdvertraegeService, FremdvertragsAuswahl } from '../../formular/services/fremdvertraege.service'
import { Auftrag } from '../../../store/models/auftrag.model'
import { take } from 'rxjs/operators'
import { VertragHttpService } from '../../../store/services/vertrag-http.service'
import { AuftragStatus } from '../../../enums/auftrag.enums'
import * as uuid from 'uuid'
import { VertragComponent } from '../components/vertrag/vertrag.component'
import { NotificationService, NotificationTyp } from '../../services/notification.service'

@Injectable({
  providedIn: 'root'
})
export class AuftragBearbeitenService {
  auftrag: Auftrag
  private auftragSubject = new BehaviorSubject<Auftrag>(null)
  auftrag$ = this.auftragSubject.asObservable()

  private allKiFremdvertragsAuswahlOptionen: FremdvertragsAuswahl[] = []

  private vertraegeSubject: BehaviorSubject<Vertrag[]> = new BehaviorSubject<Vertrag[]>(null)
  vertraege$ = this.vertraegeSubject.asObservable()

  private vertragInBearbeitungSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null)
  vertragInBearbeitung$ = this.vertragInBearbeitungSubject.asObservable()

  private ladeFremdvertraege: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  ladeFremdvertraege$ = this.ladeFremdvertraege.asObservable()

  private vertraegeBeimLadenVonDaten: string[] = []
  private vertraegeInCommunicationWithBackendSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([])
  vertraegeInCommunicationWithBackend$ = this.vertraegeInCommunicationWithBackendSubject.asObservable()

  constructor(private fremdvertraegeService: FremdvertraegeService,
              private vertragHttpService: VertragHttpService,
              private notificationService: NotificationService) {
  }

  /*
    initialize the service and make all initial calls to the backend for the given auftrag
   */
  initAuftragService(auftrag: Auftrag, auftragBearbeitbarStatus: AuftragStatus): void {
    console.log('AuftragBearbeitenService.initAuftragService(): auftragBearbeitbarStatus = %s --- auftrag: ', auftragBearbeitbarStatus, auftrag)
    if (!auftrag) {
      return
    }
    this.auftrag = auftrag
    this.auftragSubject.next(auftrag)

    // Im Readonly Modus brauchen keine Fremdverträge geladen werden, da diese
    if (auftragBearbeitbarStatus === AuftragStatus.BEARBEITUNG) {
      this.fetchFremdvertragAuswahlOptionen()
    }
    this.vertraegeSubject.next(auftrag.vertraege)

    if (this.vertraegeSubject.value.length === 1) {
      this.vertragInBearbeitungSubject.next(this.vertraegeSubject.value[0].vertragId)
    }
  }

  /**
   * Fremdvertraege needs to be load once because we receive all and filter them by FormularId.
   * We only need an array of FremdvertragAuswahl thats why we fetch this directly
   */
  private fetchFremdvertragAuswahlOptionen(): void {
    console.log('AuftragBearbeitenService.fetchFremdvertragAuswahlOptionen()')
    this.ladeFremdvertraege.next(true)
    this.fremdvertraegeService.fetchFremdvertragAuswahlOptionen(this.auftrag.kundenNummer).pipe(
      take(1),
      skipWhile(_ => this.auftrag === null)
    ).subscribe({
      next: fremdvertragAuswahlOptionen => {
        this.allKiFremdvertragsAuswahlOptionen = fremdvertragAuswahlOptionen
        this.setFremdvertragAuswahlOptionenForEachVertrag()
        this.ladeFremdvertraege.next(false)
      },
      error: err => {
        this.ladeFremdvertraege.next(false)
        this.allKiFremdvertragsAuswahlOptionen = []
        console.error('Beim Laden der Fremdverträge ist ein Fehler aufgetreten.', err)
      }
    })
  }

  /**
   * Unsubscribe to all subscriptions and
   */
  resetService(): void {
    console.log('AuftragBearbeitenService.resetService()')
    this.allKiFremdvertragsAuswahlOptionen = []
    this.auftrag = null
    this.vertraegeSubject.next(null)
    this.vertragInBearbeitungSubject.next(null)
    this.auftragSubject.next(null)
  }

  addVertragHuelle(): void {
    const id = uuid.v4()
    this.auftrag.vertraege.push({ vertragId: id, isValid: true })
    this.vertraegeSubject.next(this.auftrag.vertraege)
    this.vertragInBearbeitungSubject.next(id)
  }

  /**
   * Add a new contract to current Auftrag
   */
  addVertrag(vertragId: string, formularId: string): void {
    if (!vertragId || !formularId) {
      console.error('Aufgrund von fehlenden Daten kann kein Vertrag angelegt werden.')
      return
    }
    this.vertragHttpService.addVertrag(this.auftrag.auftragId, { vertragId, formularId }).subscribe({
      next: vertragToAdd => {
        this.auftrag.vertraege = this.auftrag.vertraege.map(existingVertrag => existingVertrag.vertragId !== vertragId ? existingVertrag : vertragToAdd)
        this.setFremdvertragAuswahlOptionenForEachVertrag()
        this.vertragInBearbeitungSubject.next(vertragToAdd.vertragId)
      },
      error: err => {
        console.error('Bei dem Hinzufügen eines Auftrags ist ein Fehler aufgetreten., ', err)
        this.notificationService.showNotification('Achtung', 'Es konnte kein Vertrag angelegt werden, bitte versuchen Sie es später erneut.', 3, NotificationTyp.achtung)

      }
    })
  }

  /**
   * Deletes a given contract of the current Auftrag
   */
  deleteVertrag(vertragId: string): void {
    if (this.auftrag.vertraege.length === 1) {
      console.error(`Der Vertrag mit der ID '%s' darf nicht gelöscht werden, da dies der letzte ist`)
      return
    }
    // sende bei Huellenvertraegen keinen Request ans BE
    if (!this.auftrag.vertraege.find(vertrag => vertrag.vertragId === vertragId).formularId) {
      this.removeVertrag(vertragId)
      return
    }

    this.vertragHttpService.deleteVertrag(this.auftrag.auftragId, vertragId).subscribe({
      next: () => {
        this.removeVertrag(vertragId)
      },
      error: err => {
        console.error('Beim Löschen des Auftrags ist ein Fehler aufgetreten., ', err)
        this.notificationService.showNotification('Achtung', 'Beim Löschen des Auftrags ist ein Fehler aufgetreten.', 3, NotificationTyp.achtung)
      }
    })
  }

  private removeVertrag(vertragId: string) {
    console.log('AuftragBearbeitenService.handleDeletVertrag(): ', vertragId)
    this.auftrag.vertraege = this.auftrag.vertraege.filter(vertrag => vertrag.vertragId !== vertragId)
    this.setFremdvertragAuswahlOptionenForEachVertrag()

    if (this.auftrag.vertraege.length === 1) {
      this.vertragInBearbeitungSubject.next(this.auftrag.vertraege[0].vertragId)
    }
  }

  changeKiFremdvertragForVertrag(vertragId: string, kiFremdvertragId: string) {
    this.addVertragToLadeAngaben(vertragId)
    this.vertragHttpService.updateKiFremdvertrag(this.auftrag.auftragId, vertragId, { kiFremdvertragId: kiFremdvertragId }).subscribe({
      next: () => {
        this.auftrag.vertraege = this.auftrag.vertraege.map(vertrag => {
          if (vertrag.vertragId === vertragId) {
            vertrag = { ...vertrag, kiFremdvertragId }
          }
          return vertrag
        })
        this.setFremdvertragAuswahlOptionenForEachVertrag()
      },
      error: err => {
        console.error(`Bei dem Ändern der KiFremdvertragsId '%s' zum Vertrag '%s' ist ein Fehler aufgetreten.`, kiFremdvertragId, vertragId, err)
        this.notificationService.showNotification('Achtung', 'Es ist ein Fehler aufgetreten. Es wurde zum zuvor gewählten Fremdvertrag zurück gewechselt.', 3, NotificationTyp.achtung)
        this.vertraegeSubject.next(this.auftrag.vertraege)
        this.removeVertragFromLadeAngaben(vertragId)
      }
    })
  }


  changeFormularForVertrag(vertragId: string, formularId: string, formularName: string, isNachbearbeitbar: boolean) {
    this.addVertragToLadeAngaben(vertragId)
    this.vertragHttpService.updateFormulartyp(this.auftrag.auftragId, vertragId, { formularId: formularId }).subscribe({
      next: (newVertrag) => {
        this.auftrag.vertraege = this.auftrag.vertraege.map(vertrag => {
          if (vertrag.vertragId === vertragId) {
            vertrag = { ...newVertrag, isNachbearbeitbar, isValid:true }
          }
          return vertrag
        })
        this.setFremdvertragAuswahlOptionenForEachVertrag()
      },
      error: err => {
        console.error(`Bei der Änderung des DokumentTyps '%s' zum Vertrag '%s' ist ein Fehler aufgetreten., `, formularId, vertragId, err)
        this.notificationService.showNotification('Achtung', 'Es ist ein Fehler aufgetreten. Bitte probieren Sie es erneut.', 3, NotificationTyp.achtung)
        this.vertraegeSubject.next(this.auftrag.vertraege)
        this.removeVertragFromLadeAngaben(vertragId)
      }
    })
  }

  /**
   * Hier werden die Fremdvertrag Optionen für jeden Vertrag im Auftrag gesetzt.
   * Es werden alle Verträge durchlaufen und zunächst nur die Optionen berücksichtigt, die nicht schon bei einem anderen
   * Vertrag als KiFremdvertrag gesetzt sind. Danach erfolgt nochmal eine Filterung nach FormularId.
   * @private
   */
  private setFremdvertragAuswahlOptionenForEachVertrag() {
    console.log('AuftragBearbeitenService.setFremdvertragAuswahlOptionenForEachVertrag()')
    this.auftrag.vertraege = this.auftrag.vertraege
      .map(itVertrag => ({
        ...itVertrag,
        kiFremdvertragOptionen: this.allKiFremdvertragsAuswahlOptionen?.filter(kiFremdvertragAuswahlOption => !this.auftrag.vertraege
          .some(vertrag => vertrag.vertragId !== itVertrag.vertragId && vertrag.kiFremdvertragId === kiFremdvertragAuswahlOption.kiFremdvertragId)
        ).filter(filteredAuswahlOption => filteredAuswahlOption.formularId === itVertrag.formularId)
      }))
    this.vertraegeSubject.next(this.auftrag?.vertraege ?? [])
  }

  toggleVertrag(vertragId: string) {
    if (this.vertragInBearbeitungSubject.getValue() === vertragId) {
      this.vertragInBearbeitungSubject.next(null)
    } else {
      this.vertragInBearbeitungSubject.next(vertragId)
      const idx = this.auftrag.vertraege.findIndex(vertrag => vertrag.vertragId === vertragId)
      console.log('AuftragBearbeitenService.toggleVertrag: idx = %s ---- vertragId: ', idx, vertragId)
      this.scrollToVertrag(idx ?? 0)
    }
  }

  /**
   * scroll to the contract at given index, so that the user do not have to scroll to see
   * the most of the current formular
   * @param index
   * @private
   */
  scrollToVertrag(index: number) {
    const contentComponent = document.getElementById('content-component')
    const kundenkontextCardHeight = document.getElementById('kundenkontext').offsetHeight
    console.log('scrollToVertrag at idx: %s --- ', index, kundenkontextCardHeight)
    setTimeout(() => {
      const yWert = kundenkontextCardHeight + index * 90
      console.log('scrollToVertrag yWert: ', yWert)
      contentComponent.scroll({
        top: yWert,
        behavior: 'smooth'
      })
    }, 500)
  }

  getAnzahlVertraege(): number {
    return this.auftrag.vertraege.length
  }

  getAnzahlVertraegeMitFormularId(): number {
    return this.auftrag.vertraege.filter(vertrag => vertrag.formularId).length
  }

  isBuendelVertrag(): boolean {
    return this.getAnzahlVertraege() > 1
  }

  /**
   * Get the youngest date of all dates from containing contracts and return it
   * @return date
   */
  getYoungestBearbeitungsdatumFromVertraege(): string {
    return this.auftrag?.vertraege.map(vertrag => vertrag.bearbeitungsDatum).sort().reverse()[0]
  }

  sendAngaben(vertragComponets: VertragComponent[]): Observable<any> {
    const angabenObservables = vertragComponets.map(vertragComp => this.vertragHttpService.updateFormularAngaben(this.auftrag.auftragId, vertragComp.vertrag.vertragId, vertragComp.getAngabenToVertrag()))
    return forkJoin(angabenObservables)
  }

  changeValidityStateOfVertrag(vertragId: string, isValid: boolean) {
    this.auftrag.vertraege = this.auftrag.vertraege.map(vertrag => vertrag.vertragId === vertragId ? {
      ...vertrag,
      isValid: isValid
    } : vertrag)
    this.vertraegeSubject.next(this.auftrag.vertraege)
  }

  setFormularNachbarbeitungToVertrag(vertragId: string, isNachbearbeitbar) {
    this.auftrag.vertraege = this.auftrag.vertraege.map(vertrag => vertrag.vertragId === vertragId ? {
      ...vertrag,
      isNachbearbeitbar
    } : vertrag)
    this.vertraegeSubject.next(this.auftrag.vertraege)
  }

  addVertragToLadeAngaben(vertragId: string) {
    if (!vertragId) {
      return
    }

    if (!this.vertraegeBeimLadenVonDaten.some(id => id == vertragId)) {
      this.vertraegeBeimLadenVonDaten.push(vertragId)
      this.vertraegeInCommunicationWithBackendSubject.next(this.vertraegeBeimLadenVonDaten)
    }
  }

  removeVertragFromLadeAngaben(vertragId: string) {
    if (!vertragId) {
      return
    }

    if (this.vertraegeBeimLadenVonDaten.some(id => id == vertragId)) {
      this.vertraegeBeimLadenVonDaten = this.vertraegeBeimLadenVonDaten.filter(id => id !== vertragId)
      this.vertraegeInCommunicationWithBackendSubject.next(this.vertraegeBeimLadenVonDaten)
    }
  }

}
