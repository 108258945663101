import { Injectable } from '@angular/core'
import { EnvironmentInfoService } from '../../../projects/environment-info/src/lib/environment-info.service'

@Injectable({
  providedIn: 'root'
})
export class UserlaneInitService {

  private userlane = null
  private isInit = false

  constructor(private environmentInfoService: EnvironmentInfoService) {
  }

  public initUserlane(vbNummer: string, customAttributes?: {}) {
    // eslint-disable-next-line dot-notation, @typescript-eslint/dot-notation
    this.userlane = window['Userlane']

    if (this.userlane) {
      if (vbNummer) {
        customAttributes ? this.userlane('identify', vbNummer, customAttributes) : this.userlane('identify', vbNummer)
      }

      // initialize Userlane
      this.userlane('init', '8mkjj')
      this.isInit = true

      if (this.environmentInfoService.isDevelopmentEnv()) {
        this.userlane('hide')
      }
    } else {
      this.isInit = false
    }
  }

  public showUserlaneAssistent() {
    // open the Userlane Assistant
    if (this.userlane && this.isInit) {
      this.userlane('openAssistant')
    }
  }

  public isUserlaneInitialized(): boolean {
    return this.isInit
  }
}
