import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Formular } from '../models/formular.model'
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs'
import {
  EinkommenDTO,
} from '../../components/formular/basis-unterformular/basis-einkommen-unterformular/model/einkommen.model'


@Injectable({
  providedIn: 'root',
})
export class FormularHttpService {

  constructor(private httpClient: HttpClient) {
  }

  fetchFormulare() {
    return this.httpClient.get<Formular[]>(environment.be.baseUrl + '/formulare')
  }

  fetchDynSelectOptions(formularId: string, feldname: string, param?: {}): Observable<string[]> {
    return this.httpClient.get<string[]>(environment.be.baseUrl + '/formulare/' + formularId + '/optionen/' + feldname, { params: param })
  }

  fetchOptions(auftragId: string, formularId: string, feldname: string): Observable<Options> {
    return this.httpClient.get(environment.be.baseUrl + '/auftraege/' + auftragId + '/formulare/' + formularId + '/felder/' + feldname + '/options')
  }

  fetchGehalt(formularId: string, kundenNummer: string) {
    return this.httpClient.get<EinkommenDTO[]>(environment.be.baseUrl + '/formulare/' + formularId + '/optionen/person/' + kundenNummer + '/gehaelter')
  }

  fetchSonderzahlung(formularId: string, kundenNummer: string) {
    return this.httpClient.get<EinkommenDTO[]>(environment.be.baseUrl + '/formulare/' + formularId + '/optionen/person/' + kundenNummer + '/sonderzahlungen')

  }
}


export interface Options {
  linkLabel?: string
  linkText?: string
  linkHref?: string
}
