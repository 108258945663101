<!-- Wird angezeigt während die Haushalte abgefragt werden -->
<ng-container *ngIf='loadingHaushalte'>
  <dx-loading-indicator id="kundenzuordnung_loading_haushalte" size='small' label='Kunden werden geladen...'
                        labelalign='right'></dx-loading-indicator>
</ng-container>

<!-- Wird angezeigt, wenn man sich im Readonly- oder Nachbearbeitungs-Modus befindet oder es nur einen Kunden im Haushalt gibt -->
<ng-container
  *ngIf='!loadingHaushalte && anzuzeigendeHhMitglieder.length === 1 || auftragBearbeitungsStatus !== AuftragStatus.BEARBEITUNG'>
  <div class='kundeninfo'>
    <dx-text id='kundenkontext__kundenname'
             type='ps'>{{auftragBearbeitenService.auftrag?.kundenName}}</dx-text>
    <dx-text id='kundenkontext__kunden-geburtsdatum' type='it'
             *ngIf='auftragBearbeitenService.auftrag?.kundenGeburtsdatum'
             [ngStyle]="{'margin-bottom': auftragBearbeitungsStatus === auftragBearbeitungEnum.BEARBEITUNG ? '4px' : '0'}">
      Geboren am {{auftragBearbeitenService.auftrag?.kundenGeburtsdatum | date: 'dd.MM.yyyy'}}</dx-text>
  </div>
  <div *ngIf='mitgliederOhneEweVorhanden' class='info-ewe'>
    <dx-icon icon='information' size='24'></dx-icon>
    <dx-text type='ps' id='zugeordneter-kunde_hinweis-ohne-ewe'>Haushaltsmitglieder ohne EWE werden nicht
      angezeigt.
    </dx-text>
  </div>
</ng-container>

<!-- Wird angezeigt, Bearbeitungs-Modus mit mehreren Kunden im Haushalt -->
<ng-container *ngIf='!loadingHaushalte && anzuzeigendeHhMitglieder.length > 1'>
  <div class='kundenzuordnung-container'>
    <app-haushaltsmitglied-auswahl
      label='Zugeordneter Kunde'
      value='{{kundenauswahlKundennummer}}'
      [haushaltsmitglieder]='anzuzeigendeHhMitglieder'
      (valueChange)='updateKundenNummerOnAuftrag($event)'>
    </app-haushaltsmitglied-auswahl>
    <div *ngIf='mitgliederOhneEweVorhanden' class='info-ewe'>
      <dx-icon icon='information' size='24'></dx-icon>
      <dx-text type='ps' id='zugeordneter-kunde_hinweis-ohne-ewe'>Haushaltsmitglieder ohne EWE werden nicht
        angezeigt.
      </dx-text>
    </div>
  </div>
</ng-container>

<!-- Wird angezeigt, wenn der Auftrag einem anderen Kunden zugeordnet wurde und die Angaben neu geladen wurden -->
<div *ngIf='changingZugeordneterKunde'>
  <dx-loading-indicator-overlay id='kunden-update-spinner' type='alert' visible='true'>
    <div style="display: flex; flex-direction: column; gap: 16px" >
      <dx-text type="ps">Bitte warten ...</dx-text>
      <dx-loading-indicator label="Ihre getätigten Angaben werden übernommen." size="medium"></dx-loading-indicator>
    </div>
  </dx-loading-indicator-overlay>
</div>

<dx-alert
  #kundenZuordnungAlert
  [visible]="false"
  id="auftrag-liste-alert"
  icon="warndreieck"
  type="error"
  titlestring="Fehler bei der Kundenzuordnung"
  content="Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.">
  <dx-button id="ok-button" type="text" label="Okay" slot="action" (click)="kundenZuordnungAlert.visible = false"></dx-button>
</dx-alert>
