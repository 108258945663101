import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DateienResult } from '../models/dateien-result.model';
import { take } from 'rxjs/operators';
import { DateienPdfResult } from '../models/dateien-pdf-result';
import { Injectable } from '@angular/core';
import { GoyaMeta } from '../models/goya-meta'


@Injectable()
export class DateienHttpService {
  constructor(private httpClient: HttpClient) { }

  addFile(kundenNr: string, payload: FormData) {
    console.log('DateienHttpService addFile() payload', payload);
    return this.httpClient.post<DateienResult>(`${environment.be.baseUrl}/personen/${kundenNr}/files`, payload).pipe(take(1));
  }

  getGoyaMetaInfo(kundenNr: string, goyaId: string) {
    console.log('DateienHttpService getGoyaMetaInfo() kundennummer', kundenNr);
    return this.httpClient.get<GoyaMeta>(`${environment.be.baseUrl}/personen/${kundenNr}/files/${goyaId}/meta`).pipe(take(1))
  }

  downloadFile(kundenNr: string, goyaId: string) {
    console.log('DateienHttpService downloadFile() kundennummer', kundenNr);
    return this.httpClient.get(`${environment.be.baseUrl}/personen/${kundenNr}/files/${goyaId}/binary`, {
      responseType: 'blob'
    }).pipe(take(1))
  }

  generatePdfIDForCustomer(kdnummer: string) {
    console.log('DateienHttpService generatePdfID() kundennummer', kdnummer);
    return this.httpClient.post<DateienPdfResult>(environment.be.baseUrl + '/pdf', {kundenNummer: kdnummer}).pipe(take(1));
  }
}
