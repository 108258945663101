import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core'
import { Authenticator } from '../model/authenticator'
import { Subscription } from 'rxjs'
import { ErrorEvent, JobError, UploaderEvent, UploadJob } from '../model/events'
import { Stage } from '../stage/stage'
import { DxAlert } from '@dvag/design-system-angular'
import { AuftragLightHttpService } from '../services/auftrag-light-http.service'
import { UploadService } from '../upload.service'
import { AuthenticatorInterceptor } from '../http/authenticator.interceptor'


@Component({
  selector: 'lib-uploader',
  template: `
    <dx-alert #uploaderror
              id='upload-error-alert'
              icon='warndreieck'
              type='error'
              titlestring='Erneut hochladen?'
              content='Leider ist ein unerwarteter Fehler aufgetreten.'>
      <dx-button
        id='button-retry'
        type='primary-s'
        slot='action'
        label='Erneut versuchen'
        (click)='retryUpload()'>
      </dx-button>
      <dx-button
        id='button-abbrechen'
        type='secondary-s'
        slot='action'
        label='Abbrechen'
        (click)='abortUpload()'>
      </dx-button>
    </dx-alert>
  `
})
export class UploaderComponent implements OnDestroy, OnChanges {

  @ViewChild('uploaderror') dsErrorDialog: DxAlert

  // TODO: Mit hilfe von UUID authenticator auswählen.
  @Input()
  set authenticator(value: Authenticator) {
    this.authenticatorInterceptor.authenticator = value
  }

  @Input()
  stage: 'entwicklung' | 'integration' | 'abnahme' | 'produktion' = null

  @Output()
  upload = new EventEmitter<UploaderEvent>()

  backendUrl: string
  currentErrorEvent: ErrorEvent


  private subscriptions$: Subscription[] = []

  constructor(private uploadService: UploadService, private uploadClientService: AuftragLightHttpService, private changeDetectionRef: ChangeDetectorRef,
              private authenticatorInterceptor: AuthenticatorInterceptor, private stageService: Stage) {
    console.log('WebComponent: Uploader loaded')

    this.subscriptions$ = [
      this.uploadService.uploadEvents.subscribe(event => {
        this.upload.emit(event)
      }),

      this.uploadService.errorEvents.subscribe((event: ErrorEvent) => {
        this.currentErrorEvent = event
        this.dsErrorDialog.visible= true
      })
    ]
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.stage) {
      this.stageService.updateStage(this.stage)
    }
  }

  ngOnDestroy(): void {
    console.log('WebComponent: Uploader closed')
    this.subscriptions$
      .filter(sub => !!sub)
      .forEach(sub => sub.unsubscribe())
  }

  retryUpload() {
    const errorEvent = {...this.currentErrorEvent}
    this.currentErrorEvent = null
    this.dsErrorDialog.visible = false
    this.uploadService.retryUpload(errorEvent.job)
  }

  abortUpload() {
    this.dsErrorDialog.visible = false
    this.uploadService.abortUpload(this.currentErrorEvent.job, {message: this.currentErrorEvent.message, error: this.currentErrorEvent.error})
    this.currentErrorEvent = null
  }
}
