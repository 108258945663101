export interface Payload {
  getFormData(name: string): Promise<FormData>
  getSizeMB(): Promise<number>
  getSize(): Promise<number>
}

export class Base64Payload implements Payload {
  size: number
  private blob: Blob
  isInit = false
  constructor(private bildBase64: string) {
  }

  private async init() {
    if(this.blob)
      return

    const response = await fetch(this.bildBase64)
    this.blob = await response.blob()
    this.size = this.blob.size
    this.isInit = true
  }

  async getFormData(name: string): Promise<FormData> {
    await this.init()
    const formData = new FormData()
    formData.append(name, this.blob, 'Upload.jpg')
    return formData
  }

  async getSize(): Promise<number> {
    if( !this.isInit ) await this.init()
    return this.size
  }

  async getSizeMB(): Promise<number> {
    if( !this.isInit ) await this.init()
    return Number(((this.size / 1024) / 1024).toFixed(3))
  }
}

export class FilePayload implements Payload {
  private size: number
  constructor(private file: File) {
    this.size = file.size
  }

  async getFormData(name): Promise<FormData> {
    const formData = new FormData()
    formData.append(name, this.file)
    return formData
  }
  async getSize(): Promise<number> {
    return this.size;
  }

  async getSizeMB(): Promise<number> {
    return Number(((this.size / 1024) / 1024).toFixed(3))
  }
}
