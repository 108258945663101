<div id="cropper" [style.display]="cropperStyle" [class.isDateiauswahl]="!shouldHideFooter"
     style="background-color: white;">
  <image-cropper [attr.license]="lizenz" #imageCropper
                 style="--active-stroke:5;--active-color:orange;--inactive-color:orange; z-index:999; background-color: white;"></image-cropper>
</div>

<div class="fullscreen" [style.display]="isloading ? 'flex' : 'none'">
  <div class="card">
    <dx-loading-indicator size="medium" theme="light" label="Bild wird geladen ...">
    </dx-loading-indicator>
  </div>
</div>

<dx-alert #dxAlert
          id="dx-alert-cropper"
          icon="{{alertIcon}}"
          type="{{alertType}}"
          titlestring="{{alertTitle}}"
          content="{{alertBody}}">
  <dx-button
    id="button-ok"
    type="primary-s"
    slot="action"
    label="Okay"
    (click)="dxAlert.visible = false">
  </dx-button>
</dx-alert>
