import { BasisUnterformular } from '../basis-unterformular'
import { Einkommen, EinkommenDTO } from './model/einkommen.model'
import { BehaviorSubject } from 'rxjs'

export class BasisEinkommenUnterformular extends BasisUnterformular {
  valueCompleteTriggered = new BehaviorSubject<boolean>(false)
  kundenNummer: string

  maximum = 9999999.99
  minimum = 0
  minimumAnzahlGehaelter = 0.1

  override setInitialValue(value: EinkommenDTO): void {
    super.setInitialValue(value)
    this.updateVersion(value.bestandsId)
  }

  override patchValue(newValue: any) {
    if (!newValue) {
      return
    }

    this.updateVersion(newValue.bestandsId)
    this.formControl.patchValue(newValue)
    if( !!newValue['netto']){
      this.enableFormControls(newValue['netto'])
    }
  }

  override readValue(): any {
    if(!this.formControl.get('netto')?.value){
      return this.initialValue
    }
    return this.formControl.getRawValue() ?? {}
  }

  enableFormControls(nettoValue: string): void {
    // wird in den jeweiligen Spezialisierungen überschrieben, wegen unterschiedlicher FormControls
  }

  updateVersion(selectedBestandsId: string): void {
  }

  getErrorMessage(fieldName: string): string {
    const control = this.formControl.get(fieldName)
    if (!control) {
      return null
    }

    if (fieldName === 'brutto' && this.formControl.hasError('nettoLessThanOrEqualToBrutto') && this.valueCompleteTriggered.value) {
      return `Der Bruttobetrag kann nicht kleiner als der Nettobetrag sein. Bitte korrigieren Sie Ihre Eingaben.`
    }

    if (control.hasError('maximum')) {
      return `Bitte wählen Sie einen Betrag unter 10 Mio €.`
    }

    if (control.hasError('minimum')) {
      return `Bitte wählen Sie einen Betrag über 0,00 €.`
    }

    return null
  }

  onValueComplete(): void {
    this.valueCompleteTriggered.next(true)
  }

  resetValueComplete(): void {
    this.valueCompleteTriggered.next(false)
  }

  /**
   * Gleicht bei existierendem initValue (netto und vorhandene BestandsId) mit den Einkommen im HaushaltsBudget ab.
   * Sollte kein Einkommen im Haushaltsbudget mit der BestandsId aus dem initialValue vorhanden sein,
   * dann wird die BestandsId auf einen Leerstring gesetzt, damit ein neues Einkommen angelegt wird.
   *
   * @param initValue
   * @param existingEinkommen
   * @returns initValue oder ein angepasstes initValue
   */
  protected checkInitialValueForExistingEinkommen(initValue: EinkommenDTO, existingEinkommen: Einkommen[]): EinkommenDTO {
    if(!initValue?.netto || !initValue?.bestandsId) {
      return initValue
    }

    const containsEinkommen = existingEinkommen.some(einkommen => einkommen.bestandsId === initValue.bestandsId)
    if( containsEinkommen ) {
      return initValue
    }

    return {...initValue, bestandsId: ''}
  }
}
