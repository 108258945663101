import { Component} from '@angular/core'
import { BasisFormularFeld } from '../basis-formular-feld'

@Component({
  selector: 'app-unknown',
  templateUrl: './unknown.component.html',
  styleUrls: ['./unknown.component.scss']
})

export class UnknownComponent extends BasisFormularFeld {}
