import { Injectable } from '@angular/core'
import { Stage } from '../../../projects/upload-components/src/lib/stage/stage'
import { ApplicationInsightsService } from './application-insights.service'

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(private stage: Stage, private appInsightsService: ApplicationInsightsService) {
  }

  async init(): Promise<any> {
    this.appInsightsService.init()
    this.chooseManifestForStage()
    return true
  }

  private chooseManifestForStage() {
    const detectedStage = this.stage.getStage()
    let useManifestForStage
    switch (detectedStage) {
      case 'entwicklung':
        useManifestForStage = '/manifest.entwicklung.webmanifest'
        break
      case 'integration':
        useManifestForStage = '/manifest.integration.webmanifest'
        break
      case 'abnahme':
        useManifestForStage = '/manifest.abnahme.webmanifest'
        break
      case 'produktion':
        useManifestForStage = '/manifest.webmanifest'
        break
      case 'localhost':
        useManifestForStage = '/manifest.local.webmanifest'
        break
      default:
        useManifestForStage = '/manifest.entwicklung.webmanifest'
    }

    document.querySelector('#datenturbo-manifest').setAttribute('href', useManifestForStage)
  }
}
