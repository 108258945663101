import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import * as moment from 'moment'
import { EnvironmentInfoService } from '../../../../environment-info/src/lib/environment-info.service'
import { BucketAccessInfo } from '../model/buckets.model'
import { DxAlert } from '@dvag/design-system-angular'
import { ConfigOptions } from '../model/config-options.model'
import { ModalButtonConfig } from '../model/modal-button-config.model'

@Component({
  selector: 'lib-switch-device',
  templateUrl: './qr-code-upload.component.html',
  styleUrls: ['./qr-code-upload.component.scss']
})
export class QrCodeUploadComponent implements OnInit {
  @ViewChild('geraeteWechselAbbrechen') geraeteWechselAbbrechenAlert: DxAlert
  @ViewChild('geraeteWechselZurueckAlert') geraeteWechselZurueckAlert: DxAlert

  @Input() set configOptions(config: ConfigOptions) {
    if (config) {
      this.documentUploadEndpoint = config.documentUploadEndpoint || this.documentUploadEndpoint
    }
  }

  @Input()
  set qrCodeInfo(value: BucketAccessInfo) {
    this.ablaufLabel = ''
    this.changeDetectorRef.detectChanges()
    if(!value) {
      this.ablaufLabel = 'Upload aktuell nicht Verfügbar'
      this.isQrCodeAbgelaufen = true
      this.changeDetectorRef.detectChanges()
      return
    }
    if (this.isAblaufDatumInFurture(value.accessKeyAblauf)) {
      this.isQrCodeAbgelaufen = false
      this.ablaufLabel = 'Dieser QR-Code ist bis ' + moment(value.accessKeyAblauf).format('DD.MM.YYYY, HH:mm') + ' Uhr gültig.'
      this.startTimer(value.accessKeyAblauf)
    } else {
      this.ablaufLabel = 'abgelaufen'
      this.isQrCodeAbgelaufen = true
    }
    this.qrCode = value.qrCodeBase64
    this.changeDetectorRef.detectChanges()
  }

  @Input() set modalClosedEvent(value: any) {
    if (value && this.geraeteWechselAbbrechenAlert) {
      if (this.documentUploadEndpoint) {
        this.geraeteWechselAbbrechenAlert.visible = true
      } else {
        this.closeModal.emit()
      }
    }
  }

  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>()
  @Output() goBackEvent: EventEmitter<void> = new EventEmitter<void>()
  @Output() changeDeviceEvent: EventEmitter<any> = new EventEmitter<any>()
  @Output()
  useButtons: EventEmitter<ModalButtonConfig[]> = new EventEmitter<ModalButtonConfig[]>()
  @Output()
  useHeadline: EventEmitter<string> = new EventEmitter<string>()

  documentUploadEndpoint: string
  qrCode: string
  isQrCodeAbgelaufen = true

  ablaufLabel = ''

  private geraetewechselButtons: ModalButtonConfig[] = [
    {
      label: 'Fertig',
      icon: 'upload',
      slot: 'primary-actions',
      id: 'qr-code-gescannt',
      type: 'primary-s',
      clickFn: () => {
        this.changeDeviceEvent.emit()
      },
    },
    {
      label: 'Zurück',
      slot: 'secondary-actions',
      id: 'back-button',
      type: 'text',
      icon: 'pfeil-links',
      clickFn: () => {
        if (this.documentUploadEndpoint) {
          this.geraeteWechselZurueckAlert.visible = true
        } else {
          this.goBackEvent.emit()
        }
      },
    }
  ]

  constructor(private changeDetectorRef: ChangeDetectorRef, private environmentInfoService: EnvironmentInfoService) {
  }

  ngOnInit(): void {
    this.useHeadline.emit('QR-Code scannen')
    this.useButtons.emit(this.geraetewechselButtons)
  }

  private startTimer(gueltigkeitBis: string) {
    let duration = this.berechneInitialeLaufzeit(gueltigkeitBis).asSeconds()

    const qrTimer = setInterval(() => {
      duration = this.environmentInfoService.isDevelopmentEnv() ? duration - 2000 : duration - 1;
      if (duration <= 0) {
        this.ablaufLabel = 'Abgelaufen'
        this.isQrCodeAbgelaufen = true
        this.changeDetectorRef.detectChanges()
        clearInterval(qrTimer)
      }
      this.changeDetectorRef.detectChanges()
    }, 1000)
  }

  private berechneInitialeLaufzeit(gueltigkeitBis: string): moment.Duration {
    const gueltigkeit = moment(gueltigkeitBis).diff(moment.now())
    return moment.duration(Math.max(gueltigkeit, 0))
  }

  private isAblaufDatumInFurture(ablauf: any): boolean {
    const ablaufDate = moment(ablauf).diff(moment.now())
    return ablaufDate > 0
  }

  cancelUpload() {
    this.geraeteWechselAbbrechenAlert.visible = false
    this.closeModal.emit()
  }

  goBack() {
    this.goBackEvent.emit()
  }

  changeDevice() {
    this.changeDeviceEvent.emit()
  }

}
