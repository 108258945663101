<div *ngIf="!isReadonly" [formGroup]="mainFormularGroup">
  <dx-text-input #focusElement
                 [formControlName]="field.name"
                 id="{{field.name}}_wert"
                 label="{{field.label}}"
                 (focus)="storeNameOfFocusedElement(field.name)"
                 (autocomplete)="filterOptions($event)"
                 (valueComplete)="handleInput($event.detail)"
                 (selectAutocomplete)="handleInput($event.detail)"
                 (valueChange)="handleInput($event.detail)"
                 [kisynced]="field.kiSync">
  </dx-text-input>
</div>
<app-readonly-template *ngIf="isReadonly" [field]="field" [displayValue]="getReadonlyDisplayValue()"></app-readonly-template>
