import { Component, ViewChild } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { BasisInputFormularFeld } from '../basis-input-formular-feld'
import { AktivesEingabefeldHandlerService } from '../services/aktives-eingabefeld-handler.service'
import { Components } from '@dvag/design-system'
import DxTextArea = Components.DxTextArea

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html'
})
export class TextareaComponent extends BasisInputFormularFeld {

  @ViewChild('focusElement') focusElement: DxTextArea

  constructor(public override fb: FormBuilder, public override focusedControlHandlerService: AktivesEingabefeldHandlerService) {
    super(fb, focusedControlHandlerService)
  }

  override async focus(): Promise<void> {
    if( this.focusElement?.focusControl ) {
      try {
        await this.focusElement?.focusControl()
      } catch {
        console.log(`Es ist eine Fehler beim Setzen des Fokus beim Feld '${this.field.name}' augetreten`)
      }
    } else {
      console.warn(`An dem FormularFeld '${this.field.name}' vom Typ '${typeof this.focusElement}' existiert keine Methode 'focusControl()'!`)
    }
  }

}
