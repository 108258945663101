import { Component, EventEmitter, Input, Output } from '@angular/core'
import { DateienHttpService } from '../../store/services/dateien.http.service'
import { forkJoin } from 'rxjs'
import { map } from 'rxjs/operators'
import * as fileSaver from 'file-saver'

@Component({
  selector: 'app-download-auftrag',
  templateUrl: './download-auftrag.component.html',
  styleUrls: ['./download-auftrag.component.scss']
})

export class DownloadAuftragComponent {
  @Input() auftragsId: string
  @Input() kundenNummer: string
  @Input() goyaId: string
  @Input() iconOnly = false

  @Output() handleDownloadError: EventEmitter<any> = new EventEmitter()
  @Output() downloadButtonClicked: EventEmitter<any> = new EventEmitter()

  isDownloading = false


  constructor(private dateienHttpService: DateienHttpService) {
  }

  downloadDatei(event: any) {
    event.stopPropagation()

    if (this.kundenNummer && this.goyaId) {
      this.isDownloading = true
      const $file = this.dateienHttpService.downloadFile(this.kundenNummer, this.goyaId)
      const $metaInfo = this.dateienHttpService.getGoyaMetaInfo(this.kundenNummer, this.goyaId)

      forkJoin([$file, $metaInfo]).pipe(
        map(([blob, metaInfo]) => {
            fileSaver.saveAs(blob, metaInfo.name)
          }
        )
      ).subscribe({
        next: () => {
          this.isDownloading = false
        },
        error: err => {
          console.log('Es ist ein Fehler beim Herunterladen aufgetreten', err)
          this.isDownloading = false
          this.handleDownloadError.emit()
        }
      })
    } else {
      console.log('Für den Download der Datei fehlen Informationen! kundenNummer: %s --- goyaId: %s', this.kundenNummer, this.goyaId)
      this.handleDownloadError.emit()
    }
    this.downloadButtonClicked.emit()

  }
}
