import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ViernulldreiInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private ngZone: NgZone
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
          if (error.status === 403) {
            this.navigateToAccessDenied(error.error.missingPermissions || []);
            return of(null);
          }

          return throwError(error);
        }
      ));
  }

  private navigateToAccessDenied(missingPermissions) {
    // NgOnInit wurde auf der accessDenied Seite nicht
    // aufgerufen was an diesem Bug liegen könnte:
    // https://github.com/angular/angular/issues/18254#issuecomment-318329250
    this.ngZone.run(() => {
      this.router.navigate(['/accessdenied'], {
        state: {
          missingPermissions
        }
      });
    });
  }
}
