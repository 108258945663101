<dt-leistungsradar-quickcheck [vergleich]="result"></dt-leistungsradar-quickcheck>


<!--Nicht gelöstes Problem: URL kann nicht im iframe/Modal angezeigt werden. Untenstehender Code wird zu Testzwecken dringelassen, um mögliche Lösungen schnell testen zu können-->
<!--<ds-button (click)="showInfoModal()" id="b1" type="primary" size="small">
  <ds-text>Modal öffnen</ds-text>
</ds-button>

<ds-modal #infoModal id="m5" size="content" label="Titel">
  <div slot="content" style="min-width: 300px">
   <iframe sandbox="allow-same-origin allow-scripts allow-forms allow-top-navigation" src="https://www.generali.de/leistungsradarapp/comparison.html"></iframe>
  </div>
</ds-modal>-->
