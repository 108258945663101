import { NgModule } from '@angular/core';
import { DateiauswahlComponent } from './dateiauswahl.component';
import { FileListItemComponent } from './file-list-item/file-list-item.component'
import { CommonModule } from '@angular/common'
import { EnvironmentInfoModule } from '../../../environment-info/src/lib/environment-info.module'
import { DragDropDirective } from './drag-drop.directive'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { DesignSystemModule } from '@dvag/design-system-angular';
import { ImageCropperModule} from '../../../image-cropper/src/lib/image-cropper.module'



@NgModule({
  declarations: [DateiauswahlComponent, FileListItemComponent, DragDropDirective],
  imports: [DesignSystemModule, CommonModule, EnvironmentInfoModule, DragDropModule, ImageCropperModule],
  exports: [DateiauswahlComponent]
})
export class DateiauswahlModule { }
