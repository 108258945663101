import { Injectable } from '@angular/core'
import { FormularHttpService } from '../../../store/services/formular.http.service'
import { distinctUntilChanged, filter, groupBy, map, mergeMap, reduce, shareReplay, toArray } from 'rxjs/operators'
import { Formular } from '../../../store/models/formular.model'
import { from, Observable, ReplaySubject } from 'rxjs'

export interface FormulareNachKategorie {
  kategorie: string;
  formulare: Formular[];
}

@Injectable({
  providedIn: 'root'
})
export class FormularListService {

  private formularListe: ReplaySubject<Formular[]> = new ReplaySubject<Formular[]>(1)
  formularListe$ = this.formularListe.asObservable().pipe(
    shareReplay(1)
  )

  private categorizedFormularListe: ReplaySubject<FormulareNachKategorie[]> = new ReplaySubject<FormulareNachKategorie[]>(1)
  kategorisierteFormularListe$ = this.categorizedFormularListe.asObservable()

  constructor(private formularHttpService: FormularHttpService) {}

  public initialLoadFormulare() {
    this.fetchFormularListe()
  }

  private fetchFormularListe(): void {
    this.formularHttpService.fetchFormulare()
      .pipe(
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
        filter(liste => liste != null)
      )
      .subscribe({
        next: (fetchedFormularListe) => {
          console.log('Received new formular list: ', fetchedFormularListe)
          this.formularListe.next(fetchedFormularListe)
          this.createCategorizedFormularList(fetchedFormularListe)
        },
        error: err => console.error('Could not read Formulare from Backend!', err)
      })
  }

  /**
   * Erzeugt eine kategorisierte Formularliste in dem Format [{kategorie1, [formular11 ,formular12]},{kategorie2, [formular21 ,formular22]}]
   */
  private createCategorizedFormularList(formularList: Formular[]): void {
    from(formularList)
      .pipe(
        groupBy(formular => formular.kategorie),
        mergeMap(categorizedFormular$ =>
          categorizedFormular$.pipe(
            reduce<Formular, FormulareNachKategorie>((acc, cur) => ({
                kategorie: acc.kategorie,
                formulare: [...acc.formulare, cur]
              }),
              { kategorie: categorizedFormular$.key, formulare: [] })
          )
        ),
        toArray()
      ).subscribe(categorizedList => this.categorizedFormularListe.next(categorizedList))
  }

  /**
   * Returns an observable of a filtered or unfiltered list of categorized formulars
   * @param filter optional string wich will filter the categorized list of formulars
   */
  getCategorizedFormularList(filter?: string): Observable<FormulareNachKategorie[]> {
    if (filter) {
      return this.categorizedFormularListe.pipe(
        map(formularGroups =>
          formularGroups.map(formularGroup => ({
            ...formularGroup,
            formulare: this.filteredFormularListByValue(formularGroup.formulare, filter)
          }))
            .filter(group => group.formulare.length > 0)
        )
      )
    }
    return this.categorizedFormularListe
  }

  private filteredFormularListByValue = (formulare: Formular[], value: string): Formular[] => {
    const filterValue = value.toLowerCase()
    return formulare.filter(
      formular =>
        formular.name.toLowerCase().includes(filterValue) || formular.kategorie.toLowerCase().includes(filterValue)
    )
  }
}
