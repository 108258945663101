import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ExtendedUploadFile } from '../dateiauswahl.component'

@Component({
  selector: 'lib-file-list-item',
  templateUrl: './file-list-item.component.html',
  styleUrls: ['./file-list-item.component.scss']
})
export class FileListItemComponent {

  public isFocused = false;
  public hideDocTypeSelect = false;
  public hideDragAndDropIcon = true;
  public fileSizeString: string;
  public file: ExtendedUploadFile;

  @Input()
  set extendedFile(file) {
    this.file = file
    this.getFileSize(this.file.fileBlob.size)
}

  @Input()
  set focused(value: boolean) {
    this.isFocused = value;
  }

  @Input()
  set showDragAndDropIcon(value: boolean) {
    this.hideDragAndDropIcon = !value;
  }

  @Input()
  isPDF: boolean

  @Output()
  deleteFile = new EventEmitter<boolean>();

  @Output()
  openCropperClicked = new EventEmitter<boolean>()

  triggerFileDelete() {
    this.deleteFile.emit(true);
  }

  onOpenCropper() {
    this.openCropperClicked.emit(true)
  }

  getFileSize(filesize): void {
    if (filesize < 1048576) {
      this.fileSizeString =  '\u00A0\u2013\u00A0' + (filesize / 1024).toFixed(0) + '\u00A0KB'
    } else {
      this.fileSizeString =  '\u00A0\u2013\u00A0' + (filesize / (1024*1024)).toFixed(1).replace('.', ',') + '\u00A0MB'
    }
  }

}
