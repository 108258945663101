import { Component, OnDestroy } from '@angular/core'
import { Mandant, ZobService, ZobVertretung } from '../../store/services/zob.service'
import { ApplicationInsightsService, DT_FE_CUSTOM_EVENT } from '../../services/application-insights.service'
import { DT_VERSION } from '../../../version'
import { Observable, Subscription } from 'rxjs'
import { Breadcrumb, BreadcrumbService } from '../../services/breadcrumb.service'
import { NavigationEnd, Router } from '@angular/router'
import { EnvironmentInfoService } from '../../../../projects/environment-info/src/lib/environment-info.service'
import { VbAvatarService } from '../../services/vb-avatar.service'
import { filter, tap } from 'rxjs/operators'
import { ContextUser, KeycloakApiService } from '../../store/services/keycloak.api.service'
import { CookieZustimmungService } from '../cookies/cookie-zustimmung/cookie-zustimmung.service'
import { CookiesStorageService } from '../cookies/cookies-storage.service'

@Component({
  selector: 'app-datenturbo',
  templateUrl: './datenturbo.component.html',
  styleUrls: ['./datenturbo.component.scss'],
  providers: [CookieZustimmungService, CookiesStorageService],
})
export class DatenturboComponent implements OnDestroy {

  public version = ''

  datenschutzUrl: string
  impressumUrl: string

  showTestLink = this.environmentInfoService.isDevelopmentEnv()
  isDevelopmentEnv = window.location.hostname.includes('entwicklung') || window.location.hostname.includes('localhost')

  avatarURL: URL
  benutzerName: string = 'N N'
  contextUser: ContextUser
  isAssistent = false
  hasVertreter = false
  vertretungen: ZobVertretung[] = []

  breadcrumb$: Observable<Breadcrumb>

  private routerEventSub: Subscription
  private showFooterOnRoutes = [
    {
      route: '/dashboard',
      bgColorType: 'background',
    },
    {
      route: '/accessdenied',
      bgColorType: 'background',
    },
    {
      route: '/update',
      bgColorType: 'background',
    },
  ]

  public showFooter: boolean = false
  public footerBgColorToUse = 'white'

  constructor(
    private router: Router,
    private zobHttpService: ZobService,
    private environmentInfoService: EnvironmentInfoService,
    private applicationInsightsService: ApplicationInsightsService,
    private avatarService: VbAvatarService,
    private breadcrumbService: BreadcrumbService,
    private keycloakApiService: KeycloakApiService) {

    this.benutzerName = keycloakApiService.getFullname()
    this.contextUser = this.keycloakApiService.getContextUser()

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
      const match = this.showFooterOnRoutes.find(it => event.urlAfterRedirects.startsWith(it.route))

      if (match) {
        this.footerBgColorToUse = match.bgColorType
        this.showFooter = true
      } else {
        this.showFooter = false
      }
    })

    // --- Update Mandanten fuer den Link
    zobHttpService.mandant().subscribe(mandant => {
      this.updateMandantenContext(mandant)
    })
    this.updateMandantenContext(Mandant.DVAG)

    this.breadcrumb$ = breadcrumbService.breadcrumb$

    zobHttpService.fetchVertretungen().pipe(
      tap(vertretungen => {
        if (vertretungen.length > 0) {
          this.vertretungen = vertretungen
          this.hasVertreter = true
        } else {
          this.hasVertreter = false
        }
      }),
    ).subscribe()

    this.avatarService.getAvatarURL().subscribe(url => {
      this.avatarURL = url ?? null
    })

    zobHttpService.getBenutzerTyp().subscribe(benutzertyp => {
      if (benutzertyp === 'ASSISTENT') {
        this.isAssistent = true;
      } else {
        this.isAssistent = false
      }
    })
  }

  public openLink(link: string) {
    window.open(link, '_blank', 'noopener')
    this.applicationInsightsService.logEvent(DT_FE_CUSTOM_EVENT.DASHBOARD_LINK_GEOEFFNET, {url: link})
  }

  toggleVersion() {
    this.version = this.version == '' ? DT_VERSION : ''
  }

  protected updateMandantenContext(mandant: Mandant) {
    switch (mandant) {
      case Mandant.ALLFINANZ:
        this.impressumUrl = 'https://www.allfinanz-dvag.de/allfinanz/impressum.html'
        this.datenschutzUrl = 'https://www.allfinanz-dvag.de/allfinanz/datenschutz.html'
        break

      case Mandant.ALLFINANZ_AG:
        this.impressumUrl = 'https://www.allfinanz.ag/ag/impressum.html'
        this.datenschutzUrl = 'https://www.allfinanz.ag/ag/datenschutz.html'
        break

      case Mandant.DVAG:
      case Mandant.DVAG_AT:
      default:
        this.impressumUrl = 'https://www.dvag.de/dvag/impressum.html'
        this.datenschutzUrl = 'https://www.dvag.de/dvag/datenschutz.html'
    }
  }

  public onLogout() {
    this.keycloakApiService.logout()
  }

  async navigateHome() {
    await this.router.navigate(['/dashboard'])
  }

  navigateToUmfrage() {
    this.openLink('https://de.research.net/r/Datenturbo')
    this.applicationInsightsService.logUmfrageGestartet()
  }

  ngOnDestroy(): void {
    this.routerEventSub?.unsubscribe()
  }

  triggerKundenwechsel() {
    //this.applicationInsightsService.logAppGestartetAlsBenutzertyp(this.isAssistent ? Benutzer.Assistent : Benutzer.Vertreter)
    this.keycloakApiService.triggerKundenwechsel()
  }
}
