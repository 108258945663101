<!-- autoplay geht wohl nicht in mobilen browsern -->
<video #video playsinline autoplay muted (play)="playing()"></video>
<div class="footer">
  <div class="actions" *ngIf="isPlaying">
    <button id="capture-button" class="primary-svg-icon-button" (click)="capture()" [disabled]="isCapturing">
      <dx-icon icon="foto-kamera" size="32" color="white"></dx-icon>
    </button>
    <div id="open-preview-button" class="btn-open-preview"
         [ngStyle]="{'display': !images || images?.length === 0 ? 'none' : 'block'}"
         (click)="showPreview()">
      <img *ngIf="images && images?.length > 0" [src]="images[0]" alt="Vorschau aufgenommener Bilder">
      <div id="open-preview-badge" class="preview-badge">
        <dx-text type="its" color="white">{{images.length}}</dx-text>
      </div>
    </div>
  </div>
</div>
<canvas #canvas></canvas>
<pre *ngIf="environmentInfoService.isDevelopmentEnv()"
     style="position:absolute; top: 0; left:0;background: black; color: white">{{debugInfo}}</pre>
