import { Injectable } from '@angular/core';
import { AuftragKategorie } from '../../../enums/auftrag.enums'

interface AuftragsFilter {
  searchterm: string,
  kategorie: AuftragKategorie,
  sorting: 'up' | 'down'
}

@Injectable({
  providedIn: 'root'
})
export class AuftragsFilterService {

  private auftragsFilter: AuftragsFilter = {
    searchterm: '',
    kategorie: AuftragKategorie.OFFEN,
    sorting: 'up'
  }

  public getFilter(): AuftragsFilter {
    return {...this.auftragsFilter}
  }

  public updateFilter(searchterm: string, kategorie: AuftragKategorie, sorting: 'up' | 'down') {
    this.auftragsFilter = {searchterm, kategorie, sorting}
  }

}
