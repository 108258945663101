import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuftraglistComponent } from './components/auftraglist/auftraglist.component'
import { DokumentSplitscreenComponent } from './components/dokument-splitscreen/dokument-splitscreen.component'
import { AccessdeniedComponent } from './components/accessdenied/accessdenied.component'
import { UpdatePwaComponent } from './components/update-pwa/update-pwa.component'
import { DatenturboComponent } from './components/datenturbo/datenturbo.component'
import { canActivateKeycloakGuard } from './guards/can-activate-keycloak.guard'
import { canDeaktivateSplittscreenGuard } from './guards/can-deaktivate-splittscreen.guard'
import { canActivatePwaUpdateGuard } from './guards/can-activate-pwa-update.guard'

/**
 * ********** HINWEIS **************
 * Soll eine Route fuer Updates an der PWA aktiviert werden, dann muss an der Route der PwaUpdateGuard fuer canActivate
 * gesetzt werden:
 *   canActivate: [PwaUpdateGuard]
 * Wird der Guard an einer Route nicht gesetzt, so wird auf dieser Route mittels dem AppInitializer nach
 * einem Update gesucht, aber es wird nicht auf die Update-Seite navigiert und auch kein PageReload
 * zum Aktivieren der Seite durchgefuehrt.
 */

const routes: Routes = [
  // Geraetewechsel - Seite wird ueber das Scannen des QR-Codes aufgerufen
  {
    path: 'qrfotoupload',
    loadChildren: () => import('./components/geraete-wechsel-upload/geraete-wechsel-upload.module').then(m => m.GeraeteWechselUploadModule)
  },
  {
    path: '',
    component: DatenturboComponent,
    children: [
      {
        path: '', pathMatch: 'full', redirectTo: '/dashboard',
      },
      // dashboard
      {
        path: 'dashboard',
        component: AuftraglistComponent,
        canActivate: [canActivateKeycloakGuard, canActivatePwaUpdateGuard],
        children: [
          // Früher hatten wir Suchparameter inder URL. z.B. /offen/up/... . Diese Anfragen sollen
          // ebenfalls ans Dashboard geleitet werden.
          {path: '**', component: AuftraglistComponent}
        ]
      },
      // splitscreen
      {
        path: 'dokumentsplitscreen/:auftragsId',
        component: DokumentSplitscreenComponent,
        canActivate: [canActivateKeycloakGuard, canActivatePwaUpdateGuard],
        canDeactivate: [canDeaktivateSplittscreenGuard]
      },
      {
        path: 'dokumentsplitscreen',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      // access denied
      {
        path: 'accessdenied',
        component: AccessdeniedComponent,
        canActivate: [canActivateKeycloakGuard]
      },
      // updating the pwa
      {
        path: 'update',
        component: UpdatePwaComponent
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
