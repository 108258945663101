<dx-alert #cameraerror
          id="camera-error-alert"
          icon="warndreieck"
          type="error"
          titlestring="Fotoaufnahme nicht möglich"
          content="Bitte probieren Sie es zu einem späteren Zeitpunkt erneut oder nutzen Sie alternativ die Funktion „Mit anderem Gerät fotografieren“ und nehmen Sie die Dokumente mit Ihrem Smartphone auf.">
  <dx-button id="ok-button" type="text" label="Okay" slot="action" (click)="confirmErrorDialog(cameraErrorDialog)"></dx-button>
</dx-alert>
<!-- error dialog is only shown on surface device and mozilla firefox -->
<dx-alert #surfacefirefoxerror
          id="surface-firefox-error-alert"
          icon="warndreieck"
          type="error"
          titlestring="Fotofunktion nicht nutzbar"
          content="Um die Fotofunktion nutzen zu können, verwenden Sie bitte den Google Chrome Browser.">
  <dx-button id="surfacefirefoxerror-ok-button" type="text" label="Okay" slot="action" (click)="closeDialog()"></dx-button>
</dx-alert>
<dx-alert #imagecapturingerror
          id="img-captureing-error-alert"
          icon="warndreieck"
          type="error"
          titlestring="Fehler bei der Bildaufnahme"
          content="Bitte schließen Sie den Kamera-Dialog und versuchen Sie es erneut.">
  <dx-button id="img-captureing-error-alert-ok-button" type="text" label="Okay" slot="action" (click)="confirmErrorDialog(imageCapturingErrorAlert)"></dx-button>
</dx-alert>
<div *ngIf="showCamera" id="upload-dialog-fullscreen" class="fullscreen">
  <button id="close-dialog-button" class="white-button-with-svg" (click)="abortProcess()" [ngClass]="{'hide-close-button': hideCloseButton}">
    <dx-icon icon="x-gross" size="24" color="default"></dx-icon>
  </button>
  <lib-camera
    [images]="images"
    [originalImages]="originalImages"
    (openPreview)="openPreview(true)"
    (noCameraAvailable)="showErrorCameraDialog($event)"
    (noImageCapturedErrorOccured)="showImageCapturedErrorDialog()"
    (extendedImages)="extendedImages($event)"
    (extendedOriginalImages)="extendedOriginalImages($event)"
    [ngStyle]="{'display': showPreview ? 'none' : 'inline'}">
  </lib-camera>
  <lib-document-preview
    *ngIf="showPreview"
    [images]="images"
    [originalImages]="originalImages"
    [selectedImage]="selectImageInPreview"
    (captureNewImage)="openPreview(false)"
    (capturedImagesChanged)="changedImages($event)"
    (updateOriginalImages)="updateOriginalImages($event)"
    (selectedImageChanged)="changeSelectedImage($event)"
    (triggerUpload)="closeDialog()"
    (cropperIsOpen)="hideDialogCloseButton = $event"
  >
  </lib-document-preview>
</div>
