<div style='width: 100%; height: 100%; display: flex; justify-content: left; align-items: center'>
  <div style='display: none;'>Placeholder für Dateiname</div>
</div>
<div class='document-viewer-main'>
  <div *ngIf='loading || !pages[imageIndex]?.saveBlobUrl'
       id='document-loading-spinner-container'
       class='document-loading'>
    <dx-loading-indicator id='image-loading-spinner' size='large' theme='light'
                          label='Dokument wird geladen ...'></dx-loading-indicator>
  </div>

  <div *ngIf='errors.length' id='dokument-errors'>
    <div *ngFor='let error of errors' class='dokument-error'>{{error}}</div>
  </div>

  <!-- fixed size OCR preview on hover -->
  <div id='ocr-info' #ocrinfo></div>

  <!-- Dokument Zoom Beispiel aus https://stackblitz.com/edit/angular-d0x-zoom -->
  <!-- Fixed size container with scroll bars -->
  <div id='scroller' #scroller (mouseup)='markNCopyechecked? this.copySelectedText(): null'>


    <!-- Zoom des Users -->
    <div id='zoomer' [ngStyle]=" {
    width: zoomedWidth + 'px',
    height: zoomedHeight + 'px'
  } ">

      <!-- Rotation der OCR -->
      <div id='rotator' [ngStyle]="{ transform: 'rotate( ' + rotation + 'deg)' }">

        <img id='image' alt='Dokument'
             *ngIf='pages[imageIndex]?.saveBlobUrl' [src]='pages[imageIndex].saveBlobUrl'
             style='width: 100%; height: 100%'>


        <svg id='ocr-container'
             style='width: 100%; height: 100%'
             attr.viewBox='0 0 {{contentWidth}} {{contentHeight}}'
             xmlns='http://www.w3.org/2000/svg'>
          <ng-container *ngFor='let elem of boundingBoxData'>

            <text *ngIf="elem.type === 'word' && markNCopyechecked && (elem.wordLength>elem.fontSize)"
                  (mouseout)='hideOcrinfo()'
                  (mousemove)='updateOcrinfoForSelection()'
                  [attr.x]='elem.xValue'
                  [attr.y]='elem.yValue'
                  fill='transparent'
                  [attr.textLength]='elem.wordLength'
                  [attr.font-size]='elem.fontSize'
            >{{elem.dataText}}</text>

            <polygon *ngIf='!markNCopyechecked'
                     id='{{elem.id}}'
                     [attr.class]='elem.type'
                     [attr.points]='elem.boundingBox'
                     (click)='onSelectValue(elem.id)'
                     [class.is-selected]='elem.isSelected'
                     (mouseover)='updateOcrinfo(elem.dataText)'
                     (mouseout)="updateOcrinfo('')">
            </polygon>
          </ng-container>
        </svg>
      </div>
    </div>
  </div>
</div>
<div id='document-controls' *ngIf='pages.length'>

  <div id='auftrag-actions' class='flex-content'>
    <app-download-auftrag [auftragsId]='currentAuftrag?.auftragId'
                          [kundenNummer]='currentAuftrag?.kundenNummer'
                          [goyaId]="currentAuftrag?.goyaId ?? ''"
                          (handleDownloadError)='handleDownloadError.emit()'
                          (downloadButtonClicked)='downloadButtonClicked()'
    >
    </app-download-auftrag>
    <dx-button
      id='button_delete'
      type='text'
      icon='muell'
      theme='destructive'
      (click)='deleteAktuellenAuftrag()'>
    </dx-button>
  </div>

  <ng-container *ngIf='pages.length > 1'>
    <div id='pagination-buttons' class='actions-with-text'>
      <dx-button
        id='button_prev'
        type='text'
        icon='chevron-links-kreis'
        (click)='onPrevImg()'
        [disabled]='!hasPreviousPage()'>
      </dx-button>

      <div id='seitenindex' class='flex-content'>
        <dx-text type='its' id='seitenindex_klein' *ngIf='fiftyfifty; else full'>S. {{ imageIndex + 1 }}
          /{{ pages.length }}</dx-text>
        <ng-template #full>
          <dx-text id='seitenindex_lang' type='its'>Seite {{ imageIndex + 1 }}
            von {{ pages.length }}</dx-text>
        </ng-template>
      </div>

      <dx-button
        id='button_next'
        type='text'
        icon='chevron-rechts-kreis'
        (click)='onNextImg()'
        [disabled]='!hasNextPage()'>
      </dx-button>
    </div>
  </ng-container>

  <div id='zoom-actions' class='flex-content'>
    <div id='zoom-in-out' class='actions-with-text'>
      <dx-button
        id='button_zoom_out'
        type='text'
        icon='minus-kreis'
        (click)='changeZoom(-.1)'
        [disabled]='zoomMinReached'>
      </dx-button>

      <div class='flex-content'>
        <dx-text type='its' id='zoom_label'>{{ zoom * 100 | number:'1.0-0' }}%</dx-text>
      </div>

      <dx-button
        id='button_zoom_in'
        type='text'
        icon='plus-kreis'
        (click)='changeZoom(.1)'
        [disabled]='zoomMaxReached'>
      </dx-button>
    </div>
    <ng-container *ngIf='currentBreakPointAsNumber >= 4'>
      <dx-spacer size='24'></dx-spacer>
      <dx-button
        id='button_zoom_fit'
        type='text'
        icon='volle-breite'
        (click)='resetZoom()'>
      </dx-button>
      <dx-button
        id='button_zoom_full'
        type='text'
        icon='vollbild-start'
        (click)='showFullDoc()'>
      </dx-button>
    </ng-container>
  </div>

</div>
