import { Injectable } from '@angular/core';
import { Stage } from '../../../projects/upload-components/src/lib/stage/stage'

@Injectable({
  providedIn: 'root'
})
export class MatomoService {
  private matomoUrl: string

  constructor(private stage: Stage) {
    this.initMatomoUrl()
  }

  private initMatomoUrl() {
    const stage = this.stage.getStage()
    switch (stage) {
      case 'localhost':
      case 'entwicklung':
      case 'integration':
      case 'abnahme':
        console.log('stge is:fran ', stage)
        this.matomoUrl = 'https://analytics.produktion.dvag/js/container_FboMKr8d_dev_ba3323e9b104d0a9a0e312cf.js'
        break
      case 'produktion':
        this.matomoUrl = 'https://analytics.produktion.dvag/js/container_FboMKr8d.js'
        break
      default:
        this.matomoUrl = 'https://analytics.produktion.dvag/js/container_FboMKr8d_dev_ba3323e9b104d0a9a0e312cf.js'
    }
  }


  initializeMatomo() {
    const _mtm = (window as any)._mtm = (window as any)._mtm || [];
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });

    const g = document.createElement('script');
    g.type = 'text/javascript';
    g.async = true;
    g.src = this.matomoUrl;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode?.insertBefore(g, s);
    console.log('Matomo initialized fran', this.matomoUrl)
  }

  /*orginalMatomoCode() {
    const _mtm = window._mtm = window._mtm || [];
    _mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
    (function () {
      const d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = 'https://analytics.produktion.dvag/js/container_FboMKr8d_dev_ba3323e9b104d0a9a0e312cf.js';
      s.parentNode.insertBefore(g, s);
    })();
  }*/
}
