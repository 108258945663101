import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { Observable, of } from 'rxjs'
import { KeycloakApiService } from '../store/services/keycloak.api.service'
import { Stage } from '../../../projects/upload-components/src/lib/stage/stage'


export interface VbBild {
  id: string,
  aufloesung: string,
  kategorie: string,
  vbNummer: string,
  url: URL,
  publicUrl: URL,
}

@Injectable({ providedIn: 'root' })
export class VbAvatarService {

  private avatarApiUrl: string
  private aufloesung = '144x144'

  constructor(private http: HttpClient, private keycloakApiService: KeycloakApiService, private stage: Stage) {
    this.avatarApiUrl = environment.avatarUrls[this.stage.getStage()]
  }

  getAvatarURL(): Observable<URL> {
    const vbNummer = this.keycloakApiService.getVBNummerFromToken() ?? ''
    if(!vbNummer) {
      return of(null)
    }
    const url = this.avatarApiUrl.replace('{replaceWithVbnummer}', vbNummer)
    return this.http.get<VbBild[]>(url, { responseType: 'json' })
      .pipe(
        map((bilder: VbBild[]) => bilder.find(bild => bild.url && bild.aufloesung === this.aufloesung && vbNummer === bild.vbNummer)?.url ?? null)
      )

  }
}
