import { Component, ElementRef, ViewChild } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { BasisNumberFormularFeld } from '../basis-number-formular-feld'
import { DecimalPipe } from '@angular/common'
import {
  AktivesEingabefeldHandlerService,
} from '../../basis-input-formular-feld/services/aktives-eingabefeld-handler.service'
import { Components } from '@dvag/design-system'
import DxNumberInput = Components.DxNumberInput

@Component({
  selector: 'app-ganzzahl',
  templateUrl: './ganzzahl.component.html'
})
export class GanzzahlComponent extends BasisNumberFormularFeld {

  @ViewChild('focusElement') focusElement: DxNumberInput
  @ViewChild('focusElement', { read: ElementRef }) elementRef: ElementRef

  constructor(public override fb: FormBuilder, public override focusedControlHandlerService: AktivesEingabefeldHandlerService, protected override decimalPipe: DecimalPipe) {
    super(fb, focusedControlHandlerService, decimalPipe)
  }

  override getReadonlyDisplayValue(): string {
    return this.field.tausenderTrennzeichen ? this.decimalPipe.transform(this.initialValue, '1.0') : this.initialValue
  }

  override async focus(): Promise<void> {
    if( this.focusElement?.focusControl ) {
      try {
        await this.focusElement?.focusControl()
      } catch {
        console.log(`Es ist eine Fehler beim Setzen des Fokus beim Feld '${this.field.name}' augetreten`)
      }
    } else {
      console.warn(`An dem FormularFeld '${this.field.name}' vom Typ '${typeof this.focusElement}' existiert keine Methode 'focusControl()'!`)
    }
  }

  override castOcrStringToNumberFormat(ocrText: string): string {
    return super.castOcrStringToNumberFormat(ocrText).split('.')[0]
  }
}
