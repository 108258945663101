import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { Haushaltsmitglied } from '../model/haushaltsmitglied.model'
import value from '*.json'

@Component({
  selector: 'app-haushaltsmitglied-auswahl',
  templateUrl: './haushaltsmitglied-auswahl.component.html',
  styleUrls: ['./haushaltsmitglied-auswahl.component.scss'],
})
export class HaushaltsmitgliedAuswahlComponent implements OnChanges {

  @Input()
  label: string

  // entspricht der Kundennummer
  @Input() value: string

  // Haushaltsmitglieder in der Reihenfolge in der sie angezeigt werden sollen
  @Input()
  haushaltsmitglieder: Haushaltsmitglied[] = []

  // Gibt die selektierte Kundennummer zurueck, wenn sie sich zu "value" unterscheidet
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>()
  // Gibt die selektiert Kundennummer zurueck, dann wenn die Komponente den Fokus verliert
  // und wenn sie sich zu "value" unterscheidet
  @Output() valueComplete: EventEmitter<string> = new EventEmitter<string>()

  @ViewChild('customselect') inputElement: ElementRef;

  selektierteOption: string = ''
  isHaushaltSelectOpened: boolean = false
  selektierterKunde: Haushaltsmitglied


  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ((this.haushaltsmitglieder.length > 1 && this.value) || (changes.value.currentValue && this.haushaltsmitglieder.length > 1)) {
      this.selektierteOption = this.value
      this.selektierterKunde = this.haushaltsmitglieder.find(it => it.kundenNummer === this.selektierteOption)
    }
  }

  kundenzuordnungWechseln(kundenNummer: string) {
    this.selektierteOption = kundenNummer
    this.inputElement.nativeElement.focus()
    this.changeDetectorRef.detectChanges()
    console.log('setFokus to Input')
    this.selektierterKunde = this.haushaltsmitglieder.find(it => it.kundenNummer === this.selektierteOption)
    if(this.selektierteOption !== this.value) {
      this.valueChange.emit(this.selektierteOption)
    }
    this.isHaushaltSelectOpened = false
  }

  toggleOptions() {
    this.isHaushaltSelectOpened = !this.isHaushaltSelectOpened
  }

  handleBlur(event: FocusEvent) {
    console.log('handle blur event')
    setTimeout(() => {
      const focusedElement = document.activeElement;
      if (focusedElement.id === 'mitgliederauswahl-select') {
        console.log('ValueComplete is not triggered => Custom DropDown is still focused');
      } else {
        if(this.isHaushaltSelectOpened) {
          this.isHaushaltSelectOpened = false
        }

        if(this.selektierteOption !== this.value) {
          console.log('ValueComplete is triggered');
          this.valueComplete.emit(this.selektierteOption)
        } else {
          console.log('ValueComplete is not triggered => Value did not change to ');
        }
      }
    }, 100) 
  }
}
