import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core'
import { debounce, filter, map, switchMap, tap } from 'rxjs/operators'
import { BehaviorSubject, interval, Subscription } from 'rxjs'
import { Kunde } from '../../model/kunde.model'
import { KundenHttpService } from '../../services/kunden-http.service'
import { DxTextInput } from '@dvag/design-system-angular'
import { VbHttpService } from '../../services/vb-http.service'

@Component({
  selector: 'lib-kundensuche',
  templateUrl: './kundensuche.component.html',
})
export class KundensucheComponent implements OnInit, OnDestroy {

  @Input() ladeZuletztVerwendeteKunden: boolean = true

  @Output()
  benutzeKundenliste: EventEmitter<Kunde[]> = new EventEmitter<Kunde[]>()

  @Output()
  hatZuletztVerwendeteKunden: EventEmitter<Kunde[]> = new EventEmitter<Kunde[]>()

  @Output()
  showLoadingInfo: EventEmitter<'loading' | 'error'> = new EventEmitter<'loading' | 'error'>()

  @ViewChild('kundensucheref') kundenSucheElementRef: DxTextInput

  private kundensuche = new BehaviorSubject('')
  private kundensucheSub: Subscription

  constructor(private kundenHttpService: KundenHttpService, private vbHttpService: VbHttpService) {
  }

  ngOnInit() {
    if (this.ladeZuletztVerwendeteKunden) {
      this.vbHttpService.getZuletztVerwendeteKunden().subscribe(kunden => {
        if (kunden && kunden.length > 0) {
          this.hatZuletztVerwendeteKunden.emit(kunden)
        }
      })
    }
    this.kundensucheSub = this.kundensuche.pipe(
      debounce(() => interval(500)),
      map(value => value.trim()),
      filter(value => value && value.length >= 3),
      tap(() => {
        this.showLoadingInfo.emit('loading')
      }),
      switchMap(value => {
        return this.kundenHttpService.sucheKunde(value.toLowerCase())
      }),
    ).subscribe(
      {
        next: (kunden) => {
          this.benutzeKundenliste.emit(kunden)
          if (kunden.length === 0) {
            this.kundenSucheElementRef.errormessage = 'Dieser Kunde konnte nicht gefunden werden. Bitte überprüfen Sie Ihre Eingabe.'
          } else {
            this.kundenSucheElementRef.errormessage = ''
          }
        },
        error: err => {
          console.log('DokumentUploadComponent uploadDokument() - Fehler bei der Kundensuche! ', err.status, err)
          this.showLoadingInfo.emit('error')
        },
      })
    setTimeout(() => {
      this.kundenSucheElementRef.focusControl()
    }, 0)
  }

  ngOnDestroy() {
    if (this.kundensucheSub) {
      this.kundensucheSub.unsubscribe()
    }
  }

  onSearchInput() {
    this.kundensuche.next(this.kundenSucheElementRef.value)
    if (this.kundenSucheElementRef.value === '') {
      this.kundenSucheElementRef.errormessage = ''
    }
  }
}
