<div class="container">

  <div class="box box-us" [ngClass]="isCompany">
    <div class="box-header">
      <div *ngIf="isCompany !== 'generali'; else generali">
        <div class="box-header-logo" [ngClass]="isCompany" id="{{isCompany}}"></div>
      </div>
      <ng-template #generali>
        <div class="box-header-logo"></div>
      </ng-template>
    </div>
    <div class="box-content" [ngClass]="isCompany">
      <div class="box-description" id="vertrag_generali">
        {{result?.sparte.name}} - <br> {{result?.quickcheck?.base}}
      </div>
      <div class="box-percent">
        {{barWidthBase}}%
      </div>
    </div>
    <div class="box-indicator">
      <dt-indicator-bar [barWidth]="barWidthBase" [company]="company"></dt-indicator-bar>
    </div>
  </div>

  <div class="box box-other">
    <div class="box-indicator">
      <dt-indicator-bar [barWidth]="barWidthCompare"></dt-indicator-bar>
    </div>
    <div class="box-content other">
      <div class="box-description" id="vertrag_vergleich">
        {{result?.sparte.name}} - <br> {{result?.quickcheck.compare}}
      </div>
      <div class="box-percent">
        {{barWidthCompare}}%
      </div>
    </div>
    <div class="box-footer">
      <div class="box-footer-text">
        <p class="u-3" id="{{result?.gesellschaft.name}}">{{result?.gesellschaft.name}}</p>
      </div>
    </div>
  </div>
</div>
