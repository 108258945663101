import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core'
import { GroupedKunde, Kunde, SimpleAdress } from '../../model/kunde.model'

@Component({
  selector: 'lib-kunden-auswahl',
  templateUrl: './kunden-auswahl.component.html',
  styleUrls: ['./kunden-auswahl.component.scss']
})
export class KundenAuswahlComponent {
  kunden: Kunde[] = []
  groupedKunden: GroupedKunde[] = []

  @Input()
  set kundenliste(value: Kunde[]) {
    if(value === null) {
      this.kunden = []
    } else {
      this.kunden = value;
      this.groupedKunden = this.getGroupedKunden();
    }
  }

  @Input()
  set vorselektierterKunde(value: Kunde) {
    if (value?.nummer !== this.selektierterKunde?.nummer) {
      this.selektierterKunde = null
      this.selektiereKunde(value)
    }
  }

  @Input()
  set adresseAnzeigen(value: boolean) {
    this.showKundenAdresse = value
    this.changeDetectorRef.detectChanges()
  }

  @Output()
  benutzeKunde: EventEmitter<string> = new EventEmitter<string>()

  selektierterKunde: Kunde = null
  showKundenAdresse = true

  constructor(private changeDetectorRef: ChangeDetectorRef) {
  }

  getCustomerText(kunde: Kunde) {
    let artikel;
    if (kunde?.anrede?.toLowerCase() === 'frau') {
      artikel = 'Die';
    } else {
      artikel = 'Der';
    }

    let status;
    if (kunde?.personStatus?.toLowerCase() === 'interessent') {
      if (kunde?.anrede?.toLowerCase() === 'frau') {
        status = 'Interessentin'
      } else {
        status = 'Interessent'
      }
    } else {
      if (kunde?.anrede?.toLowerCase() === 'frau') {
        status = 'Kundin'
      } else {
        status = 'Kunde'
      }
    }

    return `${artikel} ${status} ist Mitglied folgender Haushalte:`;
  }
  getGroupedKunden(): GroupedKunde[] {
    const groupedKunden: GroupedKunde[] = []

    this.kunden.forEach(kunde => {
      // Überprüfe, ob der Kunde schon in unserem groupedKunden-Array ist
      const kundeIstInMehrerenHaushalten = groupedKunden.find(g => g.nummer === kunde.nummer)

      // Check if any address property is null or undefined
      const isValidAddress = kunde.adresse && kunde.adresse.strasse && kunde.adresse.plz && kunde.adresse.ort

      if (!kundeIstInMehrerenHaushalten) {
        const newKunde: GroupedKunde = { ...kunde }
        // Die adressen-Property wird nur hinzugefügt, wenn die die adresse-Properties definiert sind.
        if (isValidAddress) {
          newKunde.adressen = [{
            strasse: kunde.adresse.strasse,
            plz: kunde.adresse.plz,
            ort: kunde.adresse.ort
          }]
        }
        groupedKunden.push(newKunde)
      } else {
        if (isValidAddress) {
          // Um sicher zu gehen, dass die adressen Property existiert, bevor wir darauf pushen
          if (!kundeIstInMehrerenHaushalten.adressen) {
            kundeIstInMehrerenHaushalten.adressen = []
          }
          kundeIstInMehrerenHaushalten.adressen.push({
            strasse: kunde.adresse.strasse,
            plz: kunde.adresse.plz,
            ort: kunde.adresse.ort
          })
        }
      }
    })
    return groupedKunden
  }

  selektiereKunde(kunde: Kunde) {
    console.log(kunde)
    if (kunde && kunde.eweZugestimmt && kunde?.nummer !== this.selektierterKunde?.nummer && this.kunden.some( kd => kd.nummer === kunde?.nummer )) {
      this.selektierterKunde = kunde
      this.benutzeKunde.emit(kunde.nummer)
      console.log('Kunde ausgewaehlt: %s %s', kunde.vorname, kunde.nachname, kunde.nummer)
    }
    this.changeDetectorRef.detectChanges()
  }
}
