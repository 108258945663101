import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { of, Subject, Subscription, timer } from 'rxjs'
import { Auftrag } from 'src/app/store/models/auftrag.model'
import { catchError, concatMap, filter, take, tap } from 'rxjs/operators'
import { CookieZustimmungService } from '../cookies/cookie-zustimmung/cookie-zustimmung.service'
import { AuftragService } from '../services/auftrag.service'
import { AuftragBearbeitungComponent } from '../auftrag-bearbeitung/auftrag-bearbeitung.component'
import { AuftragDatenbestand, AuftragKategorie, AuftragStatus } from '../../enums/auftrag.enums'
import { DxAlert } from '@dvag/design-system-angular'
import { BreadcrumbService } from '../../services/breadcrumb.service'

@Component({
  selector: 'app-dokument-splitscreen',
  templateUrl: './dokument-splitscreen.component.html',
  styleUrls: ['./dokument-splitscreen.component.scss']
})
export class DokumentSplitscreenComponent implements OnInit, OnDestroy {

  public currentAuftragSub: Subscription
  private pollingAuftragSub: Subscription
  private datenNachladenSub: Subscription
  private updateStateSub: Subscription

  public currentAuftrag: Auftrag

  public discardChangesAlert$: Subject<boolean> = new Subject<boolean>()

  public deselectElements: number
  public fetchErrorOccurred: boolean = false

  @ViewChild(AuftragBearbeitungComponent) auftragBearbeitungComponent: AuftragBearbeitungComponent
  @ViewChild('splitscreenalert') splitscreenAlert: DxAlert
  @ViewChild('auftragloeschenalert') auftragLoeschenAlert: DxAlert
  @ViewChild('warningunsaveddata') warningUnsavedData: DxAlert

  private submittedAllData: boolean = false

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private cookiesService: CookieZustimmungService,
    private auftragService: AuftragService,
    private breadcrumbService: BreadcrumbService) {
  }

  ngOnInit() {
    this.cookiesService.checkForCookiesAreAllowed()
    this.fetchAuftrag()
  }

  fetchAuftrag() {
    const auftragsId = this.activeRoute.snapshot.params.auftragsId
    this.currentAuftragSub = this.auftragService.fetchAuftrag(auftragsId)
      .pipe(
        catchError(_ => {
          this.fetchErrorOccurred = true
          this.splitscreenAlert.visible = true
          return of(null)
        })
      )
      .subscribe((auftrag: Auftrag) => {
        if (auftrag) {
          this.currentAuftrag = auftrag
          if (auftrag.kategorie === AuftragKategorie.OFFEN) {
            this.breadcrumbService.setBreadcrumb({label: 'Dokument verarbeiten', navigateTo: this.router.url})
          } else {
            this.breadcrumbService.setBreadcrumb({label: 'Dokument einsehen', navigateTo: this.router.url})
          }
          if (auftrag?.datenbestand === AuftragDatenbestand.NACHLADBAR) {
            this.ladeDatenNach(auftrag.auftragId)
          }
        }
      })
  }

  handleAlertOkClick() {
    this.splitscreenAlert.visible = false
    this.navigateBackToDashboard()
  }


  ngOnDestroy() {
    this.updateStateSub?.unsubscribe()
    this.currentAuftragSub?.unsubscribe()
    this.pollingAuftragSub?.unsubscribe()
    this.datenNachladenSub?.unsubscribe()
    this.breadcrumbService.resetBreadcrumbs()
  }

  ladeDatenNach(auftragId: string) {
    this.auftragService.ladeDatenFuerAuftragErneut(auftragId)
      .subscribe({
        next: _ => {
          this.pollingAuftragSub = timer(0, 2000)
            .pipe(
              concatMap(_ => this.auftragService.fetchAuftrag(auftragId)),
              filter(auftrag => auftrag.datenbestand !== AuftragDatenbestand.BEREITSTELLUNG),
              take(1)
            ).subscribe({
              next: auftrag => {
                this.currentAuftrag = auftrag
                if (this.currentAuftrag.datenbestand === AuftragDatenbestand.BEREITSTELLUNGSFEHLER) {
                  this.showSimpleSplitscreenErrorAlert('Dokument konnte nicht geladen werden', 'Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder laden Sie das Dokument neu hoch.')
                }
              },
              error: _ => {
                this.showSimpleSplitscreenErrorAlert('Dokument konnte nicht geladen werden', 'Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut oder laden Sie das Dokument neu hoch.')
              }
            })
        }
      })
  }

  openAlert() {
    this.warningUnsavedData.visible = true
    return this.discardChangesAlert$.pipe(tap(() => this.warningUnsavedData.visible = false))
  }


  private canGoBack() {
    if(this.fetchErrorOccurred){
      return true
    }
    if (this.auftragBearbeitungComponent?.auftragBearbeitungsStatus === AuftragStatus.READONLY) {
      return true
    }
    if (this.submittedAllData) {
      return true
    }
    return !this.auftragBearbeitungComponent.containsUnsavedData()
  }

  goBack() {

    console.log('inside go back')
    if (this.canGoBack()) {
      return true
    }
    return this.openAlert().pipe(
      tap((res: boolean) => {
        console.log('result: ', res)
      })
    )
  }

  formSubmitted() {
    this.submittedAllData = true
    this.navigateBackToDashboard()
  }

  navigateBackToDashboard() {
    this.router.navigate(['/dashboard'])
  }

  public showDeleteConfirmationAlert() {
    this.auftragLoeschenAlert.visible = true;
  }

  public async onDeleteAuftrag() {
    this.auftragService.deleteAuftrag(this.currentAuftrag).subscribe({
      next: () => {
        this.navigateBackToDashboard()
      },
      error: (err) => {
        console.log('Error on action \'DeleteAuftrag\'. The Auftrag with the id \' %s\' could not be removed. ', this.currentAuftrag.auftragId, err)
        this.showSimpleSplitscreenErrorAlert('Fehler beim Löschen des Auftrags')
      }
    })
    this.auftragLoeschenAlert.visible = false
  }

  showDownloadError() {
    this.showSimpleSplitscreenErrorAlert('Fehler beim Herunterladen der Datei')
  }

  showSimpleSplitscreenErrorAlert(title: string, content?: string, sublabel?: string ) {
    this.splitscreenAlert.titlestring = title
    this.splitscreenAlert.content = content ? content : 'Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.'
    this.splitscreenAlert.sublabel = sublabel ? sublabel : ''
    this.splitscreenAlert.visible = true
  }

  updatedAuftrag(auftrag) {
    this.currentAuftrag = auftrag
  }
}
