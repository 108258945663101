import { EventEmitter, Injectable } from '@angular/core'
import { BaseEvent, ErrorEvent, FinalizedCloseReason, JobError, UploaderEvent, UploadJob } from './model/events'
import * as uuid from 'uuid'
import { HttpEventType } from '@angular/common/http'
import { catchError, concatMap, delay, filter, mergeMap, repeatWhen, switchMap, take, tap } from 'rxjs/operators'
import { forkJoin, Observable, of, throwError } from 'rxjs'
import { AuftragLight, AuftragLightHttpService } from './services/auftrag-light-http.service'
import { Payload } from './model/payload'
import { BucketsHttpService } from './services/buckets-http.service'
import { VerarbeitungsErgebnis } from './model/verarbeitungs-ergebnis.model'
import { AuftragDatenbestand, AuftragKategorie } from '../../../../src/app/enums/auftrag.enums'
import {
  ApplicationInsightsService,
  DT_FE_CUSTOM_EVENT,
} from '../../../../src/app/services/application-insights.service'
import { BackendConfigObject } from './model/config-options.model'
import { Stage } from './stage/stage'

export interface Dokument {
  dateien: Payload[]
}

@Injectable({
  providedIn: 'root',
})
export class UploadService {

  startUploadEvent = new EventEmitter<UploadJob>()
  uploadEvents = new EventEmitter<UploaderEvent>()
  errorEvents = new EventEmitter<ErrorEvent>()
  parallelUploads: {
    id: string,
    startTime: number,
    containingUploads: number,
    remainingUploads: number
  }[] = []


  constructor(private uploadClientService: AuftragLightHttpService,
              private bucketsClientService: BucketsHttpService,
              private applicationInsightsService: ApplicationInsightsService,
              private stage: Stage) {
  }

  /**
   * Diese Methode wird aufgerufen, wenn der Upload-Vorgang ohne Upload abgebrochen wurde, damit ein entsprechendes Finalized-Event gefeuert wird.
   * Dies kann zum einen über den X-Button erfolgen oder aber im Falle des Geraetewechsel auch beim einfachen Schließen sein
   * @param backendConfig - BackendConfigObject, das beim Start des Uploads übergeben wurde
   * @param closeReason - es ist ein Grund vom Typ FinalizedCloseReason anzugeben
   * @param errorMesssage - optional, wird nur gesetzt, wenn der Grund 'ERROR' ist
   */
  closeUploadProcess(backendConfig: BackendConfigObject, closeReason: FinalizedCloseReason, errorMesssage?: string) {
    const uploaderEvent: UploaderEvent = {
      id: uuid.v4(),
      status: 'FINALIZED',
      reason: closeReason,
      backendConfigObject: backendConfig,
    }
    if (closeReason === 'ERROR' && errorMesssage) {
      uploaderEvent.error = errorMesssage
    }
    this.uploadEvents.emit({ ...uploaderEvent })
    return null
  }

  async retryUpload(job: UploadJob) {
    const uploaderEvent: UploaderEvent = {
      ...this.createBaseEvent(job),
      status: 'UPLOADING',
    }
    this.bucketsClientService.loescheDokumenteImBucket(job.bucketId).subscribe({
      next: () => {
        this.handleUpload(job, uploaderEvent)
      },
      error: error => {
        this.errorEvents.emit({ job, message: 'Error: Failed to prepare retry for the unsuccessful upload.', error })
        return
      },
    })
  }

  startUpload(bucketId: string,
              payloads: Payload[],
              mergeToSinglePdf = true,
              backendConfigObject?: BackendConfigObject,
  ) {
    const id = uuid.v4()
    const job: UploadJob = { id, bucketId, mergeToSinglePdf, backendConfigObject, payloads }
    const uploaderEvent: UploaderEvent = {
      ...this.createBaseEvent(job),
      status: 'UPLOADING',
    }

    // Wenn keine Bilder/Dateien übergeben werden soll ein Finalized-Event gesendet werden
    if (payloads.length === 0) {
      uploaderEvent.status = 'FINALIZED'
      uploaderEvent.reason = 'SUCCESSFUL'
      this.uploadEvents.emit({ ...uploaderEvent })
      return null
    }

    // Wenn kein Bucket existiert, dann kann kein Upload erfolgen
    if (!bucketId) {
      console.error('[Datenturbo]: Es kann keinen Upload ohne Bucket geben')
      uploaderEvent.status = 'FINALIZED'
      uploaderEvent.reason = 'ERROR'
      uploaderEvent.error = 'Error: Upload cannot be performed without a bucketID'
      this.uploadEvents.emit({ ...uploaderEvent })
      return null
    }
    this.handleUpload(job, uploaderEvent)
  }

  private async handleUpload(job: UploadJob, uploaderEvent: UploaderEvent) {
    uploaderEvent.status = 'UPLOADING'
    this.uploadEvents.emit({ ...uploaderEvent })

    let uploadResults: { dokumentId: string, verarbeitungsErgebnis?: VerarbeitungsErgebnis }[] = []
    let dokumente: Dokument[]
    try {
      if (job.mergeToSinglePdf) {
        dokumente = [{ dateien: job.payloads }]
      } else {
        dokumente = job.payloads.map(payload => ({ dateien: [payload] }))
      }

      this.parallelUploads.push({
        id: job.id,
        startTime: Date.now(),
        containingUploads: dokumente.length,
        remainingUploads: dokumente.length,
      })
      uploadResults = await this.uploadDocuments(job.id, job.bucketId, dokumente, uploaderEvent)
    } catch (error) {
      console.error('Error: File upload failed with error: ', error)
      this.errorEvents.emit({ job, message: 'Error: File upload failed.', error })
      return
    }

    uploaderEvent.uploadProgress = this.toUploadProgress(dokumente.map((currDokument) => ({ dateienProgresses: currDokument.dateien.map(() => 1) })))

    // Upload abgeschlossen und Start der Verarbeitung im BE
    // upload-Event mit Infos füllen und verschicken
    // TODO: [DT-713] - Zweite Prüfung im if-Statement muss überarbeitet werden, sobald WebVP umgestellt hat
    if (job.backendConfigObject.documentUploadEndpoint && job.backendConfigObject.documentUploadEndpoint !== `https://vertragsabschluss.${this.stage.getStage()}.dvag`) {
      const tempDocumentIds = uploadResults.map(res => res.dokumentId)
      try {
        await this.bucketsClientService.verarbeiteDokumente(job.bucketId, tempDocumentIds).toPromise()
      }  catch (error) {
        const errormsg = `Error: Processing all documents in bucket '${job.bucketId}' failed.`
        console.error(`${errormsg} Received error: `, error)
        this.errorEvents.emit({ job, message: errormsg, error })
        return
      }
      this.applicationInsightsService.logEvent(DT_FE_CUSTOM_EVENT.UPLOADER_ENDPOINT, {
        bucketId: job.bucketId,
        dokumentIds: tempDocumentIds,
      })
      uploaderEvent.status = 'PDF_CREATED'
      this.uploadEvents.emit({ ...uploaderEvent })
    } else { // Früher Flow AUFTRAG und ZUSATZDOKUMENTE von WebVP
      // TODO: [DT-713] - Überarbeiten wenn WebVP umgestellt hat - Anfang
      if (!job.backendConfigObject.documentUploadEndpoint) {
        this.applicationInsightsService.logEvent(DT_FE_CUSTOM_EVENT.UPLOADER_AUFTRAG, {
          bucketId: job.bucketId,
          auftragsIds: uploadResults.map(res => res.verarbeitungsErgebnis.auftragsId),
          dokumentIds: uploadResults.map(res => res.dokumentId),
        })
      } else {
        this.applicationInsightsService.logEvent(DT_FE_CUSTOM_EVENT.UPLOADER_ENDPOINT, {
          bucketId: job.bucketId,
          dokumentIds: uploadResults.map(res => res.dokumentId),
        })
      }
      // TODO: [DT-713] - Überarbeiten wenn WebVP umgestellt hat - Ende

      uploaderEvent.status = 'BACKGROUND_PROCESSING'
      this.uploadEvents.emit({ ...uploaderEvent })

      // pruefen, das alle Auftraege in DC angelegt wurden
      try {
        const erzeugteAuftraege: AuftragLight[] = await this.waitForCompletion(
          uploadResults
            .filter(ergebnis => ergebnis.verarbeitungsErgebnis?.auftragsId != null)
            .map(ergebnis => ergebnis.verarbeitungsErgebnis.auftragsId),
        ).toPromise()

        uploaderEvent.auftraege = uploadResults
          .map(uploadResult => ({
            goyaMeta: uploadResult.verarbeitungsErgebnis.goyaMeta,
            auftragsId: uploadResult.verarbeitungsErgebnis.auftragsId,
          }))
          .filter(goyaMetaMitAuftrag =>
            erzeugteAuftraege?.find(auftrag => auftrag.auftragId === goyaMetaMitAuftrag.auftragsId)?.kategorie === AuftragKategorie.OFFEN,
          )
      } catch (error) {
        const errmsg = 'Error: Polling for backend processing to be completed failed.'
        this.abortUpload(job, { message: errmsg, error })
        return
      }
    }

    uploaderEvent.status = 'FINALIZED'
    uploaderEvent.reason = 'SUCCESSFUL'
    this.uploadEvents.emit({ ...uploaderEvent })
  }

  /**
   * Wird aktuell aufgerufen, wenn
   * - ein Fehler passiert ist und kein weiterer Retry erfolgen soll und
   * - wenn im Flow Auftrag beim Polling der BackendVerarbeitung ein Fehler auftritt (kein Retry erforderlich, da Auftrag bereits in DT)
   * @param job - UploadJob der abgebrochen werden soll.
   * @param error - JobError der eine Message und den aufgetretenen Fehler enthält. Die Message wird mit ausgegeben.
   */
  abortUpload(job: UploadJob, error: JobError) {
    this.uploadEvents.emit({
      id: job.id,
      backendConfigObject: job.backendConfigObject,
      status: 'FINALIZED',
      reason: 'ERROR',
      error: error.message,
    })
  }

  private createBaseEvent(job: UploadJob): BaseEvent {

    return {
      id: job.id,
      bucketId: job.bucketId,
      uploadProgress: { files: job.payloads.map(() => 0), total: 0 },
      backendConfigObject: job.backendConfigObject,
    }
  }

  private uploadDocuments(jobId: string, bucketId: string, documents: Dokument[], baseEvent: BaseEvent): Promise<{
    dokumentId: string,
    verarbeitungsErgebnis?: VerarbeitungsErgebnis
  }[]> {
    const progresses = documents.map((dokument) => ({ dateienProgresses: dokument.dateien.map(() => 0) }))

    const documentsUpload = documents.map((document, docidx) => {
      return this.bucketsClientService.erstelleDokumentImBucket(bucketId).pipe(
        concatMap(dokumentId => {
          const uploadRequests = document.dateien.map((payload, index) => {
            return this.bucketsClientService.addDateiOderBildZumDokument(bucketId, dokumentId, index, payload)
              .pipe(
                tap(event => {
                  if (event.type === HttpEventType.UploadProgress) {
                    progresses[docidx].dateienProgresses[index] = event.loaded / event.total
                    this.uploadEvents.emit({
                      ...baseEvent,
                      uploadProgress: this.toUploadProgress([...progresses]),
                      status: 'UPLOADING',
                    })
                  }
                }),
                filter(event => event.type === HttpEventType.Response),
              )
          })
          return forkJoin(uploadRequests).pipe(
            tap(_ => {
              this.parallelUploads = this.parallelUploads.map(upload => {
                if (upload.id === jobId && upload.remainingUploads > 0) {
                  const duration = Date.now() - upload.startTime
                  const dateigroessenPromises = document.dateien.map(payload => payload.getSize())
                  Promise.all(dateigroessenPromises).then((dateigroessen) => {
                    console.log('Dateigroessen: ', dateigroessen)
                    const sumFileSize = dateigroessen.reduce((sum, currentSize) => sum + currentSize, 0)
                    this.applicationInsightsService.logEvent(DT_FE_CUSTOM_EVENT.UPLOADED_DOCUMENT, {
                      bucketId: bucketId,
                      dokumentId: dokumentId,
                      uploadDauerInSekunden: duration / 1000,
                      anzahlDateien: document.dateien.length,
                      uploadDateiGesamtgroesse: sumFileSize,
                      uploadDateiGesamtgroesseMB: Number(((sumFileSize / 1024) / 1024).toFixed(3)),
                      backendConfig: {
                        ...baseEvent.backendConfigObject,
                        kundennummer: baseEvent.backendConfigObject.kundennummer ? 'xxxxxxxx' : null,
                      },
                    })
                  })
                  upload.remainingUploads--
                }
                return upload
              }).filter(upload => upload.remainingUploads > 0)
            }),
            switchMap((): Observable<{ dokumentId: string, verarbeitungsErgebnis?: VerarbeitungsErgebnis }> => {
              // TODO: [TD-713]: 2. Prüfung entfernen, wenn WebVP umgestellt hat
              if (!baseEvent.backendConfigObject.documentUploadEndpoint || baseEvent.backendConfigObject.documentUploadEndpoint === `https://vertragsabschluss.${this.stage.getStage()}.dvag`) {
                return this.bucketsClientService.verarbeiteDokument(bucketId, dokumentId).pipe(
                  switchMap(result => {
                    return of({ dokumentId: dokumentId, verarbeitungsErgebnis: result })
                  }),
                  catchError(error => {
                    const errmsg = `Error: Processing of the document '${dokumentId}' has failed`
                    console.error(`${errmsg}. Received error: `, error)
                    return throwError(() => errmsg)
                  })
                )
              } else {
                return this.bucketsClientService.dokumentVorverarbeiten(bucketId, dokumentId).pipe(
                  switchMap(() => {
                    return of({ dokumentId: dokumentId })
                  }),
                  catchError(error => {
                    const errmsg = `Error: Preprocessing of the document '${dokumentId}' has failed`
                    console.error(`${errmsg}. Received error: `, error)
                    return throwError(() => errmsg)
                  })
                )
              }
            }),
          )
        }),
      )
    })

    return forkJoin(documentsUpload).toPromise()
  }

  private toUploadProgress(dokumentenProgress: { dateienProgresses: number[] }[]): { files: number[], total: number } {
    const reducedUploadProgresses: number[] = dokumentenProgress
      .map(dokumentProgressObj => dokumentProgressObj.dateienProgresses)
      .reduce((acc, currentVal) => acc.concat(currentVal))
    return {
      files: reducedUploadProgresses,
      total: reducedUploadProgresses.reduce((a, b) => a + b) / reducedUploadProgresses.length,
    }
  }

  private waitForCompletion(auftragIds: string[]): Observable<AuftragLight[]> {
    const pollRequests = auftragIds.map(auftragsId =>
      of(auftragsId).pipe(
        // TODO: Maximale wartezeit einführen
        repeatWhen(obs => obs.pipe(delay(1000))),
        mergeMap(auftragId => this.uploadClientService.fetchAuftrag(auftragId)),
        filter(auftrag => auftrag.datenbestand !== AuftragDatenbestand.BEREITSTELLUNG),
        take(1),
      ),
    )
    const auftraege = forkJoin(pollRequests)
    this.trackUploadsInApplicationInsights(auftraege)
    return auftraege
  }

  private trackUploadsInApplicationInsights(auftraegeLight: Observable<AuftragLight[]>) {
    auftraegeLight.subscribe({
      next: auftraege => {
        auftraege.forEach((auftrag) => {
          const formulartyp = auftrag.vertraege[0]?.formularName ?? ''
          this.applicationInsightsService.logUploadAbgeschlossen(auftrag.ursprung, formulartyp)
        })
      },
      error: () => {
        this.applicationInsightsService.logError('Upload')
      },
    })
  }
}
