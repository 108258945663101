import { FeldValidator, FormularFeldConfig } from 'src/app/store/models/formular-feld-config.model'
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms'
import { FormularFeldValidatoren } from '../validators/formular-feld-validatoren'
import { BasisFormularFeld } from '../basis-formular-feld/basis-formular-feld'
import { AktivesEingabefeldHandlerService } from './services/aktives-eingabefeld-handler.service'
import {
  FormularFeldDictionaryService
} from '../../auftrag-bearbeitung/components/vertrags-formular/services/formular-feld-dictionary.service'


export abstract class BasisInputFormularFeld extends BasisFormularFeld {

  errormessage = ''
  public formControl: AbstractControl
  public initialValue: any

  constructor(public fb: FormBuilder, public focusedControlHandlerService: AktivesEingabefeldHandlerService) {
    super()
  }

  storeNameOfFocusedElement(fieldName: string): void {
    this.focusedControlHandlerService.aktivesEingabefeld = {
      setText: async (text: string) => {
        await this.setOcrResultToFormControl(text)
      },
      focus: async () => {
        await this.focus()
      }
    }
  }

  async setOcrResultToFormControl(ocrText: string): Promise<void> {
    const fcOldValue = this.readValue()

    let newValue
    if (ocrText) {
      newValue = this.prepareOcrTextForFormControl(ocrText, fcOldValue)
    }

    if (newValue !== undefined) {
      this.patchValue(newValue)
    }
    await this.focus()
  }

  readValue(): any {
    if (this.formControl?.value === '') {
      return null
    }
    return this.formControl?.value ?? null
  }

  patchValue(newValue): void {
    this.formControl?.patchValue(newValue)
    this.validate(newValue)
  }

  validate(newValue: any): void {
    // spezielle Validierungen müssen in den spezialisierteren Klassen vorgenommen werden
  }

  async focus(): Promise<void> {
    // die Fokussierung muss in den Komponenten erfolgen. Das DesignSystem hat an jeder Formular-Komponente ein Methode
    // focusControl() bereitgestellt, die das richtige Element fokussiert (KUBA-4875)
  }

  prepareOcrTextForFormControl(ocrText: string, oldValue: any): any {
    console.log('%s: prepare ocr text for form control: ', this.field.name, ocrText, oldValue)
    if (!ocrText) {
      return oldValue
    }
    if (!oldValue) {
      return ocrText
    }
    return oldValue + ocrText
  }

  override initBasisFormularFeld(group: FormGroup, field: FormularFeldConfig, formularId: string, isReadonly: boolean, auftragId?: string, formularFeldDictionaryService?: FormularFeldDictionaryService): void {
    super.initBasisFormularFeld(group, field, formularId, isReadonly, auftragId, formularFeldDictionaryService)
    this.formularFeldDictionaryService.addBasisInputFormularFeldToFieldName(field.name, this)
    this.attachToGroup()
  }

  buildFormControl(): AbstractControl {
    return this.fb.control(
      null,
      this.bindValidations(this.field.validators)
    )
  }

  /**
   * Hier können Componenten spezifische Validatoren hinzugefügt werden, die nicht an den FormularFeldern vom Backend hängen,
   * wie beispielsweise bei der DatumComponent
   */
  componentValidators(): ValidatorFn[] {
    return []
  }


  bindValidations(fieldValidators: FeldValidator[]): any[] | ValidatorFn {
    const componentValidators = this.componentValidators()

    if( fieldValidators?.length === 0 && componentValidators?.length === 0) {
      return []
    }

    const validList = componentValidators ?? []
    if (fieldValidators.length > 0) {
      fieldValidators
        .filter(validator => validator.typ === 'FEHLER')
        .forEach(fehlerValidator => {
          switch (fehlerValidator.name) {
            case 'MAXIMUM':
            case 'MINIMUM': {
              const minMaxValFn = this.getMinMaxValidator(fehlerValidator)
              if (minMaxValFn) {
                validList.push(minMaxValFn)
              }
              break
            }
            case 'IBAN':
              validList.push(FormularFeldValidatoren.iban())
              break
            case 'KENNZEICHEN':
              validList.push(FormularFeldValidatoren.kennzeichen())
              break
            case 'BIS_HEUTE':
              validList.push(FormularFeldValidatoren.bisHeute())
              break
            case 'MAX_ZEICHEN': {
              const maxZeichenValFn = this.getMaxZeichenValidFn(fehlerValidator)
              if (maxZeichenValFn) {
                validList.push(maxZeichenValFn)
              }
              break
            }
            case 'MIN_ZEICHEN': {
              const minZeichenValFn = this.getMinZeichenValidFn(fehlerValidator)
              if (minZeichenValFn) {
                validList.push(minZeichenValFn)
              }
              break
            }
            case 'ENTSPRICHT_REGEX': {
              validList.push(FormularFeldValidatoren.entsprichtRegex(fehlerValidator.params))
              break
            }
            default:
              console.log(`Found no Match vor Validator "${fehlerValidator.name}" in the list.`)
          }
        })
    }
    return Validators.compose(validList)
  }

  addToCompletedValues(value: any): void {
    // console.log('👽 %s: add to completed values: ', this.field.name, value)
    this.formularFeldDictionaryService.singleformularValueCompleted(this.field.name, value)
  }

  setInitialValue(value: any): void {
    this.initialValue = value
    this.patchValue(value)
  }

  hasValueChanged(): boolean {
    return this.initialValue !== this.readValue()
  }

  private attachToGroup() {
    if (this.fb) {
      this.formControl = this.buildFormControl()
      this.mainFormularGroup.addControl(this.field.name, this.formControl)
    }
  }

  private getMaxZeichenValidFn(fieldValidator: FeldValidator): ValidatorFn | null {
    let resultValidFn = null
    if (fieldValidator.params != null) {
      const anzahlZeichen = Number(fieldValidator.params)
      if (typeof anzahlZeichen === 'number') {
        resultValidFn = FormularFeldValidatoren.maxZeichen(anzahlZeichen)
      } else {
        console.log('The value of the validator "%s" is not a number: ', fieldValidator.name, fieldValidator.params)
      }
    } else {
      console.log('Value is missing for validator "%s".', fieldValidator.name)
    }
    return resultValidFn
  }

  private getMinZeichenValidFn(fieldValidator: FeldValidator): ValidatorFn | null {
    let resultValidFn = null
    if (fieldValidator.params != null) {
      const anzahlZeichen = Number(fieldValidator.params)
      if (typeof anzahlZeichen === 'number') {
        resultValidFn = FormularFeldValidatoren.minZeichen(anzahlZeichen)
      } else {
        console.log('The value of the validator "%s" is not a number: ', fieldValidator.name, fieldValidator.params)
      }
    } else {
      console.log('Value is missing for validator "%s".', fieldValidator.name)
    }
    return resultValidFn
  }

  private getMinMaxValidator(fieldValidator: FeldValidator): ValidatorFn | null {
    let returnValue: ValidatorFn = null
    if (fieldValidator.params != null) {
      const num = Number(fieldValidator.params)
      if (typeof num === 'number') {
        returnValue = 'MAXIMUM' === fieldValidator.name ? FormularFeldValidatoren.maximum(num) : FormularFeldValidatoren.minimum(num)
      } else {
        console.log('The value of the validator "%s" is not a number: ', fieldValidator.name, fieldValidator.params)
      }
    } else {
      console.log('Value is missing for validator "%s".', fieldValidator.name)
    }
    return returnValue
  }

  getReadonlyDisplayValue(): string {
    return this.initialValue ? this.initialValue : ''
  }

  getBetragValue(ocrText: string, oldValue: any): number {
    if (!ocrText) {
      return oldValue
    }
    const tempValue = this.castOcrStringToNumberFormat(ocrText)
    if (!Number(tempValue)) {
      return oldValue
    }
    if (!oldValue) {
      return Number(tempValue)
    }
    if (oldValue % 1 === 0) { // ganzzahlig
      return Number(oldValue + tempValue)
    } else { // gleitkomma
      return Number(oldValue + tempValue.replace(/\./g, ''))
    }
  }

  castOcrStringToNumberFormat(ocrText: string): string {
    return ocrText
      .replace(/ /g, '')
      .replace(/\./g, '')
      .replace(/\,/g, '.')
  }
}
