import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ImageCropperComponent } from './image-cropper.component';
import { DesignSystemModule } from '@dvag/design-system-angular'



@NgModule({
  declarations: [
    ImageCropperComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    imports: [
        DesignSystemModule
    ],
  exports: [
    ImageCropperComponent
  ],
})
export class ImageCropperModule { }
