<form *ngIf="fields?.length > 0" [formGroup]="form" id="dynamic-form">
  <div [ngClass]="{'formcontrol-grid': auftragBearbeitungsStatus !== auftragBearbeitungEnum.READONLY, 'formcontrol-readonly': auftragBearbeitungsStatus === auftragBearbeitungEnum.READONLY}">
    <ng-container *ngFor="let field of fields;">
      <div class="input-container"
           [ngClass]="{
                      'formular-field-fullwidth': this.field.fullWidth && auftragBearbeitungsStatus !== auftragBearbeitungEnum.READONLY,
                      'align-end': this.field.type === 'CHECKBOX' && auftragBearbeitungsStatus !== auftragBearbeitungEnum.READONLY
                      }">

        <div class="toggle-vis" [ngClass]="{'show': !showLoadingIndicator || !field.eingabefeld }">
          <ng-container appDynFormularFeld
                        [field]="field"
                        [group]="form"
                        [auftragId]="auftragId"
                        [currentFormularId]="formularInput.id"
                        [isReadonly]="auftragBearbeitungsStatus === auftragBearbeitungEnum.READONLY"
                        [formularFeldDictionaryService]="formularFeldDictionaryService"
          >
          </ng-container>
        </div>
        <div class="skeleton-box formular-skeleton" [ngClass]="{'hide': !showLoadingIndicator || !field.eingabefeld }">
        </div>
      </div>
    </ng-container>
  </div>
</form>
