import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { AuftragBearbeitenService } from '../../../../auftrag-bearbeitung/services/auftrag-bearbeiten.service'
import { Einkommen, EinkommenDTO, EinkommenKunde } from '../model/einkommen.model'
import { KundenHttpService } from '../../../../../store/services/kunden.http.service'
import { DecimalPipe } from '@angular/common'

@Injectable()
export class EinkommenService {
  kundenNummer: string = ''

  constructor(
    private auftragBearbeitenService: AuftragBearbeitenService,
    private kundenHttpService: KundenHttpService,
    private decimalPipe: DecimalPipe
  ) {
    this.auftragBearbeitenService.auftrag$
      .subscribe(auftrag => {
        if (auftrag) {
          this.kundenNummer = auftrag.kundenNummer
        }
      })
  }

  private gehaltSubject = new BehaviorSubject<Einkommen[]>([])
  private sonderzahlungSubject = new BehaviorSubject<Einkommen[]>([])
  gehalt$: Observable<Einkommen[]> = this.gehaltSubject.asObservable()
  sonderzahlung$: Observable<Einkommen[]> = this.sonderzahlungSubject.asObservable()

  setGehalt(gehalt: Einkommen[]): void {
    this.gehaltSubject.next(gehalt)
  }

  setSonderzahlung(sonderzahlung: Einkommen[]): void {
    this.sonderzahlungSubject.next(sonderzahlung)
  }

  fetchEinkommen(selectedKundenNummer?: string): void {
    const kundenNummer: string = selectedKundenNummer ?? this.kundenNummer
    if (!kundenNummer) {
      return
    }

    this.kundenHttpService.fetchEinkommen(kundenNummer).subscribe({
      next: (response) => {
        if (!response || response.length === 0) {
          this.setGehalt([])
          this.setSonderzahlung([])
          return
        }

        const data = response.find((e: EinkommenKunde) => e.kundenNummer === kundenNummer)
        const gehaelter: EinkommenDTO[] = data?.gehaelter || []
        const sonderzahlungen: EinkommenDTO[] = data?.sonderzahlungen || []

        this.setGehalt(gehaelter.map((e: EinkommenDTO) => ({
          ...e,
          displayText: this.generateDisplayText(e),
        })))
        this.setSonderzahlung(sonderzahlungen.map((e: EinkommenDTO) => ({
          ...e,
          displayText: this.generateDisplayText(e),
        })))
      },
      error: (error) => {
        console.error('Error fetching income data:', error)
        this.setGehalt([])
        this.setSonderzahlung([])
      },
    })
  }

  private generateDisplayText(einkommen: EinkommenDTO): string {
    let nettoFormatted: string = this.decimalPipe.transform(einkommen?.netto, '1.2-2', 'de') + ' € netto'

    let bruttoFormatted: string = ''
    if (einkommen?.brutto) {
      bruttoFormatted = ' / ' + this.decimalPipe.transform(einkommen.brutto, '1.2-2', 'de') + ' € brutto'
    }

    return nettoFormatted + bruttoFormatted
  }

  resetGehalt(): void {
    this.setGehalt([])
  }

  resetSonderzahlung(): void {
    this.setSonderzahlung([])
  }
}
