<div *ngIf="!isReadonly" [formGroup]="mainFormularGroup">
  <dx-number-input #focusElement
                   [formControlName]="field.name"
                   id="{{field.name}}_wert"
                   label="{{field.label}}"
                   thousandseparator="{{field.tausenderTrennzeichen}}"
                   precision="0"
                   required="{{field.required}}"
                   (focus)="storeNameOfFocusedElement(field.name)"
                   (valueComplete)="validate($event.detail)"
                   (valueChange)="validate($event.detail)"
                   [errormessage]="errormessage"
                   [kisynced]="field.kiSync"
                   placeholder="0"
  >
  </dx-number-input>
</div>
<app-readonly-template *ngIf="isReadonly" [field]="field" [displayValue]="getReadonlyDisplayValue()"></app-readonly-template>
