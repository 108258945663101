import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { BasisInputFormularFeld } from '../basis-input-formular-feld'
import { FeldValidator, TextKonfiguration } from '../../../../store/models/formular-feld-config.model'
import { AktivesEingabefeldHandlerService } from '../services/aktives-eingabefeld-handler.service'
import { DxTextInput } from '@dvag/design-system-angular'

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
})

export class TextComponent extends BasisInputFormularFeld implements OnInit {
  placeholder: string

  config: TextKonfiguration
  hasConfigLoescheZeichenAusOcrWert: boolean
  hasConfigMaskierung: boolean

  @ViewChild('focusElement') focusElement: DxTextInput
  @ViewChild('focusElement', { read: ElementRef }) elementRef: ElementRef

  constructor(public override fb: FormBuilder, public override focusedControlHandlerService: AktivesEingabefeldHandlerService) {
    super(fb, focusedControlHandlerService)
  }

  ngOnInit() {
    this.config = this.field.konfiguration as TextKonfiguration ?? null
    this.hasConfigMaskierung = !!this.config?.maskierung
    this.hasConfigLoescheZeichenAusOcrWert = !!this.config?.loescheZeichenAusOcrWert
  }


  valueAdded(e: KeyboardEvent) {
    if (!isNaN(parseInt(e.key)) || e.key === 'Backspace' || e.key === 'Tab') {
      console.log('added value is a number, Backspace or Tab')
    } else {
      e.preventDefault()
    }
  }

  override prepareOcrTextForFormControl(ocrText: string, oldValue: any): any {

    if (!this.config || !ocrText) {
      return super.prepareOcrTextForFormControl(ocrText, oldValue)
    }

    if (this.hasConfigLoescheZeichenAusOcrWert) {
      this.config.loescheZeichenAusOcrWert?.forEach((zeichen) => {
        ocrText = this.trimAndRemoveParentheses(ocrText, zeichen)
      })
    }

    // Postleitzahlen dürfen nicht mit führenden Nullen aufgefüllt werden
    // Daher ist es ein Textfeld mit Maskierung, um eine PLZ abzubilden.
    // In diesem Fall dürfen nur zahlen dem bestehenden Wert hinzugefügt werden.
    if( this.hasConfigMaskierung ) {
      if (!isNaN(parseInt(ocrText)) && this.hasConfigMaskierung) {
        return oldValue === null ? ocrText : oldValue + ocrText
      } else {
        return oldValue
      }
    }
    return super.prepareOcrTextForFormControl(ocrText, oldValue)
  }

  trimAndRemoveParentheses(input: string, zeichen: string): string {
    console.log('trimAndRemoveParentheses')
    // Creates a dynamic RegExp based on zeichen
    const regEx1 = new RegExp('\\' + zeichen, 'g')
    return input.trim().replace(regEx1, '')
  }

  override validate(value: string): void {
    this.errormessage = ''
    this.field.validators.forEach((validator: FeldValidator) => {
      // Wenn bereits eine Errormessage gesetzt ist, dann soll keine weitere mehr angezeigt werden
      if (this.errormessage) {
        return
      }
      if (validator.name.toLowerCase() === 'max_zeichen') {
        if (value?.length > validator.params) {
          this.errormessage = validator.fehlerText
        }
      }
      if (validator.name.toLowerCase() === 'min_zeichen') {
        if (value?.length < validator.params && value?.length > 0) {
          this.errormessage = validator.fehlerText
        }
      }
      if (validator.name.toLowerCase() === 'entspricht_regex') {
        const regex = RegExp(validator.params)
        if (!(regex.test(value))) {
          this.errormessage = validator.fehlerText
        }
      }
    })
  }

  override async focus(): Promise<void> {
    if (this.focusElement?.focusControl) {
      try {
        await this.focusElement?.focusControl()
      } catch {
        console.log(`Es ist eine Fehler beim Setzen des Fokus beim Feld '${this.field.name}' augetreten`)
      }
    } else {
      console.warn(`An dem FormularFeld '${this.field.name}' vom Typ '${typeof this.focusElement}' existiert keine Methode 'focusControl()'!`)
    }
  }
}
