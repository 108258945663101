import { Injectable, NgModule } from '@angular/core'
import { UploaderComponent } from './uploader/uploader.component'
import { UploadToolDialogComponent } from './upload-tool-dialog/upload-tool-dialog.component'
import { APP_BASE_HREF, CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { KundenAuswahlComponent } from './upload-tool-dialog/kunden-auswahl/kunden-auswahl.component'
import { KundensucheComponent } from './upload-tool-dialog/kundensuche/kundensuche.component'
import { AuthenticatorInterceptor } from './http/authenticator.interceptor'
import { QrCodeUploadComponent } from './geraetewechsel/qr-code-upload.component'
import { UploadCameraDialogComponent } from './upload-camera-dialog/upload-camera-dialog.component'
import { FotokollektorModule } from '../../../fotokollektor/src/lib/fotokollektor.module';
import { DateiauswahlModule } from '../../../dateiauswahl/src/lib/dateiauswahl.module'
import { DesignSystemModule } from '@dvag/design-system-angular'
import { RouterModule } from '@angular/router';
import { UploadFileDialogComponent } from './upload-file-dialog/upload-file-dialog.component'

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  override overrides = {
    swipe: { direction: 'DIRECTION_HORIZONTAL'},
    pinch: { enable: false },
    rotate: { enable: false }
  }
}

@NgModule({
  declarations: [
    UploaderComponent,
    UploadToolDialogComponent,
    UploadFileDialogComponent,
    UploadCameraDialogComponent,
    KundensucheComponent,
    KundenAuswahlComponent,
    QrCodeUploadComponent,
  ],
  imports: [
    RouterModule.forRoot([]),
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModule,
    DragDropModule,
    DesignSystemModule,
    HammerModule,
    FotokollektorModule,
    DateiauswahlModule
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/'},
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    [{
      provide: HTTP_INTERCEPTORS,
      useExisting: AuthenticatorInterceptor,
      multi: true
    }],
    AuthenticatorInterceptor],
  exports: [
    UploaderComponent,
    UploadToolDialogComponent,
    UploadFileDialogComponent,
    UploadCameraDialogComponent
  ]
})
export class UploadComponentsModule {
  constructor() {
    console.log('WebComponent: UploadComponentsModule loaded')
  }
}
