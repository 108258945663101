import { Injectable } from '@angular/core'
import { FetchAuftraege } from 'src/app/store/actions/auftrag.actions'
import { Store } from '@ngxs/store'

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  public activeUploads = new Set();

  constructor(
    private store: Store
  ) {}

  removeUpload(id: string) {
    this.activeUploads.delete(id)
    this.store.dispatch(new FetchAuftraege())
  }

  addUpload(id: string) {
    this.activeUploads.add(id)
  }
}
