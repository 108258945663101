import { Component } from '@angular/core';
import { BasisFormularFeld } from '../basis-formular-feld'

@Component({
  selector: 'app-headline',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.scss']
})

export class HeadlineComponent extends BasisFormularFeld {}
