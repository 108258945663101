import { Component, ElementRef, HostBinding, OnInit, ViewChild } from '@angular/core'
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms'
import { BasisNumberFormularFeld } from '../basis-number-formular-feld'
import { FormularFeldConfig, WertEinheitKonfiguration } from '../../../../store/models/formular-feld-config.model'
import { DecimalPipe } from '@angular/common'
import {
  AktivesEingabefeldHandlerService,
} from '../../basis-input-formular-feld/services/aktives-eingabefeld-handler.service'
import { Components } from '@dvag/design-system'
import DxNumberInput = Components.DxNumberInput
import {
  FormularFeldDictionaryService
} from '../../../auftrag-bearbeitung/components/vertrags-formular/services/formular-feld-dictionary.service'

@Component({
  selector: 'app-wert-einheit',
  templateUrl: './wert-einheit.component.html',
  styleUrls: ['./wert-einheit.component.scss']
})
export class WertEinheitComponent extends BasisNumberFormularFeld implements OnInit {

  @HostBinding('class.formular-field-fullwidth') isFullWidth: boolean
  @ViewChild('focusElement') focusElement: DxNumberInput
  @ViewChild('focusElement', { read: ElementRef }) elementRef: ElementRef


  public currentTyp: 'PROZENT' | 'EURO'
  public currentProfile: 'currency-euro' | 'percent' | '' = ''

  public wertEinheitKonfigurations: WertEinheitKonfiguration[]
  override initialValue: { wert: number, typ: string }

  constructor(public override fb: FormBuilder, public override focusedControlHandlerService: AktivesEingabefeldHandlerService, protected override decimalPipe: DecimalPipe) {
    super(fb, focusedControlHandlerService, decimalPipe)
  }

  /**
   * Diese ValidatorFunction dient dazu, Fehler, die vom DesignSystem wegen invaliden Datums geworfen werden, an das
   * FormControl weiter zu geben und es darüber auf valide oder invalide zu setzen.
   */
  private validWertEinheit(): ValidatorFn {
    return (): { [key: string]: any } | null => {
      return this.errormessage !== '' ? { WERT_EINHEIT_ERROR: false } : null
    }
  }
  override initBasisFormularFeld(group: FormGroup, field: FormularFeldConfig, formularId: string, isReadonly: boolean, auftragId?: string, formularFeldDictionaryService?: FormularFeldDictionaryService): void {
    super.initBasisFormularFeld(group, field, formularId, isReadonly, auftragId ?? null, formularFeldDictionaryService ?? null)
    this.wertEinheitKonfigurations = this.field.konfiguration as WertEinheitKonfiguration[] ?? []
    this.nachkommastellen = this.wertEinheitKonfigurations.find(it => it.typ === "PROZENT")?.anzahlNachkommastellen ?? 2
  }
  ngOnInit() {
    this.isFullWidth = this.field.fullWidth
    const wertEinheitTypFormControl = this.formControl.get('typ')
    wertEinheitTypFormControl.valueChanges.subscribe(value => {
      this.typOptionChanged(value)
    })

  }

  override componentValidators(): ValidatorFn[] {
    return [this.validWertEinheit()]
  }

  override setInitialValue(value: any): void {
    this.typOptionChanged(value?.typ ?? '')
    super.setInitialValue(value ?? { wert: null, typ: null })
  }

  override readValue(): any {
    return this.formControl?.value ?? null
  }

  override buildFormControl(): AbstractControl {
    return this.fb.group({
      wert: [''],
      typ: ['']
    }, {validators: this.bindValidations(this.field.validators)})
  }

  override hasValueChanged(): boolean {
    const wert = this.formControl.get('wert').value
    const typ = this.formControl.get('typ').value

    return this.initialValue?.typ !== typ || this.initialValue?.wert !== wert
  }

  override async setOcrResultToFormControl(ocrText: string): Promise<void> {
    const valueOfFormControlWert = this.formControl.get('wert').value

    if (!ocrText) {
      await this.focus()
      return
    }

    const newValue = this.prepareOcrTextForFormControl(ocrText, valueOfFormControlWert)

    if (newValue !== undefined) {
      this.formControl.get('wert').patchValue(newValue)
      this.validate(this.formControl.get('wert').value)
    }
    await this.focus()
  }

  setProfileForTyp(typ: 'PROZENT' | 'EURO'): void {
    this.nachkommastellen = this.wertEinheitKonfigurations.find(it => it.typ === typ)?.anzahlNachkommastellen ?? 2
    switch (typ) {
      case 'EURO':
        this.currentProfile = 'currency-euro'
        this.einheit= "€"
        break
      case 'PROZENT':
        this.currentProfile = 'percent'
        this.einheit= "%"
        break
    }
  }

  override getReadonlyDisplayValue(): string {
    if (this.initialValue && this.initialValue.wert != null) {
      const transformedValue = this.decimalPipe.transform(this.initialValue.wert, '1.2')
      return `${transformedValue} ${this.initialValue.typ === 'EURO' ? '€' : '%'}`
    } else {
      return ''
    }
  }

  private typOptionChanged(value: string) {
    if (value && ( value === 'PROZENT' || value === 'EURO') ) {
      this.setProfileForTyp(value)
    } else {
      this.currentProfile = ''
    }
    this.validate(this.formControl.get('wert').value)
  }

  override validate(value: number): void {
    this.errormessage = ''
    for (const validator of this.field.validators) {
      if (validator.name.toLowerCase() === 'wert_einheit_maximum') {
        if (this.currentProfile === 'percent') {
          if (value > validator.params.PROZENT) {
            this.errormessage = this.nonBreakingSpaceVorEinheitHinzufuegen(validator.fehlerText)
          }
        }
      }
    }

    this.formControl.updateValueAndValidity()
  }

  override async focus(): Promise<void> {
    if( this.focusElement?.focusControl ) {
      try {
        await this.focusElement?.focusControl()
      } catch {
        console.log(`Es ist eine Fehler beim Setzen des Fokus beim Feld '${this.field.name}' augetreten`)
      }
    } else {
      console.warn(`An dem FormularFeld '${this.field.name}' vom Typ '${typeof this.focusElement}' existiert keine Methode 'focusControl()'!`)
    }
  }
}
