  export enum AuftragStatus {
  BEARBEITUNG = 'BEARBEITUNG',
  READONLY = 'READONLY',
  NACHBEARBEITUNG = 'NACHBEARBEITUNG'
}

export enum AuftragKategorie {
  OFFEN = 'OFFEN',
  BEARBEITET = 'BEARBEITET'
}

export enum AuftragDatenbestand {
  BEREITSTELLUNG = 'BEREITSTELLUNG',
  BEREITGESTELLT = 'BEREITGESTELLT',
  NACHLADBAR = 'NACHLADBAR',
  BEREITSTELLUNGSFEHLER = 'BEREITSTELLUNGSFEHLER'
}
