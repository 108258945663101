import { Component, OnInit, ViewChild } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { FormularHttpService } from '../../../../store/services/formular.http.service'
import {
  TextInputAutocompleteController,
  TextInputAutocompleteValue
} from '@dvag/design-system/dist/types/components/form/dx-text-input/text-input.public'
import { BasisInputFormularFeld } from '../basis-input-formular-feld'
import { AktivesEingabefeldHandlerService } from '../services/aktives-eingabefeld-handler.service'
import { DxTextInput } from '@dvag/design-system-angular'
import { take } from 'rxjs/operators'

@Component({
  selector: 'app-dyn-autocomplete',
  templateUrl: './dyn-autocomplete.component.html'
})
export class DynAutocompleteComponent extends BasisInputFormularFeld implements OnInit {
  originOptions: TextInputAutocompleteValue[] = []

  @ViewChild('focusElement') focusElement: DxTextInput

  constructor(public override fb: FormBuilder, private formularHttpService: FormularHttpService, public override focusedControlHandlerService: AktivesEingabefeldHandlerService) {
    super(fb, focusedControlHandlerService)
  }

  ngOnInit() {
    if (this.formularId) {
      this.formularHttpService.fetchDynSelectOptions(this.formularId, this.field.name)
        .pipe(take(1))
        .subscribe(list => {
          this.originOptions = list.map(option => ({
            label: this.truncate(option, 100),
            value: option,
            data: option
          } as TextInputAutocompleteValue))
        })
    }
  }

  override setInitialValue(value: any): void {
    super.setInitialValue(value);
    this.addToCompletedValues(value)
  }

  filterOptions(event: any): void {
    const autocompleteEvent = event.detail
    const autocompleteInputValue = autocompleteEvent.value.toLowerCase()

    const filteredSelectOptions = this.originOptions
      .filter(it => it.label.toLowerCase().includes(autocompleteInputValue))


    if (autocompleteInputValue.trim() === '') {
      autocompleteEvent.provideValues(this.originOptions)

    } else if (filteredSelectOptions.length === 0) {
      autocompleteEvent.provideValues([])

    } else {
      autocompleteEvent.provideValues(filteredSelectOptions)

    }
  }

  // TODO Click auf Clear-Button öffnet die Liste nicht, siehe Bug DSYS-881
  handleInput(input: any): void {
    console.log('input', input)
    const validOption = this.originOptions.some(option => option.data == input)
    if (validOption) {
      this.addToCompletedValues(input)
      return
    }
    this.addToCompletedValues(input ? 'no_valid_option' : null)
  }

  private truncate(input: string, length: number): string {
    return input.length > length ? input.substring(0, length) + '...' : input
  }

  override async focus(): Promise<void> {
    if (this.focusElement?.focusControl) {
      try {
        await this.focusElement?.focusControl()
      } catch {
        console.log(`Es ist eine Fehler beim Setzen des Fokus beim Feld '${this.field.name}' augetreten`)
      }
    } else {
      console.warn(`An dem FormularFeld '${this.field.name}' vom Typ '${typeof this.focusElement}' existiert keine Methode 'focusControl()'!`)
    }
  }
}
