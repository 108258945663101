<img *ngIf="zoomImage"
     class="zoom-image"
     [src]="images[selectedImage]"
     alt="Image"
     (click)="zoom(false)"/>

<div class="preview">

  <div id="preview-image" class="preview-image"
       (swipeleft)="showNextImg()"
       (swiperight)="showPreviousImg()"
       (dblclick)="onDbClick()">
    <img *ngIf="images.length > 0"
         [src]="images[selectedImage]"
         alt="Image"
    />
  </div>
  <lib-image-cropper
    (cropperReady)="cropperReady($event)"
    (croppingDone)="handleCroppedImage($event)"
    (cropperOpen)="onCropperOpen($event)"
  ></lib-image-cropper>

  <div id="preview-footer" class="preview-footer">
    <button id="delete-image-button" class="white-button-with-svg error" (click)="deleteImage()">
      <dx-icon icon="loeschen" color="error" size="24"></dx-icon>
    </button>
    <button id="cropper-button" class="white-button-with-svg" (click)="openCropper(originalImages[selectedImage], selectedImage)">
      <dx-icon icon="crop" size="24" color="default"></dx-icon>
    </button>
    <button id="add-image-button" class="white-button-with-svg" (click)="addImage()">
      <dx-icon icon="x-gross" size="24" color="default" style="rotate: 45deg;"></dx-icon>
    </button>
    <div class="thumbnails">
      <div class="thumbnails__images" cdkDropList cdkDropListOrientation="horizontal"
           (cdkDropListDropped)="dropImage($event)">
        <div *ngFor="let image of images; let idx = index"
             id="thumbnail_{{idx}}"
             class="thumbnail"
             cdkDrag
             [cdkDragDisabled]="images.length <= 1"
             (contextmenu)="preventContextMenu($event)">

          <div *cdkDragPlaceholder class="image-drag-placeholder"></div>

          <div [ngStyle]="{'background-image':  'url(' + image + ')'}"
               class="thumbnail-background-image"
               [ngClass]="{'thumbnail-image-highlight': selectedImage === idx}"
               (click)="setSelectedImage(idx)">
          </div>

          <div id="delete-thumbnail-button_{{idx}}" class="delete-button" (click)="deleteImage(idx)">
            <dx-icon icon="loeschen" color="error" size="16"></dx-icon>
          </div>

          <div id="crop-thumbnail-button_{{idx}}" class="crop-thumbnail-button" (click)="openCropper(originalImages[idx], idx)">
            <dx-icon icon="crop" color="default" size="16"></dx-icon>
          </div>

          <img *cdkDragPreview [src]="image" alt="Drag&Drop Vorschau"/>
        </div>
      </div>
    </div>
    <button id="send-images-button" class="primary-svg-icon-button" (click)="done()"
            [disabled]="images.length === 0">
      <dx-icon icon="senden" size="32" color="white" style="margin: 4px 0 0 2px;"></dx-icon>
    </button>
  </div>

</div>

<div class="fullscreen" [style.display]="isLoading ? 'flex' : 'none'">
  <div class="card">
    <dx-loading-indicator size="medium" theme="light" label="Bild wird geladen ...">
    </dx-loading-indicator>
  </div>
</div>
