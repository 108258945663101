import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms'
import { BasisInputFormularFeld } from '../basis-input-formular-feld'
import { AktivesEingabefeldHandlerService } from '../services/aktives-eingabefeld-handler.service'
import { Components } from '@dvag/design-system'
import DxSingleSelect = Components.DxSingleSelect


@Component({
  selector: 'app-single-select',
  templateUrl: './single-select.component.html'
})
export class SingleSelectComponent extends BasisInputFormularFeld{

  @ViewChild('focusElement') focusElement: DxSingleSelect

  constructor(public override fb: FormBuilder, public override focusedControlHandlerService: AktivesEingabefeldHandlerService) {
    super(fb, focusedControlHandlerService)
  }

}

