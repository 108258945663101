import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { catchError, map, mergeMap, shareReplay, take, tap } from 'rxjs/operators'
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject, throwError } from 'rxjs'
import { KeycloakApiService } from './keycloak.api.service'

export enum Mandant {
  DVAG = 'DVAG',
  DVAG_AT = 'DVAG_AT',
  ALLFINANZ = 'ALLFINANZ',
  ALLFINANZ_AG = 'ALLFINANZ_AG'
}

@Injectable({ providedIn: 'root' })
export class ZobService {

  private benutzertyp$: ReplaySubject<string> =new ReplaySubject<string>(1)
  private mandant$: BehaviorSubject<Mandant> = new BehaviorSubject<Mandant>(Mandant.DVAG)

  constructor(private httpClient: HttpClient,
              private keycloak: KeycloakApiService) {

    this.httpClient.get<ZobBenutzer>(environment.zob.baseUrl + '/rest/v1/ressources/users/loggedinuser')
      .pipe(
        catchError(() => of({
                    userid: '',
                    anrede: '',
                    titel: '',
                    vorname: '',
                    name: '',
                    geburtsdatum: '',
                    gesellschaft: '',
                    benutzertyp: '',
                    stammdat: null,
                  } as ZobBenutzer)),
        shareReplay(1),
        tap((it) => {
          //console.log('🪲 ZobBenutzer: ', it)
          this.benutzertyp$.next(it.benutzertyp)
          this.mandant$.next(this.mapGesellschaftToMandant(it.gesellschaft))
        }),
      ) .subscribe()
  }

  private mapGesellschaftToMandant(gesellschaft: string): Mandant {
    switch (gesellschaft) {
      case '1':
        return Mandant.DVAG
      case '2':
        return Mandant.DVAG_AT
      case '3':
        return Mandant.ALLFINANZ
      case '5':
        return Mandant.ALLFINANZ_AG
      default:
        return Mandant.DVAG
    }
  }

  getBenutzerTyp() {
    return this.benutzertyp$.asObservable()
  }

  mandant(): Observable<Mandant> {
    return this.mandant$.asObservable()
  }

  fetchVertretungen(): Observable<ZobVertretung[]> {
    const vbnummer = this.keycloak.getVBNummerFromToken()
    return this.httpClient.get<ZobVertretung[]>(environment.zob.baseUrl + '/rest/v1/ressources/vertretungen/' + vbnummer)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 404) {
            return of([])
          } else {
            return throwError(err)
          }
        }),
        map(vertretungen => vertretungen.sort((a, b) => {
          if (a.userId < b.userId) {
            return 1
          } else if (a.userId > b.userId) {
            return -1
          } else {
            return 0
          }
        })),
        shareReplay(1),
      )
  }
}


export interface ZobVertretung {
  userId: string
  typ: string
  name: string
  vorname: string
  anrede: string
  titel: string
}

export interface ZobStammdaten {
  direktion: number
  dg: string
  stufe: number
  praxisstf: number
  admiralsclub: string
  asclub: string
  kapitansclub: string
  austritt: string
  dlseit: string
  email: string
  homepage: string
  handy1: string
  handy2: string
  handynotfall: string
  pruef: string
  VBSEIT: string
  vorges: string
  beschrkz: number
  berufstarter: number
  mitbenutzernick: string
  dvSchulung: string
}

export interface ZobBenutzer {
  userid: string
  anrede: string
  titel: string
  vorname: string
  name: string
  geburtsdatum: string
  gesellschaft: string
  benutzertyp: string
  stammdat: ZobStammdaten
}
