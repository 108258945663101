import { Component, ViewChild } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { BasisInputFormularFeld } from '../basis-input-formular-feld'
import { AktivesEingabefeldHandlerService } from '../services/aktives-eingabefeld-handler.service'
import { Components } from '@dvag/design-system'
import DxDropdown = Components.DxDropdown

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent extends BasisInputFormularFeld {

  @ViewChild('focusElement') focusElement: DxDropdown

  constructor(public override fb: FormBuilder, public override focusedControlHandlerService: AktivesEingabefeldHandlerService) {
    super(fb, focusedControlHandlerService)
  }

  override prepareOcrTextForFormControl(ocrText: string, oldValue: any): any {

    // Wenn bereits ein 1 zu 1 Match gefunden wurde, dann gib den Wert zurück
    if (this.field.selectOptions.includes(ocrText)) {
      return ocrText
    }

    if (this.field.needExtraOcrTextPreparation) {
      const sfKlasse = this.getSfKlasseFromString(ocrText)
      if (sfKlasse)
        return sfKlasse
    }

    return oldValue

  }

  private getSfKlasseFromString(inputString: string): string | null {
    const removeCharsRegEx = new RegExp(/[_,.)-]/g)
    const ocrTextNachRemoveChars = inputString.replace(removeCharsRegEx, '')
    const matchResultZahl = ocrTextNachRemoveChars.match('\\d.*')
    if (matchResultZahl) {
      switch (matchResultZahl[0]) {
        case '0':
          return 'KL 0'
        case '1/2':
          return 'SF1/2'
        default:
          if (this.field.selectOptions.includes('SF ' + matchResultZahl)) {
            return 'SF ' + matchResultZahl
          }
      }
    }
    return null
  }

  override async focus(): Promise<void> {
    if( this.focusElement?.focusControl ) {
      try {
        await this.focusElement?.focusControl()
      } catch {
        console.log(`Es ist eine Fehler beim Setzen des Fokus beim Feld '${this.field.name}' augetreten.`)
      }
    } else {
      console.warn(`An dem FormularFeld '${this.field.name}' vom Typ '${typeof this.focusElement}' existiert keine Methode 'focusControl()'!`)
    }
  }

}
