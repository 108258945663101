<ng-container *ngIf="!isReadonly">
  <div class="sync" *ngIf="loading">
    <dx-loading-indicator size="small" label=""></dx-loading-indicator>
  </div>
  <div [formGroup]="mainFormularGroup">
    <dx-dropdown #focusElement
                 [formControlName]="field.name"
                 [attr.disabled]="dynSelectOptions?.length === 0"
                 id="{{field.name}}_wert"
                 label="{{field.label}}"
                 required="{{field.required}}"
                 (focus)="storeNameOfFocusedElement(field.name)"
                 (valueComplete)="addToCompletedValues($event.detail)"
                 [kisynced]="field.kiSync">
      <dx-list-item *ngFor="let option of dynSelectOptions; let idx = index" [value]="option" [id]="'tarifname_wert-list-item-' + idx + '-control'"
                          [label]="option"></dx-list-item>
    </dx-dropdown>
  </div>
</ng-container>
<app-readonly-template *ngIf="isReadonly" [field]="field" [displayValue]="getReadonlyDisplayValue()"></app-readonly-template>
