import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FotokollektorComponent } from './fotokollektor.component';
import { EnvironmentInfoModule } from '../../../environment-info/src/lib/environment-info.module'
import { CameraComponent } from './camera/camera.component'
import { DocumentPreviewComponent } from './document-preview/document-preview.component'
import { DesignSystemModule } from '@dvag/design-system-angular'
import { CommonModule } from '@angular/common'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { ImageCropperModule } from '../../../image-cropper/src/lib/image-cropper.module'



@NgModule({
  declarations: [FotokollektorComponent, CameraComponent, DocumentPreviewComponent],
  imports: [EnvironmentInfoModule, DesignSystemModule, CommonModule, DragDropModule,  ImageCropperModule],
  exports: [FotokollektorComponent]
})
export class FotokollektorModule { }
