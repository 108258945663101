<dx-modal #dxmodal id='m2' height='960' width='xl'
          [label]="modalTitle"
          (modalClosed)='onModalClosed($event)'>
  <div slot='content' style='height: 100%; display: flex; flex-direction: column; justify-content: center;'>
    <lib-dateiauswahl #dateiauswahl
                      *ngIf='showDateiauswahl'
                      id='upload-tool-dialog_dateiauswahl'
                      [modalClosedEvent]='modalClosedEvent'
                      [configOptions]="mergedConfigOptions"
                      (uploadTriggered)='uploadDokument($event)'
                      (useButtons)='handleUseButtons($event)'
                      (closeModal)='cancelUpload()'
                      (useHeadline)='changeModalTitle($event)'
    >
    </lib-dateiauswahl>
    <dx-loading-indicator *ngIf='showSpinner' size='large' theme='light' label=''></dx-loading-indicator>

    <div *ngIf='showError' style='display: flex; flex-direction: column;  gap: 20px; align-items: center;'>
      <ng-container [ngSwitch]='errorType'>
        <ng-container *ngSwitchCase="FileUploadErrorType.CUSTOMER_NOT_FOUND">
          <dx-icon icon='warndreieck' size='72'></dx-icon>
          <dx-text type='ps'>Zu der von Ihnen angegebenen Kundennummer konnte kein Kunde gefunden werden.</dx-text>
        </ng-container>
        <ng-container *ngSwitchCase="FileUploadErrorType.CUSTOMER_NO_EWE">
          <dx-icon icon='information' size='72'></dx-icon>
          <dx-text type='ps'>Für die Übermittlung wichtiger Unterlagen ist eine Einwilligung in die Datenverarbeitung
            erforderlich.
          </dx-text>
          <dx-text *ngIf="userContext === 'VB'" type='ps'>Bitte holen Sie diese ein, bevor Sie Dokumente hochladen
            können
          </dx-text>
          <dx-text *ngIf="userContext === 'ENDKUNDE'" type='ps'>Bitte setzen Sie sich dazu mit Ihrem Vermögensberater in
            Verbindung, der
            Ihnen bei den nächsten Schritten zur Seite steht
          </dx-text>
        </ng-container>
        <ng-container *ngSwitchCase="FileUploadErrorType.TECHNICAL_ERROR">
          <dx-icon icon='warndreieck' size='72'></dx-icon>
          <dx-text type='ps'>Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.</dx-text>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <dx-button *ngFor='let buttonConfig of modalButtons'
             [label]='buttonConfig.label'
             [slot]='buttonConfig.slot'
             [id]='buttonConfig.id'
             [disabled]='buttonConfig.disabled ?? false'
             [type]='buttonConfig.type'
             [icon]='buttonConfig.icon ?? null'
             (click)='buttonConfig.clickFn()'
  ></dx-button>

</dx-modal>

<dx-alert #fileuploaderroralert
          id='file-upload-error-alert'
          type='error'
          icon='warndreieck'
          titlestring='Achtung'
          content='Es ist ein unerwarteter Fehler aufgetreten. Schließen Sie den Dialog und probieren Sie es in einigen Minuten erneut.'
>
  <dx-button
    id='bucket-error-ok'
    type='primary-s'
    slot='action'
    label='Okay'
    (click)='closeAndResetModal()'
  ></dx-button>
</dx-alert>

