import { Injectable } from '@angular/core'
import {
  AktivesEingabefeldHandlerService
} from '../formular/basis-input-formular-feld/services/aktives-eingabefeld-handler.service'

@Injectable({
  providedIn: 'root'
})
export class ClickAndCollectService {

  constructor(private focusedControlHandlerService: AktivesEingabefeldHandlerService) {
  }

  setTextToFormControl(text: string): void {
    console.log('ClickAndCollectService.setTextToFormControl(): ', text)
    const aktivesEingabefeld = this.focusedControlHandlerService.aktivesEingabefeld

    if (!aktivesEingabefeld) {
      console.warn('ClickAndCollectService.setTextToFormControl(): Text konnte nicht gesetzt werden, da kein Form Control fokussiert ist')
      return
    }

    if (text) {
      aktivesEingabefeld.setText(text)
    } else {
      aktivesEingabefeld.focus()
    }
  }
}
